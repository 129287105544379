import React from 'react';
import { ErrorMessage, useField } from 'formik';

const InputField = ({label , ...props}) => {
    
    const [field , meta] = useField(props);
    
    return (
        <>
            <div className="form-group">
                <label htmlFor={field.name} className="form-label m-0">{label}</label>
                <input type={props.type}
                    {...field}
                    {...props}
                    className={`form-control ${meta.touched && meta.error && 'is-invalid'}`} placeholder={props.placeholder} autoComplete="off"
                />
                <ErrorMessage name={field.name} component="span" className="field-error"/>
            </div>
        </>
    )
}

export default InputField