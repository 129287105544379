import React, { useEffect, useState } from 'react';
import { valueCheckGlobal, getLoader } from '../../../GlobalFunctions';
// import axios from 'axios';
import './ratio.css';
import LoadingImage from '../../../assets/media/loader.gif';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
  modal_excel_icon,
} from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
// import Valuation from './../../valuationData';
import ChartModal from './../../../layout/modals/ChartModal';
import RatioTableModal from './../../../layout/modals/RatioTableModal';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';

const RatioTable = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});

  // let sectorId = props.company.sector_id;
  // console.log('i am here1', props.company);
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // setValData(props.allData);
  const [valData, setValData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  useEffect(() => {
    fetch(`${baseUrl}/ratio/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        setIsLoading(true);
        // console.log(data, 'ffls1234 response');
        // generateChart(data);
      });
  }, [companyId]);

  let withPercent = {
    gross_margin: 'Gross Margin',
    ebidta_margin: 'EBITDA Margin',
    operating_margin: 'Operating Margin',
    net_margin: 'Net Margin',
    revenue_growth: 'Revenue Growth',
    gross_profit_growth: 'Gross Profit growth',
    ebidta_profit_growth: 'EBITDA Growth',
    operating_profit_growth: 'Operating Profit Growth',
    profit_before_tax_growth: 'Pre-Tax Profit Growth',
    net_profit_growth: 'Net profit Growth',
    roe: 'ROE',
    roa: 'ROA',
  };

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [label, setLabel] = useState('');
  const [ratioName, setRatioName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);

  const handleChartModal = (company_name, label, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLabel(label);
    setchartData(chartData);
    setChartActive(true);
  };

  const excel_name = company.name + ` - Ratios`;

  // if (valData.length && isLoading) {
  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {valData.length && isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="ratio-main statement-table">
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button income-excel-btn"
                  table="table"
                  filename={excel_name}
                  sheet="file"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{' '}
              </span>
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th colSpan={3}>Ratios</th>
                    <th>&nbsp;</th>
                    {valData
                      ? valData[0].data.slice(-10).map((year, i) => {
                          return i !== 10 ? <th key={i}>{year.year}</th> : null;
                        })
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {valData.map((r, i) => {
                    var abc = r.data.map(function (el) {
                      return el.value;
                    });
                    return (
                      <tr key={i}>
                        <td colSpan={2}>
                          {r.label}{' '}
                          <span className="table-unit-col">({r.unit})</span>
                        </td>
                        <td>
                          <span
                            style={styles.chart}
                            onClick={() => {
                              handleChartModal(
                                company.name,
                                r.label + ' - ' + r.unit,
                                r.data
                              );
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modal_chart_icon,
                              }}
                            />
                          </span>
                          <span style={styles.chart}>
                            <Link
                              // target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  e.currentTarget.href,
                                  '_blank',
                                  'location = yes,height = 450,width = 950,scrollbars = yes,status = yes,left = 200),top = 100'
                                );
                              }}
                              to={{
                                pathname: `/comparison/ratios/annual/${company.symbol}/${r.name}`,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: modal_table_icon,
                                }}
                              />
                            </Link>
                          </span>
                        </td>
                        <td>
                          <img
                            src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
                            width="50px"
                            height="20px"
                          />
                        </td>
                        {r.data.slice(-10).map((d, i) => {
                          return i !== 10 ? (
                            <td key={i}>{valueCheckGlobal(d.value)}</td>
                          ) : null;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {chartActive && (
              <ComparisonChartModal
                chartActive={chartActive}
                setChartActive={setChartActive}
                companyname={companyName}
                label={label}
                chartData={chartData}
                slice={false}
              />
            )}
          </div>
        </>
      ) : (
        <>{getLoader()}</>
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};

export default RatioTable;
