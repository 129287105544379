import React, {useState}  from 'react';
import Modal from 'react-bootstrap/Modal';
import cross from "../../assets/media/icons/crosswhite.svg";
import registrationEmail from "../../assets/media/images/registrationEmail.svg"

const ForgotModal = (props) => {

    const [successMessage , setSuccesMessage] = useState(false);

    // const validate = Yup.object({
    //     email: Yup.string().email("Invalid email format").required("Email is required")
    // });

    // const handleSubmit = (values) => {
    //     setError('');
    //     setSuccesMessage(false);
    //     axios.post(forgotAPI, values)
    //     .then(function (response) {
    //         setSuccesMessage(true);
    //         setTimeout(() => {
    //             props.closeModal();
    //             setSuccesMessage(false);
    //         }, 3000);
    //     })
    //     .catch(function (error) {
    //         setSuccesMessage(false);
    //         if(error.response.status === 400){
    //             console.log(error.response.data.error);
    //             setError(error.response.data.error);
    //         }
    //         else{
    //             console.log(error.response.data.error);
    //         }
    //     });
    // }
    const closeModal = () => {
        props.closeModal()
    }    
    return (
        <Modal centered className="table-modal success-modal" show={props.successModal?true:false}>
            <Modal.Header>
                <button className='close-subs-view' onClick={closeModal} style={{background:"#000000"}} >
                    <img src={cross} alt="close" />
                    {/* <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" className=""></path></svg> */}
                </button>
            </Modal.Header> 
            <Modal.Body>
                <div className='forgot-content text-center' style={styles.successContentSuccess}>
                    <img src={registrationEmail} style={styles.successEmailImg}  />
                    <h3 style={styles.successEmailText}>Registration completed successfully</h3>
                    <p style={styles.successParagraph}>To activate your account, please check your registered email to verify your email address.</p>
                </div>
            </Modal.Body>
        </Modal>   
    )
}

export default ForgotModal;

const styles ={
    successContentSuccess:{
        margin: "0",
        padding: "0",
        border: 'none'
    },
    successParagraph:{
        fontSize: "20px",
        color: "rgb(72, 72, 72)",
        fontWeight: "400",
        marginTop:"20px",
        lineHeight: "24px",
    },
    successEmailImg:{
        width:"250px"
    },
    successEmailText:{
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "36px",
        marginTop:"30px",
        textAlign: "center",
        color: "#3D3D3D"
    }
}