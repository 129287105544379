
import {createStore , applyMiddleware} from 'redux';
import {createStateSyncMiddleware , initStateWithPrevTab} from 'redux-state-sync';
import reducer from './reducer'

const reduxStateSyncConfig = {
    broadcastChannelOption: {type: "idb"}
};
const store = createStore(reducer, applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)));

initStateWithPrevTab(store);

export default store;