import React, { useEffect, useState, useContext } from "react";
import "./latestnews.css";
import Header from "../../../common/header/header";
import latest_chart from "../../../../src/assets/media/images/latestchart.svg";
import luckyicon from "../../../../src/assets/media/images/luck-icon.svg";
import breflyicon from "../../../../src/assets/media/images/brefly-icon.svg";
import heldicon from "../../../../src/assets/media/images/held-icon.svg";
import gasicon from "../../../../src/assets/media/images/gas-icon.svg";
import ranbowicon from "../../../../src/assets/media/images/ranbow-icon.svg";
import shellicon from "../../../../src/assets/media/images/shell-icon.svg";
import channel_icon from "../../../../src/assets/media/images/channel-icon.svg";
import blogthumbnail from "../../../../src/assets/media/images/blog-thumbnail.svg";
import postcomment1 from "../../../../src/assets/media/images/men1.png";
import postcomment2 from "../../../../src/assets/media/images/men2.png";
import postcomment3 from "../../../../src/assets/media/images/men3.png";
import postcomment4 from "../../../../src/assets/media/images/men4.png";
import {
  facebookicon,
  twittericon,
  instagramicon,
  linkedinicon,
  shareicon,
  replyicon,
  thumbsup,
  thumbsdown,
  calendaricon,
  downloadicon,
} from "../../../api/imageUrl";
import { Link } from "react-router-dom";
const latestnews = () => {
  return (
    <>
      <Header />
      <div className="main_latest">
        <div className="container container_box">
          <div className="detail">
            <div className="row">
              <div className="col-md-8 main">
                <div className="heading_latest">
                  <h2>
                    Using diversification to minimize market volatility: How to
                    diversify your stock portfolio
                  </h2>
                  <p className="lucky-text mb-3">
                    By <strong>Muhammad Atif</strong> | Result Review | 8th
                    August, 2021 | 4 Minutes
                  </p>
                </div>
                <div className="col-md-12 d-flex p-0">
                  <div className="col-md-6 detailsocialicon pl-0">
                    <div
                      className="social-icon"
                      dangerouslySetInnerHTML={{
                        __html: facebookicon,
                      }}
                    />
                    <div
                      className="social-icon"
                      dangerouslySetInnerHTML={{
                        __html: twittericon,
                      }}
                    />
                    <div
                      className="social-icon"
                      dangerouslySetInnerHTML={{
                        __html: instagramicon,
                      }}
                    />
                    <div
                      className="social-icon"
                      dangerouslySetInnerHTML={{
                        __html: linkedinicon,
                      }}
                    />
                  </div>
                  <div className="col-md-6 listitems pl-0 pr-1">
                    <ul>
                      <li>LUCK</li>
                      <li>CEMENT</li>
                      <li>RESULTS</li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="col-md-2 image">
                  <img src={latest_chart} alt="" className="imagebig" />
                </div>
                <div className="row">
                  <div className="col-md-12 content">
                    <p>
                      Seamlessly syndicate cutting-edge architectures rather
                      than collaborative collaboration and idea-sharing.
                      Proactively incubate visionary interfaces whereas premium
                      benefits. Seamlessly negotiate ubiquitous leadership
                      skills rather than parallel ideas. Dramatically visualize
                      superior interfaces for best-of-breed alignments.
                      Synergistically formulate performance based users through
                      customized relationships. Interactively deliver
                      cross-platform ROI via granular systems. Intrinsicly
                      enhance effective initiatives vis-a-vis orthogonal
                      outsourcing. Rapidiously monetize market-driven
                      opportunities with multifunctional users. Collaboratively
                      enhance visionary opportunities through revolutionary
                      schemas. Progressively network just in time customer
                      service without real-time scenarios.
                    </p>
                    <p>
                      Synergistically drive e-business leadership with unique
                      synergy. Compellingly seize market positioning ROI and
                      bricks-and-clicks e-markets.
                    </p>
                  </div>
                  <div className="col-md-12 content float-left">
                    <div className="detailsbuttons float-right">
                      <div className="socialicon detailsocialicon pl-2">
                        <div
                          className="social-icon download_social"
                          dangerouslySetInnerHTML={{
                            __html: facebookicon,
                          }}
                        />
                        <div
                          className="social-icon download_social"
                          dangerouslySetInnerHTML={{
                            __html: twittericon,
                          }}
                        />
                        <div
                          className="social-icon download_social"
                          dangerouslySetInnerHTML={{
                            __html: instagramicon,
                          }}
                        />
                        <div
                          className="social-icon download_social"
                          dangerouslySetInnerHTML={{
                            __html: linkedinicon,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 postcomment">
                    <h2>Post a comment</h2>
                    <div className="d-flex comment">
                      <img src={postcomment1} />
                      <div className="flex-grow-1 ms-3">
                        <div className="mb-1">
                          <Link to="#" className="fw-bold link-dark me-1"></Link>
                          <span className="short_text">
                            Sort by: <strong>Best</strong>
                          </span>
                        </div>
                        <textarea
                          name=""
                          cols="70"
                          rows="7"
                          placeholder="Add a Comment.."
                        ></textarea>
                        <button className="detailcomment float-right">
                          Comment
                        </button>
                      </div>
                    </div>
                    <div className="d-flex comment mt-5">
                      <img src={postcomment2} />
                      <div className="flex-grow-1 ms-3">
                        <div className="mb-1">
                          <a
                            href="#"
                            className="fw-bold link-dark me-1 text-dark text-decoration-none"
                          >
                            <b>Jenny Wilson</b>
                          </a>
                          <span className="time_hour text-muted float-right">
                            12 hours ago
                          </span>
                        </div>
                        <div className="time_hour paragraphtext mb-2">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Amet minim mollit non deserunt
                          ullamco est sit aliqua
                        </div>
                        <div className="hstack align-items-center reply_style text-muted mb-2 float-right">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsup,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsdown,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                        </div>
                        <div className="hstack align-items-center reply_style mb-2">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: replyicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Reply
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: shareicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Share
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex comment mt-4">
                      <img src={postcomment3} />
                      <div className="flex-grow-1 ms-3">
                        <div className="mb-1">
                          <a
                            href="#"
                            className="fw-bold link-dark me-1 text-dark text-decoration-none"
                          >
                            <b>Jenny Wilson</b>
                          </a>
                          <span className="time_hour text-muted float-right">
                            12 hours ago
                          </span>
                        </div>
                        <div className="time_hour paragraphtext mb-2">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Amet minim mollit non deserunt
                          ullamco est sit aliqua
                        </div>
                        <div className="hstack align-items-center reply_style text-muted mb-2 float-right">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsup,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsdown,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                        </div>
                        <div className="hstack align-items-center reply_style mb-2">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: replyicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Reply
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: shareicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Share
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex comment mt-4">
                      <img src={postcomment4} />
                      <div className="flex-grow-1 ms-3">
                        <div className="mb-1">
                          <a
                            href="#"
                            className="fw-bold link-dark me-1 text-dark text-decoration-none"
                          >
                            <b>Jenny Wilson</b>
                          </a>
                          <span className="time_hour text-muted float-right">
                            12 hours ago
                          </span>
                        </div>
                        <div className="time_hour paragraphtext mb-2">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Amet minim mollit non deserunt
                          ullamco est sit aliqua
                        </div>
                        <div className="hstack align-items-center reply_style text-muted mb-2 float-right">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsup,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: thumbsdown,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted"
                            href="#"
                          >
                            0
                          </a>
                        </div>
                        <div className="hstack align-items-center reply_style mb-2">
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: replyicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Reply
                          </a>
                          <div className="d-flex"
                            dangerouslySetInnerHTML={{
                              __html: shareicon,
                            }}
                          />
                          <a
                            className="link-secondary small text-muted text-decoration-none"
                            href="#"
                          >
                            Share
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 side">
                <div className="col-md-12 research">
                  <h2>Research Reports</h2>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
                    <div className="main_row">
                      <div className="col-md-1 icon">
                        <img src={luckyicon} alt="" />
                      </div>
                      <div className="col-md-12 heading_latest">
                        LUCK Held Its Analyst Briefing Today To Discuss Its
                        1HFY23 Financial Results.
                      </div>
                    </div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="latest_more">
                        <Link to="/market/researchreport">View More</Link> &nbsp;
                        <Link to="#">Download</Link>
                        <div
                          className="d-flex"
                          dangerouslySetInnerHTML={{
                            __html: downloadicon,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
				  	<div className="main_row">
                    	<div className="col-md-1 icon">
                      		<img src={heldicon} alt="" />
                    	</div>
                    	<div className="col-md-12 heading_latest">
                      		LUCK Held Its Analyst Briefing Today To Discuss Its 1HFY23
                      		Financial Results.
                    	</div>
                    </div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="latest_more">
                        <Link to="/market/researchreport">View More</Link> &nbsp;
                        <Link to="#">Download</Link>
                        <div
                          className="d-flex"
                          dangerouslySetInnerHTML={{
                            __html: downloadicon,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
				  	<div className="main_row">
                    	<div className="col-md-1 icon">
                      		<img src={ranbowicon} alt="" />
                    	</div>
                    	<div className="col-md-12 heading_latest">
                      		LUCK Held Its Analyst Briefing Today To Discuss Its 1HFY23
                      		Financial Results.
                    	</div>
                    </div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="latest_more">
                        <Link to="/market/researchreport">View More</Link> &nbsp;
                        <Link to="#">Download</Link>
                        <div
                          className="d-flex"
                          dangerouslySetInnerHTML={{
                            __html: downloadicon,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="col-md-12 research mt-5">
                  <h2>Latest News</h2>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
				  	<div className="main_row">
                    	<div className="col-md-1 icon">
                      		<img src={gasicon} alt="" />
                    	</div>
                    	<div className="col-md-12 heading_latest">
                      		LUCK Held Its Analyst Briefing Today To Discuss Its 1HFY23
                      		Financial Results.
                    	</div>
                    </div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
				  	<div className="main_row">
                    	<div className="col-md-1 icon">
                      		<img src={breflyicon} alt="" />
                    	</div>
                    	<div className="col-md-12 heading_latest">
                      		LUCK Held Its Analyst Briefing Today To Discuss Its 1HFY23
                      		Financial Results.
                   	 	</div>
                   	</div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
				  	<div className="main_row">
                    	<div className="col-md-1 icon">
                      		<img src={shellicon} alt="" />
                   	 	</div>
                    	<div className="col-md-12 heading_latest">
                      		LUCK Held Its Analyst Briefing Today To Discuss Its 1HFY23
                      		Financial Results.
                    	</div>
                    </div>
                    <div className="col-md-12 content">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the 1HFY23..
                    </div>
                    <div className="col-md-12 calander d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="col-md-12 research mt-5">
                  <h2>Channel Checks</h2>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
                    <div className="col-md-1 icon d-flex">
                      <img
                        src={channel_icon}
                        alt=""
                        className="luckmini-icon"
                      />
                    </div>
                    <div className="col-md-12 heading_latest pl-4">
                      | Lucky cement limited | LUCK | 7th April, 2023
                    </div>
                    <div className="col-md-12 content pl-4 pb-0">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the.
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
                    <div className="col-md-1 icon d-flex">
                      <img
                        src={channel_icon}
                        alt=""
                        className="luckmini-icon"
                      />
                    </div>
                    <div className="col-md-12 heading_latest pl-4">
                      | Lucky cement limited | LUCK | 7th April, 2023
                    </div>
                    <div className="col-md-12 content pl-4 pb-0">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the.
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pr-0">
                    <div className="col-md-1 icon d-flex">
                      <img
                        src={channel_icon}
                        alt=""
                        className="luckmini-icon"
                      />
                    </div>
                    <div className="col-md-12 heading_latest pl-4">
                      | Lucky cement limited | LUCK | 7th April, 2023
                    </div>
                    <div className="col-md-12 content pl-4 pb-0">
                      The company reported consolidated earnings of PKR
                      49.32/share, up 21% YoY during the.
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="col-md-12 research mt-5 mb-4">
                  <h2>Blogs</h2>
                  <hr />
                  <div className="researchitem pl-0 pt-4 pr-0">
                    <div className="col-md-1 icon">
                      <img src={blogthumbnail} alt="" />
                    </div>
                    <div className="col-md-12 detailheading_latest pl-4">
                      LUCK held its analyst briefing today to discuss
                    </div>
                    <div className="col-md-12 detailcontent pl-4">
                      The company reported consolidated earnings of PKR
                      49.32/share
                    </div>
                    <div className="col-md-12 calander mt-4 d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pt-4 pr-0">
                    <div className="col-md-1 icon">
                      <img src={blogthumbnail} alt="" />
                    </div>
                    <div className="col-md-12 detailheading_latest pl-4">
                      LUCK held its analyst briefing today to discuss
                    </div>
                    <div className="col-md-12 detailcontent pl-4">
                      The company reported consolidated earnings of PKR
                      49.32/share
                    </div>
                    <div className="col-md-12 calander mt-4 d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="researchitem pl-0 pt-4 pr-0">
                    <div className="col-md-1 icon">
                      <img src={blogthumbnail} alt="" />
                    </div>
                    <div className="col-md-12 detailheading_latest pl-4">
                      LUCK held its analyst briefing today to discuss
                    </div>
                    <div className="col-md-12 detailcontent pl-4">
                      The company reported consolidated earnings of PKR
                      49.32/share
                    </div>
                    <div className="col-md-12 calander mt-4 d-flex align-items-center">
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: calendaricon,
                        }}
                      />{" "}
                      28th Aug, 2004
                      <span className="float-right ml-auto">
                        <Link to="/market/latestnews">View More</Link>
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default latestnews;
