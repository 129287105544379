import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import IdleTimer from '../../IdleTimerContainer';
import Header from '../../common/header/header';
import Wordsvg from '../../assets/media/files/Word.svg';
import Excelsvg from '../../assets/media/files/Excel.svg';
import Pdfsvg from '../../assets/media/files/Pdf.svg';
import Powerpointsvg from '../../assets/media/files/Powerpoint.svg';
import SessionExpire from '../../layout/login/sessionExpire';
import DownloadLink from 'react-download-link';
import LoadingImage from '../../assets/media/loader.gif';
// import './tabs.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import fileDownload from 'js-file-download';

// import '../../dashboard/tabs/tabs.css';
import { useCookies } from 'react-cookie';
// import Loader from '../../assets/media/_loader.gif';
import axios from 'axios';
import './financial.css';
import DataTable from 'react-data-table-component';
import { baseUrl } from '../../api/baseUrl';

function Index(props) {
  const [sessionMessage, setSessionMessage] = useState('');
  const [sessionModal, setSessionModal] = useState(false);
  const [dark, setDark] = useState(false);
  const [financial, setFinancial] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = cookies.accessToken;
  // const [alert, setAlert] = useState(false);

  /* Session expiry functionality start */

  /* if (alert === true) {
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  } */

  const sessionTimer = localStorage.getItem('sessionTime');

  function sessionTimeout() {
    setSessionModal(false);
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    localStorage.removeItem('sessionTime');
    history.replace('/');
  }

  var count = sessionTimer * 1000;
  setTimeout(() => {
    setSessionMessage('Your session has timed out. Please login again.');
    handleSession();
  }, 3600000);

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  useEffect(() => {
    const AuthStr = 'Bearer '.cookies?.accessToken;
    const interval2 = axios
      .get(`${baseUrl}/financials`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      })
      .then((response) => {
        // If request is good...
        setFinancial(response.data);
        setIsLoading(true);
        // console.log(response.data);
        console.log(isLoading);
      })
      .catch((error) => {
        console.log('error ' + error);
      });

    return () => clearInterval(interval2);
  }, []);

  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }

  const column = [
    {
      name: 'Company Name',
      selector: (row) => row.company,
    },
    {
      name: 'Last Updated',
      selector: (row) => row.created_at,
    },
    {
      name: 'title Name',
      selector: (row) => row.name,
    },
  ];

  return (
    // const {setFinancial} = setFinancial;
    <>
      <IdleTimer
        handleSession={handleSession}
        setSessionMessage={setSessionMessage}
      />
      <Header dark={dark} setDark={setDark} />

      {isLoading ? (
        <div className="dashboard-main container container_box">
          <div className="main-tabs-view">
            <div className="title-block d-flex flex-wrap justify-content-between align-items-center">
              <h1 className="financial-heading">Financial Models</h1>
              <div className="financial-main">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th>S. No</th>
                      <th>Company Name</th>
                      <th>Last Updated</th>
                      <th>Title</th>
                      <th>Local/Trading Ticker</th>
                      <th>Sector</th>
                      <th>Country</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financial.map((value, key) => {
                      const {
                        company,
                        country,
                        created_at,
                        name,
                        sector,
                        ticker,
                        files,
                      } = value;
                      return (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{company}</td>
                          <td>{created_at}</td>
                          <td>{name}</td>
                          <td>{ticker}</td>
                          <td>{sector}</td>
                          <td>{country}</td>
                          <td>
                            {files.map((value2, key2) => {
                              const { extension, name } = value2;
                              var url =
                                'https://alpha.akseerresearch.com/' + name;
                              {
                              }
                              var name2 = name.replace('files/', '');
                              if (extension == 'docx') {
                                var file = Wordsvg;
                              } else if (extension == 'pdf') {
                                var file = Pdfsvg;
                              } else if (extension == 'xlsx') {
                                var file = Excelsvg;
                              } else if (extension == 'pptx') {
                                var file = Powerpointsvg;
                              }

                              return (
                                <>
                                  <Link
                                    to={{ pathname: `${url}` }}
                                    target="_blank"
                                    download
                                  >
                                    <img src={file} title={name2} />
                                  </Link>
                                </>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* <DataTable
                  columns={column}
                  data={financial}
                  pagination
                  fixedHeader
                  subHeader
                  subHeaderComponent={
                    <input
                      type="text"
                      placeholder="Search here"
                      ClassName="w-25 form-control"
                    />
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
      <SessionExpire
        sessionModal={sessionModal}
        closeModal={closeModal}
        sessionMessage={sessionMessage}
      />
    </>
  );
}

export default Index;
const styles = {
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};

// export default Index;
