import React, { forwardRef, useEffect, useState, useContext } from 'react';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import axios from 'axios';
// import Select from 'react-select';
// import { Select } from '@mantine/core';
import { Group, Avatar, Text, Select, Button, Loader } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
const Search2 = ({ setSelectedItem, handleSubmit, count, isLoading }) => {
  // console.log(123, data);
  // const allcompany = 1;
  const [companyList, setCompanyList] = useState([]);

  const data2 = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'JPY', label: 'JPY' },
    { value: 'GBP', label: 'GBP' },
    { value: 'CHF', label: 'CHF' },
    { value: 'AUD', label: 'AUD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'SEK', label: 'SEK' },
    { value: 'NOK', label: 'NOK' },
    { value: 'DKK', label: 'DKK' },
    { value: 'AED', label: 'AED' },
    { value: 'SGD', label: 'SGD' },
    { value: 'SAR', label: 'SAR' },
    { value: 'NZD', label: 'NZD' },
    { value: 'MYR', label: 'MYR' },
    { value: 'KWD', label: 'KWD' },
    { value: 'HKD', label: 'HKD' },
    { value: 'BHD', label: 'BHD' },
    { value: 'INR', label: 'INR' },
    { value: 'ZAR', label: 'ZAR' },
    { value: 'OMR', label: 'OMR' },
    { value: 'QAR', label: 'QAR' },
    { value: 'BDT', label: 'BDT' },
    { value: 'BRL', label: 'BRL' },
    { value: 'ARS', label: 'ARS' },
    { value: 'CNY', label: 'CNY' },
    { value: 'LKR', label: 'LKR' },
    { value: 'THB', label: 'THB' },
    { value: 'TRY', label: 'TRY' },
    { value: 'IDR', label: 'IDR' },
    { value: 'MXN', label: 'MXN' },
    { value: 'RUB', label: 'RUB' },
    { value: 'KRW', label: 'KRW' },
    { value: 'CNH', label: 'CNH' },
  ];

  const handleSearchChange = (value) => {
    console.log(value);
    if (value != null) {
      setSelectedItem(value);
    }
  };

  const SelectItem = forwardRef(({ label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* <Avatar src={image} size="sm" /> */}
        <div>
          <Text size="sm">{label}</Text>
          {/* <Text size="xs" opacity={0.65}>
            {name}
          </Text> */}
        </div>
      </Group>
    </div>
  ));

  return (
    <div className="container container_box">
      <div className="row" style={styles.companyname}>
        <div className="col-sm-6 p-0 mb-5">
          <Select
            placeholder="Search..."
            itemComponent={SelectItem}
            data={data2}
            // defaultValue={'USD'}
            onChange={handleSearchChange}
            searchable
            clearable
            // limit={5}
            maxDropdownHeight={400}
            nothingFound="Not Found"
            filter={
              (value, item) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim())
              // item.name.toLowerCase().includes(value.toLowerCase().trim())
            }
            // icon={<IconSearch size="1rem" />}
            rightSection={<IconSearch size="1rem" />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
        </div>
        <div className="col-sm-6">
          <Button
            loading={isLoading ? false : true}
            variant="gradient"
            gradient={{
              from: 'rgba(29, 66, 131, 1)',
              to: 'rgba(29, 66, 131, 1)',
              deg: 90,
            }}
            // color="rgba(163, 36, 36, 1)"
            onClick={handleSubmit}
          >
            {/* <Loader color="rgba(255, 255, 255, 1)" type="bars" size="xs" /> */}
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },

  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '147px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    // width: '92%',
    fontSize: '14px',
    // padding: '0 50px',
    margin: 'auto',
    marginBottom: '10px',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default Search2;
