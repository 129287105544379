import React, { useEffect, useState, useRef } from 'react';
import SlickNextArrow from '../../../common/slicknext';
import SlickPrevArrow from '../../../common/slickprev';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import Slider from 'react-slick';
import { removeSpecialCharacters } from '../../../GlobalFunctions';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

export default function Chart({ section, data, index, company, key2 }) {
  let compId = company;
  const [destroy, setDestroy] = useState(false);
  // const [chartColour, setChartColour] = useState();
  const chartRefFinance = useRef();

  // console.log('financial', data.data);

  const chartName = data.name;

  const ChartColors = [
    '0B254A',
    '3969D5',
    'B7CD5E',
    '717375',
    'D2E5FF',
    '0B254A',
    '3969D5',
    'B7CD5E',
    '717375',
    'D2E5FF',
  ];

  useEffect(() => {
    /*if (data.data[data.data.length - 1].year === '2022') {
      data.data.pop();
    }*/
    // setChartColour(ChartColors[index]);
    // console.log(chartColour);
    // setTimeout(() => {
    // setChartColour(ChartColors.index);

    generateChart(removeSpecialCharacters(`${section}_${data.name}_${index}`));

    // }, 2000);
  }, [company.id, data.data]);

  function getVariancePercent(dataItem) {
    if (dataItem) {
      let value = dataItem.get('valueY');
      let openValue = dataItem.get('openValueY');
      let change = value - openValue;
      return Math.round((change / openValue) * 100);
    }
    return 0;
  }

  const generateChart = (element) => {
    if (destroy === true) {
      chartRefFinance.current.dispose();
    }

    // console.log('dalay =========');

    let root = am5.Root.new(element);
    chartRefFinance.current = root;

    root.setThemes([am5themes_Animated.new(root)]);

    root.numberFormatter.setAll({
      numberFormat: '#a',
      numericFields: ['valueY'],
    });

    setDestroy(true);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        fill: '#396dd578',
        // wheelable: true
      })
    );

    chart.root._logo._childrenDisplay.visible = false;
    // chart._settings.paddingBottom = 25;
    chart._settings.paddingLeft = 5;

    var cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      strokeOpacity: 1,
      strokeWidth: 0.2,
    });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
    });
    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'year',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // min: 0,
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
      })
    );
    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Value',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        // sequencedInterpolation: true,
        categoryXField: 'year',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    );
    series.set('fill', am5.color('#' + ChartColors[index]));
    series.set('stroke', am5.color('#' + ChartColors[index]));

    series.columns.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color('#' + ChartColors[index]),
          },
          {
            // color: am5.color(0x297373)
            color: am5.color('#' + ChartColors[index]),
          },
        ],
        rotation: 45,
      })
    );

    // console.log(data.data , "data data in generate function financials charts");
    for (var i = 0; i < data.data.length; i++) {
      if (typeof data.data[i].value === 'string') {
        // console.log(typeof(data.data[i].value));
        data.data[i].value = parseFloat(data.data[i].value);
      }
    }
    var nowDate = new Date();
    var current_date =
      nowDate.getFullYear() +
      '-' +
      (nowDate.getMonth() + 1) +
      '-' +
      nowDate.getDate();
    var current_time =
      nowDate.getHours() +
      ':' +
      nowDate.getMinutes() +
      ':' +
      nowDate.getSeconds();
    var date_time = current_date + ' ' + current_time;
    var exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data.data,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
      filePrefix: date_time,
    });

    /*exporting.events.on('dataprocessed', function (ev) {
      for (var i = 0; i < ev.data.length; i++) {
        ev.data[i].sum = ev.data[i].value + ev.data[i].value2;
      }
    });*/

    exporting.get('menu').set('items', [
      {
        type: 'format',
        format: 'jpg',
        label: 'JPEG',
        iconTag: 'image.jpg',
      },
      {
        type: 'format',
        format: 'xlsx',
        label: 'Excel',
      },
      {
        type: 'format',
        format: 'pdf',
        label: 'PDF',
      },
    ]);

    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'categoryX',
        x: am5.percent(45),
        y: am5.percent(100),
        centerX: am5.percent(0),
        centerY: am5.percent(0),
      })
    );
    // legend.data.setAll(chart.series.values);

    xAxis.data.setAll(data.data);
    series.data.setAll(data.data);

    series.appear(1000);
    chart.appear(1000, 100);
  };

  return (
    <div key={`${data.name}_${index}`}>
      <p className="chart-title">
        {data.name}
        <span style={{ fontSize: '11px', marginLeft: '3px' }}>
          {data.unit ? `(${data.unit})` : null}
        </span>
      </p>
      <div
        id={removeSpecialCharacters(`${section}_${data.name}_${index}`)}
        className="chart-block"
        key={key2}
      ></div>
    </div>
  );
}
