import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import './technical.css';
import { baseUrl } from '../../../api/baseUrl';

const TechTest = (props) => {
  const [destroy, setDestroy] = useState(false);
  const [cookies] = useCookies();
  const chartRef = useRef();
  let companyId = props.companyId;
  // console.log(props.company.id , "props.company.id")
  // let compId = 189;

  const generateChart = () => {
    if (destroy === true) {
      chartRef.current.dispose();
    }
    let root = am5.Root.new('technical-chart');
    chartRef.current = root;

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    root.numberFormatter.setAll({
      numberFormat: "#.# ' PKR'",
      numericFields: ['valueY'],
    });

    setDestroy(true);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    chart.get('colors').set('step', 2);
    chart.root._logo._childrenDisplay.visible = false;
    chart._settings.paddingBottom = 10;
    chart._settings.paddingLeft = 10;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var valueAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          inside: true,
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        height: am5.percent(70),
      })
    );

    valueAxis.get('renderer').labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    valueAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    valueAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: 'Value',
        fontWeight: 'bold',
        paddingBottom: 5,
        paddingTop: 5,
      })
    );

    var volumeAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          inside: true,
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        height: am5.percent(30),
        layer: 5,
        numberFormat: '#a',
      })
    );

    volumeAxis.get('renderer').labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    volumeAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    volumeAxis.axisHeader.set('paddingTop', 10);
    volumeAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: 'Volume',
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5,
      })
    );

    var dateAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        maxDeviation: 1,
        start: 0,
        end: 0.25,
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    dateAxis.get('renderer').labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    });
    dateAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    var color = root.interfaceColors.get('background');

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var valueSeries = chart.series.push(
      am5xy.CandlestickSeries.new(root, {
        fill: color,
        clustered: false,
        calculateAggregates: true,
        stroke: color,
        name: 'STCK',
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: 'close',
        openValueYField: 'open',
        lowValueYField: 'low',
        highValueYField: 'high',
        valueXField: 'date',
        lowValueYGrouped: 'low',
        highValueYGrouped: 'high',
        openValueYGrouped: 'open',
        valueYGrouped: 'close',
        legendValueText:
          'open: {openValueY} low: {lowValueY} high: {highValueY} close: {valueY}',
        legendRangeValueText: '{valueYClose}',
      })
    );
    // valueSeries.events.once('datavalidated' , (ev) => {
    //     dateAxis.zoomToDates(new Date(2010, 0, 1), new Date(2010, 1, 1))
    // } , this)
    var valueTooltip = valueSeries.set(
      'tooltip',
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        getLabelFillFromSprite: true,
        autoTextColor: false,
        pointerOrientation: 'horizontal',
        labelText:
          "{name}: {valueY} {valueYChangePreviousPercent.formatNumber('[#00ff00]+#,###.##|[#ff0000]#,###.##|[#999999]0')}%",
      })
    );
    valueTooltip
      .get('background')
      .set('fill', root.interfaceColors.get('background'));

    var firstColor = chart.get('colors').getIndex(0);
    var volumeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'STCK',
        clustered: false,
        fill: firstColor,
        stroke: firstColor,
        valueYField: 'volume',
        valueXField: 'date',
        valueYGrouped: 'sum',
        xAxis: dateAxis,
        yAxis: volumeAxis,
        legendValueText: '{valueY}',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    );

    // volumeSeries.events.once('datavalidated' , (ev) => {
    //     dateAxis.zoomToDates(new Date(2010, 0, 1), new Date(2010, 1, 1))
    // } , this)

    //volumeSeries.columns.template.setAll({
    //strokeWidth: 0.5,
    //strokeOpacity: 1,
    //stroke: am5.color(0xffffff)
    //});

    // Add legend to axis header
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/axis-headers/
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var valueLegend = valueAxis.axisHeader.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
      })
    );
    valueLegend.data.setAll([valueSeries]);

    var volumeLegend = volumeAxis.axisHeader.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
      })
    );
    volumeLegend.data.setAll([volumeSeries]);

    // Stack axes vertically
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/#Stacked_axes
    chart.leftAxesContainer.set('layout', root.verticalLayout);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
        xAxis: dateAxis,
      })
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', false);

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    var scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 10,
      })
    );

    // var sbDateAxis = scrollbar.chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
    //     baseInterval: {
    //         timeUnit: "day",
    //         count: 1
    //     },
    //     renderer: am5xy.AxisRendererX.new(root, {
    //     })
    // }));

    // var sbValueAxis = scrollbar.chart.yAxes.push(
    //     am5xy.ValueAxis.new(root, {
    //         renderer: am5xy.AxisRendererY.new(root, {})
    //     })
    // );

    // var sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {

    //     valueYField: "close",
    //     valueXField: "date",
    //     xAxis: sbDateAxis,
    //     yAxis: sbValueAxis
    // }));

    // sbSeries.fills.template.setAll({
    //     visible: true,
    //     fillOpacity: 0.3
    // });

    // =========================================================
    // Data loading
    // =========================================================

    // actual data loading and handling when it is loaded
    function loadData(unit, min, max, side) {
      min = am5.time.round(new Date(min), unit, 1).getTime();

      // Load external data
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Setting_data
      // var url = "https://www.amcharts.com/tools/data/?unit=" + unit + "&start=" + min + "&end=" + max;
      let url = `${baseUrl}/technicalchart/day/${min}/${max}/${companyId}`;

      // Handle loaded data
      am5.net
        .load(url, chart, {
          requestHeaders: [
            { key: 'Authorization', value: `Bearer ${cookies.accessToken}` },
          ],
          // {
          //     'Access-Control-Allow-Origin': '*',
          //     'Authorization': `Bearer ${cookies.accessToken}`
          // }
        })
        .then(function (result) {
          // debugger;
          // console.log(result.response , "result.response in technical");

          // Parse loaded data
          var data = am5.CSVParser.parse(result.response, {
            delimiter: ',',
            reverse: false,
            skipEmpty: true,
            useColumnNames: true,
          });

          // Process data (convert dates and values)
          var processor = am5.DataProcessor.new(root, {
            numericFields: ['date', 'open', 'high', 'low', 'close', 'volume'],
          });
          processor.processMany(data);

          var start = dateAxis.get('start');
          var end = dateAxis.get('end');

          // will hold first/last dates of each series
          var seriesFirst = {};
          var seriesLast = {};

          // Set data
          if (side == 'none') {
            if (data.length > 0) {
              // change base interval if it's different
              if (dateAxis.get('baseInterval').timeUnit != unit) {
                dateAxis.set('baseInterval', { timeUnit: unit, count: 1 });
                // sbDateAxis.set("baseInterval", { timeUnit: unit, count: 1 });
              }

              dateAxis.set('min', min);
              dateAxis.set('max', max);
              dateAxis.setPrivate('min', min); // needed in order not to animate
              dateAxis.setPrivate('max', max); // needed in order not to animate

              valueSeries.data.setAll(data);
              volumeSeries.data.setAll(data);
              // sbSeries.data.setAll(data);

              // console.log(data, 'technical adtatta');
              // dateAxis.zoom(0.5, 1, 0);
              // volumeSeries.events.once("datavalidated", function(ev) {

              //     //pre-zooming to last date

              //     var startDate = data[0].date;

              //     if(data.length >= 270){
              //         startDate = data[data.length - 270].date;
              //     }

              //     ev.target.get("xAxis").zoomToDates(new Date(startDate), new Date(data[data.length - 1].date));
              // });
            }
          } else if (side == 'left') {
            // save dates of first items so that duplicates would not be added
            seriesFirst[valueSeries.uid] = valueSeries.data.getIndex(0).date;
            seriesFirst[volumeSeries.uid] = volumeSeries.data.getIndex(0).date;
            // seriesFirst[sbSeries.uid] = sbSeries.data.getIndex(0).date;

            for (var i = data.length - 1; i >= 0; i--) {
              var date = data[i].date;
              // only add if first items date is bigger then newly added items date
              if (seriesFirst[valueSeries.uid] > date) {
                valueSeries.data.unshift(data[i]);
              }
              if (seriesFirst[volumeSeries.uid] > date) {
                volumeSeries.data.unshift(data[i]);
              }
              // if (seriesFirst[sbSeries.uid] > date) {
              //     sbSeries.data.unshift(data[i]);
              // }
            }

            // update axis min
            min = Math.max(min, absoluteMin);
            dateAxis.set('min', min);
            dateAxis.setPrivate('min', min); // needed in order not to animate
            // recalculate start and end so that the selection would remain
            dateAxis.set('start', 0);
            dateAxis.set('end', (end - start) / (1 - start));
          } else if (side == 'right') {
            // save dates of last items so that duplicates would not be added
            seriesLast[valueSeries.uid] = valueSeries.data.getIndex(
              valueSeries.data.length - 1
            ).date;
            seriesLast[volumeSeries.uid] = volumeSeries.data.getIndex(
              volumeSeries.data.length - 1
            ).date;
            // seriesLast[sbSeries.uid] = sbSeries.data.getIndex(sbSeries.data.length - 1).date;

            for (var i = 0; i < data.length; i++) {
              var date = data[i].date;
              // only add if last items date is smaller then newly added items date
              if (seriesLast[valueSeries.uid] < date) {
                valueSeries.data.push(data[i]);
              }
              if (seriesLast[volumeSeries.uid] < date) {
                volumeSeries.data.push(data[i]);
              }
              // if (seriesLast[sbSeries.uid] < date) {
              //     sbSeries.data.push(data[i]);
              // }
            }
            // update axis max
            max = Math.min(max, absoluteMax);
            dateAxis.set('max', max);
            dateAxis.setPrivate('max', max); // needed in order not to animate

            // recalculate start and end so that the selection would remain
            dateAxis.set('start', start / end);
            dateAxis.set('end', 1);
          }
        });
    }

    function loadSomeData() {
      var start = dateAxis.get('start');
      var end = dateAxis.get('end');

      var selectionMin = Math.max(
        dateAxis.getPrivate('selectionMin'),
        absoluteMin
      );
      var selectionMax = Math.min(
        dateAxis.getPrivate('selectionMax'),
        absoluteMax
      );

      var min = dateAxis.getPrivate('min');
      var max = dateAxis.getPrivate('max');

      // if start is less than 0, means we are panning to the right, need to load data to the left (earlier days)
      if (start < 0) {
        loadData(currentUnit, selectionMin, min, 'left');
      }
      // if end is bigger than 1, means we are panning to the left, need to load data to the right (later days)
      if (end > 1) {
        loadData(currentUnit, max, selectionMax, 'right');
      }
    }

    var currentDate = new Date();
    var currentUnit = 'day';

    // initially load 50 days
    var min = currentDate.getTime() - am5.time.getDuration('day', 500);
    var max = currentDate.getTime();

    // limit to the data's extremes
    var absoluteMax = max;
    var absoluteMin = new Date(2000, 0, 1, 0, 0, 0, 0);

    // load data when panning ends
    chart.events.on('panended', function () {
      loadSomeData();
    });

    var wheelTimeout;
    chart.events.on('wheelended', function () {
      // load data with some delay when wheel ends, as this is event is fired a lot
      // if we already set timeout for loading, dispose it
      if (wheelTimeout) {
        wheelTimeout.dispose();
      }

      wheelTimeout = chart.setTimeout(function () {
        loadSomeData();
      }, 50);
    });

    // load some initial data
    loadData('day', min, max, 'none');

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 500);
  };

  useEffect(() => {
    generateChart();
  }, [companyId]);

  return (
    <>
      <div id="technical-chart" className="technical-chart"></div>
    </>
  );
};

export default TechTest;
