import React, { useEffect, useState, useContext } from 'react';
import './omc.css';

import { valueCheckGlobal } from '../../../GlobalFunctions';
import axios from 'axios';
import LoadingImage from '../../../assets/media/loader.gif';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.png';
import { baseUrl } from '../../../api/baseUrl';
// import Search2 from '../search2';
import Nav from '../nav1';

import Header from '../../../common/header/header';
import Loader from '../../../assets/media/_loader.gif';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';

const OMCs = (props) => {
  const [omc, setOmc] = useState([]);
  const [head, setHead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const d = new Date();
  let current_date =
    d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear();
  // console.log(current_date);

  useEffect(() => {
    setOmc([]);

    axios
      .get(`${baseUrl}/omc`)
      .then(function (response) {
        setOmc(response.data.data);
        setHead(response.data.head);
        setIsLoading(true);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log('inflation====>', error);
      });
    // console.log(123, selectedItem);
  }, []);

  let boldHeadings = ['Industry', 'PSO', 'SHEL', 'APL', 'HASCOL', 'CYNERGYICO'];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  // console.log(123);
  return (
    <>
      <Header />
      <div className="omc-main analyst">
        <div className="row omcboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
      </div>
      {/* {console.log(123, omc)} */}
      {omc.length && isLoading ? (
        <div className="omc-main analyst">
          <div
            className="row container container_box p-0"
            style={styles.companyname}
          >
            <div className="col-sm-10 p-0 title omc_lucky">
              <p className="heading mb-0 p-0">Akseer Research (Pvt) Ltd.</p>
              <p className="amount_symbol mb-0 p-0">{current_date}</p>
              <p className="heading mb-0 p-0">OMCs Cumulative Sales</p>
            </div>

            <div className="col-sm-2 logo p-0" style={styles.companylogoimage}>
              <img src={companylogo} style={styles.companylogo} />
            </div>
          </div>

          <div className="OMC_table">
            <table
              className="table table-responsive container container_box"
              style={{ width: '50%' }}
            >
              <thead>
                <tr>
                  <th>K Tonnes</th>
                  {head.map((value, index) => {
                    return <th>{value}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {omc.map((value, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          fontWeight: headingCheck(value.label) ? '800' : '0',
                        }}
                      >
                        {value.label}
                      </td>
                      {value.data.map((value2, index2) => {
                        return (
                          <td
                            style={{
                              fontWeight: headingCheck(value.label)
                                ? '800'
                                : '0',
                            }}
                          >
                            {value2.value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="container container_box pl-0"
              style={{ width: '50%' }}
            >
              <p>Source: OCAC, Akseer Research</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '50%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default OMCs;
