import { ActionTypes } from "../constants/ActionTypes";

const inititalState = {
    isAuthenticated: false,
}


export const authReducer = (state = inititalState, {type, payload}) => {

    if ( type == ActionTypes.SET_AUTH ) {
        localStorage.setItem("auth", JSON.stringify(payload));
        localStorage.setItem("isAuthenticated", true);
        return { ...state, auth: payload };
    }

    if (type == ActionTypes.GET_AUTH ) {
        const auth = localStorage.getItem('auth');
        const isAuthenticated = JSON.parse(localStorage.getItem('isAutenticated'));
        // console.log("this is auth", auth);
        // console.log("this is isAuthenticated", isAuthenticated);
        return {...state, isAuthenticated: isAuthenticated, auth: auth};
    }
    
    return state;
};