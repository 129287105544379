import React, { useEffect, useState } from 'react';
import './comparasion.css';
// import Header from '../../common/header/header';
import Search2 from '../askanalyst/search2';
import Nav from '../askanalyst/nav';
import { baseUrl, linecharticon, sorticon } from '../../api/baseUrl';
import IncomeStatementTableModal from '../../layout/modals/IncomeStatementTableModal';
import LoadingImage from '../../assets/media/loader.gif';
import { minusValueCheckGlobal } from '../../GlobalFunctions';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { SegmentedControl } from '@mantine/core';
import ComparisonChartModal from '../../layout/modals/ComparisonChartModal';
import { modal_chart_icon, modal_table_icon } from '../../api/baseUrl';

const Peercomparasion = () => {
  const location = useLocation();
  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [valData, setValData] = useState([]);
  const [comparison, setComparison] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [chartActive, setChartActive] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [label, setLabel] = useState('');
  const [slice, setSlice] = useState(false);

  var segments = location.pathname.split('/');
  //console.log(segments[2]);

  useEffect(() => {
    fetch(`${baseUrl}${location.pathname}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setComparison(data);
        setIsLoading(true);
      });
  }, [companyId]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...comparison].sort((a, b) => {
      const aValue = a.data.find((item) => item.year === key)?.value;
      const bValue = b.data.find((item) => item.year === key)?.value;
      return direction === 'ascending' ? aValue - bValue : bValue - aValue;
    });

    setComparison(sortedData);
  };

  // const [lable, setLable] = useState('');

  const handleColumnChartModal = (year) => {
    var objects = [];

    for (var x = 0; x < comparison.length; x++) {
      let obj = comparison[x].data.find((o) => o.year === year);
      objects.push({ year: comparison[x].symbol, value: obj.value });
    }
    var sector = comparison[0]['sector'];
    sector = sector.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });

    setSlice(true);
    setCompanyName(sector + ' Sector Peers');
    setLabel(
      sector +
        ' Sector Peers - ' +
        year +
        ' ' +
        comparison[0].description +
        ' ' +
        comparison[0]['unit']
    );
    setChartData(objects);
    setChartActive(true);

    // console.log(comparison);
  };
  const handleChartModal = (companyName, label_parameter, chartData) => {
    setSlice(false);
    setCompanyName(companyName);
    setLabel(
      companyName + ' - ' + label_parameter + ' - ' + comparison[0]['unit']
    );
    //console.log(123, comparison, label);
    setChartData(chartData);
    setChartActive(true);
  };

  return (
    <>
      {/* <Header /> */}
      {comparison.length && isLoading ? (
        <div className="peer-comarasion">
          <div className="container container_box">
            <div className="comparasion_table">
              <table className="mb-3">
                <tr>
                  <th>Peer Comparison - {comparison[0].description}</th>
                </tr>
              </table>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th onClick={() => handleSort('name')}>Company</th>
                    <th></th>
                    <th onClick={() => handleSort('symbol')}>Symbol</th>
                    <th>Sector</th>
                    <th>Last 10 Years</th>
                    {comparison[0].data.slice(-5).map((y, i) =>
                      i !== 5 ? (
                        <th className="sort-main" key={i}>
                          {y.year}
                          <div
                            className="sorticon"
                            onClick={() => handleSort(y.year)}
                            dangerouslySetInnerHTML={{ __html: sorticon }}
                          />
                          <div
                            onClick={() => handleColumnChartModal(y.year)}
                            dangerouslySetInnerHTML={{
                              __html: linecharticon,
                            }}
                          />
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {comparison.map((value, index) => {
                    var abc = value.data.map(function (el) {
                      return el.value;
                    });
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={`http://admin.akseerresearch.com/logo25/${value.symbol}.svg`}
                            alt=""
                          />
                        </td>
                        <td>{value.name}</td>
                        <td>
                          <span
                            style={styles.chart}
                            onClick={() => {
                              handleChartModal(
                                value.symbol,
                                comparison[0].description,
                                value.data
                              );
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: linecharticon,
                              }}
                            />
                          </span>
                        </td>
                        <td>{value.symbol}</td>
                        <td>
                          <span className="peer-cement-blue">
                            {value.sector}
                          </span>
                        </td>
                        <td>
                          <img
                            src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
                            width="50px"
                            height="20px"
                          />
                        </td>
                        {value.data.slice(-5).map((d, i) => {
                          return i !== 5 ? (
                            <td key={i}>{minusValueCheckGlobal(d.value)}</td>
                          ) : null;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
      {chartActive && (
        <ComparisonChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          label={label}
          chartData={chartData}
          slice={slice}
        />
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};

export default Peercomparasion;
