import React, { useEffect, useState } from 'react';
import ValuationChart from './valuationChart';
import Chart from './chart';
// import axios from 'axios';
import './valuation.css';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import { getLoader } from '../../../GlobalFunctions';
import { RangeSlider, Slider } from '@mantine/core';
import ValuationChartModal from './ValuationChartModal';
import ApexCharts from 'apexcharts';
// import classes from './Demo.module.css';

const Valuation = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  let objIds = [];
  // let objIdTwo = [];
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);

  const [valData, setValData] = useState([]);
  const [valuation, setValuation] = useState([]);
  const [series, setSeries] = useState('PE');
  const [peChartActive, setPeChartActive] = useState('1M');
  const [pbvChartActive, setPbvChartActive] = useState('1M');
  const [psChartActive, setPsChartActive] = useState('1M');
  const [evSalesChartActive, setEvSalesChartActive] = useState('1M');
  const [evEbidtaChartActive, setEvEbidtaChartActive] = useState('1M');
  const [company, setCompany] = useState({});

  useEffect(() => {
    setIsLoading(false);

    fetch(`${baseUrl}/generatevaluation/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValuation(data);
        console.log(123, data);
        setIsLoading(true);
      });
  }, [companyId]);

  /* useEffect(() => {
    setIsLoading(false);

    fetch(`${baseUrl}/valuation/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        setIsLoading(true);
      });
  }, [companyId]);

  let arrExist = Object.keys(valData); */

  if (valuation !== undefined) {
    objIds = [
      {
        id: 'v1',
        title: 'P/E Multiple',
        key: 'price_to_earning',
        color: '0B254A',
        description:
          'FFL is trading at a TTM P/E multiple of -1.9x, which is close to 1 standard deviation above the mean TTM P/E of -4.6x',
        data: valData.PE,
      },
      {
        id: 'v2',
        title: 'P/BV Multiple',
        key: 'price_to_book_value',
        color: '3969D5',
        description:
          'FFL is currently trading at a P/BV multiple of 0.9x, which is just below its mean 1.13x.',
        data: valData.PBV,
      },
      {
        id: 'v3',
        title: 'P/S Multiple',
        key: 'price_to_sales',
        color: 'B7CD5E',
        description:
          'FFL is trading at a TTM P/S multiple of 0.7x, which is close to 2 standard deviation below the mean TTM P/S of 1.99x.',
        data: valData.PS,
      },
      {
        id: 'v4',
        title: 'EV/EBITDA Multiple',
        key: 'ev_to_ebidta',
        color: '717375',
        description:
          'FFL is currently trading at a EV/EBITDA multiple of -8.0x, which is just below its mean -7.87x',
        data: valData.EV_EBIDTA,
      },
      {
        id: 'v5',
        title: 'EV/Sales Multiple',
        key: 'ev_to_sales',
        color: 'D2E5FF',
        description:
          'FFL is trading at a TTM EV/Sales multiple of 0.7x, which is close to 2 standard deviation below the mean TTM EV/Sales of 1.99x.',
        data: valData.EV_SALES,
      },
    ];
  }

  var frequencies = [
    { id: '1M', value: '1M' },
    { id: '3M', value: '3M' },
    { id: '6M', value: '6M' },
    { id: '1Y', value: '1Y' },
    { id: '3Y', value: '3Y' },
    { id: '5Y', value: '5Y' },
  ];

  const handleFrequency = (value, chart_id) => {
    console.log(value, chart_id, series, valuation);
    if (chart_id == 'PE') {
      setPeChartActive(value);
    } else if (chart_id == 'PBV') {
      setPbvChartActive(value);
    } else if (chart_id == 'PS') {
      setPsChartActive(value);
    } else if (chart_id == 'EV_SALES') {
      setEvSalesChartActive(value);
    } else if (chart_id == 'EV_EBIDTA') {
      setEvEbidtaChartActive(value);
    }

    ApexCharts.exec(
      chart_id,
      'updateSeries',
      [
        {
          data: valuation[value],
        },
      ],
      true
    );
  };
  // console.log('marks', marks);

  return (
    <>
      {/* <ValuationSection company={props.company} /> */}
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {/* <Slider
        defaultValue={15}
        label={(val) => marks.find((mark) => mark.value === val).label}
        step={25}
        marks={marks}
        // styles={{ markLabel: { display: 'block' } }}
      /> */}

      {isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="valuation-main">
              <div className="d-flex flex-wrap justify-content-between">
                {valuation['PE'] !== undefined &&
                Object.keys(valuation.PE).length ? (
                  <div
                    key={'pe_valuation'}
                    className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                  >
                    <div className="valuation-block col-12 p-0">
                      <div className="title-block block-head-bg-dark">
                        <h4>P/E Multiple</h4>
                      </div>
                      <div className="col-12 p-0 data-block flex-wrap justify-content-between">
                        <div className="indices-chart order-md-12 pr-0">
                          <div className="chart__tools">
                            <div className="chart__range">
                              {frequencies.map((value, index) => {
                                return (
                                  <div
                                    className={
                                      peChartActive == value.value
                                        ? 'chart_rangeitem chartrange_item--selected'
                                        : 'chart_rangeitem'
                                    }
                                    data-name={value.value}
                                    onClick={() => {
                                      handleFrequency(value.value, 'PE');
                                    }}
                                  >
                                    {value.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <ValuationChartModal
                          current_feed={valuation}
                          chart_id={peChartActive}
                          chart={'PE'}
                          series={'PE'}
                          color={'0B254A'}
                        />{' '}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {valuation['PBV'] !== undefined &&
                Object.keys(valuation.PBV).length ? (
                  <div
                    key={'pbv_valuation'}
                    className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                  >
                    <div className="valuation-block col-12 p-0">
                      <div className="title-block block-head-bg-dark">
                        <h4>P/BV Multiple</h4>
                      </div>
                      <div className="col-12 p-0 data-block flex-wrap justify-content-between">
                        <div className="indices-chart order-md-12 pr-0">
                          <div className="chart__tools">
                            <div className="chart__range">
                              {frequencies.map((value, index) => {
                                return (
                                  <div
                                    className={
                                      pbvChartActive == value.value
                                        ? 'chart_rangeitem chartrange_item--selected'
                                        : 'chart_rangeitem'
                                    }
                                    data-name={value.value}
                                    onClick={() => {
                                      handleFrequency(value.value, 'PBV');
                                    }}
                                  >
                                    {value.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <ValuationChartModal
                          current_feed={valuation}
                          chart_id={pbvChartActive}
                          chart={'PBV'}
                          series={'PBV'}
                          color={'3969D5'}
                        />{' '}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {valuation['PS'] !== undefined &&
                Object.keys(valuation.PS).length ? (
                  <div
                    key={'pe_valuation'}
                    className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                  >
                    <div className="valuation-block col-12 p-0">
                      <div className="title-block block-head-bg-dark">
                        <h4>P/S Multiple</h4>
                      </div>
                      <div className="col-12 p-0 data-block flex-wrap justify-content-between">
                        <div className="indices-chart order-md-12 pr-0">
                          <div className="chart__tools">
                            <div className="chart__range">
                              {frequencies.map((value, index) => {
                                return (
                                  <div
                                    className={
                                      psChartActive == value.value
                                        ? 'chart_rangeitem chartrange_item--selected'
                                        : 'chart_rangeitem'
                                    }
                                    data-name={value.value}
                                    onClick={() => {
                                      handleFrequency(value.value, 'PS');
                                    }}
                                  >
                                    {value.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <ValuationChartModal
                          current_feed={valuation}
                          chart_id={psChartActive}
                          chart={'PS'}
                          series={'PS'}
                          color={'B7CD5E'}
                        />{' '}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {valuation['EV_SALES'] !== undefined &&
                Object.keys(valuation.EV_SALES).length ? (
                  <div
                    key={'pe_valuation'}
                    className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                  >
                    <div className="valuation-block col-12 p-0">
                      <div className="title-block block-head-bg-dark">
                        <h4>EV/Sales Multiple</h4>
                      </div>
                      <div className="col-12 p-0 data-block flex-wrap justify-content-between">
                        <div className="indices-chart order-md-12 pr-0">
                          <div className="chart__tools">
                            <div className="chart__range">
                              {frequencies.map((value, index) => {
                                return (
                                  <div
                                    className={
                                      evSalesChartActive == value.value
                                        ? 'chart_rangeitem chartrange_item--selected'
                                        : 'chart_rangeitem'
                                    }
                                    data-name={value.value}
                                    onClick={() => {
                                      handleFrequency(value.value, 'EV_SALES');
                                    }}
                                  >
                                    {value.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <ValuationChartModal
                          current_feed={valuation}
                          chart_id={evSalesChartActive}
                          chart={'EV_SALES'}
                          series={'EV_SALES'}
                          color={'717375'}
                        />{' '}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {valuation['EV_EBIDTA'] !== undefined &&
                Object.keys(valuation.EV_EBIDTA).length ? (
                  <div
                    key={'pe_valuation'}
                    className="col-xl-6 d-flex flex-wrap justify-content-between px-0 pl-xl-2"
                  >
                    <div className="valuation-block col-12 p-0">
                      <div className="title-block block-head-bg-dark">
                        <h4>EV/EBITDA Multiple</h4>
                      </div>
                      <div className="col-12 p-0 data-block flex-wrap justify-content-between">
                        <div className="indices-chart order-md-12 pr-0">
                          <div className="chart__tools">
                            <div className="chart__range">
                              {frequencies.map((value, index) => {
                                return (
                                  <div
                                    className={
                                      evEbidtaChartActive == value.value
                                        ? 'chart_rangeitem chartrange_item--selected'
                                        : 'chart_rangeitem'
                                    }
                                    data-name={value.value}
                                    onClick={() => {
                                      handleFrequency(value.value, 'EV_EBIDTA');
                                    }}
                                  >
                                    {value.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <ValuationChartModal
                          current_feed={valuation}
                          chart_id={evEbidtaChartActive}
                          chart={'EV_EBIDTA'}
                          series={'EV_EBIDTA'}
                          color={'D2E5FF'}
                        />{' '}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{getLoader()}</>
      )}
    </>
  );
};

export default Valuation;
