// import React from "react";
import React, { useState, useEffect } from 'react';
import Header from '../../../common/header/header';
import { MultiSelect } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Pagination } from '@mantine/core';
import Luckylatest from '../../../../src/assets/media/images/latest1img.svg';
import luckyicon from '../../../../src/assets/media/images/luck-icon.svg';
import AELLucky from '../../../../src/assets/media/images/latest2img.svg';
import AELicon from '../../../../src/assets/media/images/ael_icon.svg';
import UblLucky from '../../../../src/assets/media/images/latest3img.svg';
import alnrsicon from '../../../../src/assets/media/images/alnrs_icon.svg';
import cementicon from '../../../../src/assets/media/images/cement_icon.svg';
import luckbox from '../../../../src/assets/media/images/luckbox1.svg';
import ublboxed from '../../../../src/assets/media/images/ubl_boxed.svg';
import sapticon from '../../../../src/assets/media/images/sapt_icon.svg';
import federalboxed from '../../../../src/assets/media/images/federal_boxed.svg';
import bglicon from '../../../../src/assets/media/images/bgl_icon.svg';
import srccement from '../../../../src/assets/media/images/src_cement.svg';
import srcicon from '../../../../src/assets/media/images/src_icon.svg';
import cementlucky from '../../../../src/assets/media/images/cement_lucky.svg';
import dfsmicon from '../../../../src/assets/media/images/dfsm_icon.svg';
import luckystrucure from '../../../../src/assets/media/images/lucky_strucure.svg';
import habsmicon from '../../../../src/assets/media/images/habsm_icon.svg';
import worklucky from '../../../../src/assets/media/images/work_lucky.svg';
import construction from '../../../../src/assets/media/images/construction.svg';
import constructionlucky from '../../../../src/assets/media/images/construction1.svg';
// import { Link } from 'react-router-dom';
import { SegmentedControl } from '@mantine/core';
import Search2 from '../search2';
import Nav from '../nav';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import './news.css';
import Paginate from 'react-paginate';
import { baseUrl, downicon } from '../../../api/baseUrl';
import { formatDate } from '../../../GlobalFunctions';

// import { downicon } from '../../api/baseUrl';
import { Select } from '@mantine/core';
import { Popover, Button, Checkbox } from '@mantine/core';
function Index() {
  const location = useLocation();

  const [company, setCompany] = useState({});
  let compId = location.state != undefined ? location.state.id : 189;
  let symbol = company.symbol;
  const [companyId, setCompanyId] = useState(compId);
  const [news, setNews] = useState([]);
  const [check, setCheck] = useState(false);

  console.log(company.symbol);

  useEffect(() => {
    // if (company.symbol != undefined) {
    fetch(`${baseUrl}/news/${symbol}/1000`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          setNews(data);
          setCheck(true);
        }
      });
    // }
  }, [symbol, compId]);
  // console.log(symbol, news);
  /////////////////////Pagination/////////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = news.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const handlePostsPerPage = (e) => {
    setCurrentPage(1);
    setPostsPerPage(e.target.value);
  };
  ////////////////////End Pagination///////////////////////////

  return (
    <>
      <Header />

      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />

      <div className="main_news">
        <div className="container container_box">
          <div className="news_table">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>
                    <span className="filling-filter">Date</span>
                  </th>
                  <th>Detail</th>
                  <th>Company/Sector</th>
                  <th>
                    <span className="filling-filter">Outlook</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.length ? (
                  currentPosts.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <Link
                            to={{ pathname: `${item.link}` }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </Link>
                        </td>
                        <td>{formatDate(item.date)}</td>
                        <td>
                          <Link
                            to={{ pathname: `${item.link}` }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.description.slice(0, 50)}...
                          </Link>
                        </td>
                        <td>
                          <span className="sector-economy">{item.sector}</span>
                        </td>
                        <td>
                          <span
                            className={
                              item.type == 'Positive'
                                ? 'positive-green'
                                : item.type == 'Negative'
                                ? 'negative-red'
                                : 'neutral-grey'
                            }
                          >
                            {item.type}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {check ? 'No Record Found' : 'Loading...'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="dropdown-pagination">
              <div className="select_drop">
                <select
                  className="selectpicker"
                  value={postsPerPage}
                  onChange={handlePostsPerPage}
                >
                  <option data-tokens="25">25</option>
                  <option data-tokens="50">50</option>
                  <option data-tokens="100">100</option>
                  {/* <option data-tokens="All">All</option> */}
                </select>
              </div>
              <Paginate
                onPageChange={paginate}
                pageCount={Math.ceil(news.length / postsPerPage)}
                previousLabel={'Prev'}
                nextLabel={'Next'}
                containerClassName={'pagination'}
                pageLinkClassName={'page-number'}
                previousLinkClassName={'page-number'}
                nextLinkClassName={'page-number'}
                activeLinkClassName={'active'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
