import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import { modal_close_icon, modal_excel_icon } from '../../api/baseUrl';
// import { ApexOptions } from 'apexcharts';
import { valueCheckGlobal } from '../../GlobalFunctions';

const ChartModal = ({
  chartActive,
  setChartActive,
  companyname,
  label,
  chartData,
  slice,
}) => {
  const theme = useTheme();
  let slicevalue = 10;
  if (slice) {
    slicevalue = 100;
  }

  console.log(chartData);

  const value = chartData.slice(-slicevalue).map((d) => d['value']);
  const years = chartData.slice(-slicevalue).map((d) => d['year']);

  const ApexOptions = {
    chart: {
      id: 'apexchart-example',
      foreColor: theme.palette.primary.main,
      type: 'bar',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      decimalsInFloat: 2,
    },
    xaxis: {
      // categories: [
      //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
      // ],
      categories: years,
    },
    legend: {
      width: 400,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      width: 1,
      curve: 'straight',
      // curve: ['smooth', 'straight', 'stepline'],
    },
    series: [
      {
        name: '',
        type: 'bar',
        // data: [23, 42, 35, 27, 43, 22, 17, 31, 42, 22, 12, 16],
        data: value,
      },
    ],
  };

  // console.log(years, value);

  // return ;
  // const [chartShow, setChartShow] = useState(true);

  return (
    <>
      <Modal
        style={styles.modalBackdrop}
        className="table-modal session-modal"
        show={chartActive ? true : false}
        centered
      >
        <Modal.Header style={styles.modalHeader}>
          <Modal.Title
            style={styles.modalTitle}
            className="text-left d-inline-block w-100"
          >
            {companyname}
            <span
              style={styles.close}
              onClick={() => {
                setChartActive(false);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: modal_close_icon }} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <div>{label} </div>
          <ReactApexChart options={ApexOptions} series={ApexOptions.series} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChartModal;

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 25px',
    letterSpacing: '0.3px',
    fontWeight: '700',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3969D5',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 0px',
  },
  close: {
    cursor: 'pointer',
    float: 'right',
    color: '#3d3d3d',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
};
