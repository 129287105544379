import { combineReducers } from "redux";
// import { productReducer } from "./ProductReducer";
import { authReducer } from "./AuthReducer";
import { CompanyReducer } from './CompanyReducer';
import { searchDataReducer } from "./SearchDataReducer";

const reducers = combineReducers({
    // allProducts: productReducer,
    authentication: authReducer,
    company: CompanyReducer,
    searchData: searchDataReducer,
})

export default reducers;