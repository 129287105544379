import { ActionTypes } from "../constants/ActionTypes"

const inititalState = {
    allData: '',
    selectedData: {
                    id: 8,name: 'Lucky Cement'
                },
    data: '',
}

export const searchDataReducer = (state = inititalState, {type, payload}) => {
    switch(type) {
        case ActionTypes.SET_ALL_DATA:
            return { ...state, allData: payload };
        case ActionTypes.GET_ALL_DATA:
            return state.allData;
        case ActionTypes.SET_SELECTED_DATA:
            // console.log(payload);
            return { ...state, selectedData: payload };
        case ActionTypes.GET_SELECTED_DATA:
            return state.selectedData;
        case ActionTypes.SET_DATA:
            return { ...state, data: payload }
        default:
            return state;
    }    
};