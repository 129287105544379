import React from 'react';
import resetImg from '../../assets/media/images/reset.svg';
import './reset.css';
import _reset from './_reset';
import { Link } from 'react-router-dom';
import askanalystlogo from '../../assets/media/images/askanalyst.png';
import alphaanalystlogo from '../../assets/media/images/alphaanalyst.svg';
// https://askanalyst-testing.akseerresearch.com/api/login

// https://askanalyst-testing-demo.akseerresearch.com/api/verify/MjU3

const ResetPassword = () => {
  let logo;
  if (window.location.host === 'analyst.alphacapital.com.pk') {
    logo = alphaanalystlogo;
  } else if (window.location.host === 'askanalyst.akseerresearch.com') {
    logo = askanalystlogo;
  } else {
    logo = askanalystlogo;
  }
  return (
    <>
      <section className="login-sec">
        <div className="logo">
          <Link to="/" replace>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-md-7 d-flex flex-column align-items-center justify-content-around">
                  <div className="reset-img">
                    <img src={resetImg} alt="login-img" />
                  </div>
                  <Link to="/" style={styles.goHome}>
                    <svg
                      style={styles.iconRotate}
                      width="20"
                      height="15"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#ffffff"
                        d="M1600 960q0 54-37 91l-651 651q-39 37-91 37-51 0-90-37l-75-75q-38-38-38-91t38-91l293-293h-704q-52 0-84.5-37.5t-32.5-90.5v-128q0-53 32.5-90.5t84.5-37.5h704l-293-294q-38-36-38-90t38-90l75-75q38-38 90-38 53 0 91 38l651 651q37 35 37 90z"
                      ></path>
                    </svg>
                    <span className="ml-1" style={styles.goBackContent}>
                      Go Back to Sign in
                    </span>
                  </Link>
                </div>
                <div className="col-md-5 d-flex flex-column justify-content-center">
                  <div className="form-area ml-md-auto mx-auto">
                    <h3 className="reset-title">Reset Password</h3>
                    <_reset />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;

const styles = {
  goHome: {
    color: '#ffffff',
    width: 'auto',
    padding: '10px',
    background: '#3969d5',
    // minHeight: "40px",
    marginTop: '30px',
    border: 'none',
    borderRadius: '23px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  goBackContent: {
    lineHeight: '1',
  },
};
