import React, { useState, useEffect } from 'react';
// import './databank.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
function Nav() {
  const [cookies, setCookies, removeCookies] = useCookies();

  const nav = [
    { value: 'food', label: 'Food' },
    { value: 'chemical', label: 'Chemical' },
  ];
  const location = useLocation();

  // var current_date = new Date();
  // var one_year_ago = current_date.setFullYear(current_date.getFullYear() - 1);

  return (
    <>
      <Row className="justify-content-between w-100 m-0">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Container fluid>
            <Row className="justify-content-between w-100 m-0">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  {nav.map((value, index) => {
                    {
                      /* console.log(
                      location.pathname,
                      location.pathname == 'databank/'
                    ); */
                    }
                    return (
                      <li className="nav-item">
                        <Link
                          className={
                            'nav-link ' +
                            (location.pathname == '/databank/' + value.value
                              ? 'active'
                              : '')
                          }
                          to={{ pathname: `/databank/${value.value}` }}
                        >
                          <button
                            type="button"
                            id="controlled-tab-example-tab-technical"
                            role="tab"
                            data-rr-ui-event-key="technical"
                            aria-controls="controlled-tab-example-tabpane-technical"
                            tabIndex="-1"
                          >
                            {value.label}
                          </button>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Col
                md={2}
                xs={6}
                className="d-flex align-items-center justify-content-end pr-0"
              ></Col>
            </Row>
          </Container>
        </nav>
      </Row>
    </>
  );
}

export default Nav;
