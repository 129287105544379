import { ActionTypes } from "../constants/ActionTypes"

let inititalState = [{
    selectedCompanyId: null,
    companyData: null,
}];


export const CompanyReducer = (state = inititalState, {type, payload}) => {
    if(type === ActionTypes.SET_COMPANY_DETAIL) {
        return { ...state, companyData: payload }
    }
    return inititalState;
}

// export const setSelectedCompanyIdReducer = () => {
//     switch(type) { 
//         case ActionTypes.SET_SELECTED_COMPANY_ID:
//             return { ...state, selectedCompanyId: payload };
//         default:
//             return state;
//     }    
// };

// export const getSelectedCompanyIdReducer = (state = inititalState, {type, payload}) => {
//     switch(type) { 
//         case ActionTypes.GET_SELECTED_COMPANY_ID:
//             return { state:selectedCompanyId };
//         default:
//             return state;
//     }    
// };

