import React from 'react';
import Modal from 'react-bootstrap/Modal';
import sessionIcon from '../../assets/media/icons/sessionIcon.svg';

const SessionExpire = ({ sessionModal, closeModal, sessionMessage }) => {
  return (
    <>
      <Modal
        style={styles.modalBackdrop}
        className="table-modal session-modal"
        show={sessionModal ? true : false}
        centered
      >
        <Modal.Header style={styles.modalHeader}>
          <Modal.Title
            style={styles.modalTitle}
            className="text-left d-inline-block w-100"
          >
            Session Timeout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <div
            style={styles.sessionAlertContent}
            className="session-alert-content"
          >
            <p style={styles.para}>
              <span style={styles.iconSpan}>
                <img src={sessionIcon} />
              </span>{' '}
              {sessionMessage}
            </p>
            <button onClick={closeModal} style={styles.buttonOK}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SessionExpire;

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 25px',
    letterSpacing: '0.3px',
    fontWeight: '700',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3d3d3d',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 50px',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
};
