import React, { useState } from 'react';
import TechnicalChart from './technical.jsx';
import './technical.css';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import { getLoader } from '../../../GlobalFunctions';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';

const Technical = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  // console.log(props.company , "company");
  return (
    <>
      <Header />
      <Search2 companyId={companyId} setCompanyId={setCompanyId} />
      <Nav companyId={companyId} />
      <div className="dashboard-main container container_box">
        <div className="overview-main">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-xl-12 d-flex p-0">
              <div className="technical-block col-lg-12 p-0">
                <div className="title-block block-head-bg-dark">
                  <h4>Technical Chart</h4>
                </div>
                <div className="col-12 data-block d-flex flex-wrap justify-content-between">
                  <TechnicalChart companyId={companyId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Technical;
