import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ValuationChartModal = (props) => {
  // const theme = useTheme();

  // const years = chartData.slice(-10).map((d) => d['year']);
  // const value = chartData.slice(-10).map((d) => d['value']);
  var xaxis = {};
  var tooltip = { x: { format: 'MMM dd, yyyy' } };
  if (props.chart_id == '1D') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'hh:mm TT',
      },
      // tickAmount: 3,
    };
    tooltip = {
      x: { format: 'MMM dd hh:mm' },
    };
  } else if (props.chart_id == '1M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd',
      },
      // tickAmount: 5,
    };
  } else if (props.chart_id == '3M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd-MMM',
      },
      // tickAmount: 3,
    };
  } else if (props.chart_id == '6M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd-MMM',
      },
      // tickAmount: 6,
    };
  } else if (props.chart_id == '1Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yy',
      },
      // tickAmount: 5,
    };
  } else if (props.chart_id == '3Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yyyy',
      },
      // tickAmount: 3,
    };
  } else if (props.chart_id == '5Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yyyy',
      },
      // tickAmount: 5,
    };
  } else {
    xaxis = {};
  }

  // console.log(props.series);

  console.log(123, props.current_feed[props.series][props.chart_id]);

  const ApexOptions = {
    colors: ['#' + props.color],
    chart: {
      id: props.chart,
      type: 'area',
      height: '300px',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: [0.35, 1],
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    yaxis: {
      opposite: false,
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      // decimalsInFloat: 2,
    },
    xaxis: xaxis,
    legend: {
      width: 400,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      width: 2,
      curve: 'straight',
      // curve: ['smooth', 'straight', 'stepline'],
    },
    tooltip: tooltip,
    series: [
      {
        name: '',
        type: 'area',
        data: props.current_feed[props.series][props.chart_id],
        // data: value,
      },
    ],
  };

  /*var options = {
    colors: ['#' + color, '#' + color],
    series: [
      {
        type: 'area',
        name: chartName,
        data: value,
      },
      {
        type: 'line',
        name: 'Mean',
        data: mean,
      },
    ],
    fill: {
      type: 'solid',
      opacity: [0.35, 1],
    },

    chart: {
      id: id,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      // type: 'area',
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(1);
        },
      },
    },

    xaxis: {
      type: 'datetime',
      categories: category,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
    },
  };*/

  return (
    <>
      <ReactApexChart
        type="area"
        options={ApexOptions}
        series={ApexOptions.series}
        height="350"
        width="100%"
      />
    </>
  );
};

export default ValuationChartModal;
