import React, { forwardRef, useEffect, useState, useContext } from 'react';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import axios from 'axios';
// import Select from 'react-select';
// import { Select } from '@mantine/core';
import { Group, Avatar, Text, Select } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
const Search2 = ({ companyId, setCompanyId, setCompany }) => {
  // console.log(123, data);
  const [cookies, setCookies, removeCookies] = useCookies();
  // const allcompany = 1;
  const [allcompany, setAllcompany] = useState(1);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    const list = fetch(`${baseUrl}/companylistwithids`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var index = data.findIndex((p) => p.value == companyId);
        //console.log(index, data[index]);
        setCompany(data[index]);
        setCompanyList(data);
      });
  }, []);

  const handleSearchChange = (value) => {
    // console.log(123, companyList);
    if (value != null) {
      setCompanyId(value);
      var index = companyList.findIndex((p) => p.value == value);
      setCompany(companyList[index]);
    }
    // console.log(value, index, companyList[index]);
    // companyList(data[index]);
  };

  const SelectItem = forwardRef(({ image, label2, name, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} size="sm" />
        <div>
          <Text size="sm">{label2}</Text>
          <Text size="xs" opacity={0.65}>
            {name}
          </Text>
        </div>
      </Group>
    </div>
  ));

  // const [value, setValue] = useState([189]);
  // console.log(value);
  return (
    <div className="container container_box">
      <div
        className="row"
        // style={styles.companyname}
        style={styles.companyname}
      >
        <div className="col-sm-6 p-0 mb-5">
          <Select
            // label="Choose employee of the month"
            placeholder="Search..."
            itemComponent={SelectItem}
            data={companyList}
            defaultValue={companyId}
            // onSearchChange={handleSearchChange}
            onChange={handleSearchChange}
            searchable
            clearable
            limit={5}
            maxDropdownHeight={400}
            nothingFound="Not Found"
            filter={(value, item) =>
              item.label2.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.name.toLowerCase().includes(value.toLowerCase().trim())
            }
            // icon={<IconSearch size="1rem" />}
            rightSection={<IconSearch size="1rem" />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
        </div>
      </div>
    </div>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },

  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '147px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    // width: '92%',
    fontSize: '14px',
    // padding: '0 50px',
    margin: 'auto',
    marginBottom: '10px',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default Search2;
