import React, { useEffect, useState, createContext } from 'react';
import { getState } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import * as actions from './actions';
import Login from './layout/login/login';
import Signup from './components/Signup/signup';
import Alphaanalyst from './components/alphaanalyst';
// import ResearchReport from './components/ResearchReport';
import RecentDevelopment from './components/askanalyst/recentdevelopment';
import market2 from './components/market2';
import Databank from './components/databank';
// import Chemical from './components/databank/chemical';
import TimeLine from './components/askanalyst/timeline';
// import Sector from './components/Sector';
import Sectorchemcial from './components/Sector/chemical';
// import Sectorchemical from './components/Sector/tabs/chemical';
import Result from './components/askanalyst/result';
import ResearchReport from './components/askanalyst/researchreport';
import Balancesheet from './components/askanalyst/balancesheet';
import Incomestatement from './components/askanalyst/incomestatement';
import Ratio from './components/askanalyst/ratio';
import Cashflow from './components/askanalyst/cashflow';
import Valuation from './components/askanalyst/valuation';
import Technical from './components/askanalyst/technical';
import Overview from './components/askanalyst/overview';
import Chemical from './components/Sector/chemical';
import OMC from './components/Publisher/omc';
import Settlement from './components/market-old/settlement';
import Currency from './components/economy/currency';
// import Portfolio from './components/market/investment';
import market from './components/market';
import BOP from './components/Publisher/bop';
import Inflation from './components/economy/inflation';
import MB from './components/Publisher/morningbriefing';
import Energy from './components/Sector/energy';
// import investment from './components/economy/investment';
import LayoutComponent from './components/Layout';
import Reset from './components/ResetPassword/reset';
import Welcome from './layout/flowscreen/welcome';
import Expire from './layout/flowscreen/expire';
import ResetExpire from './layout/flowscreen/resetexpire';
// import Dashboard from './components/askanalyst/dashboard';
import Dashboard from './components/dashboard';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import Layout from './layout/Layout';

import Research from './components/market/researchreport';
import latestnews from './components/market/latestnews';
import Report from './components/market/reportlisting';

import Filling from './components/askanalyst/companyfilings';
import Finanicalstatement from './components/askanalyst/financialstatements';
import News from './components/askanalyst/news';
import Camparasion from './components/comparison/index';
import Portfolio from './components/Publisher/portfolio/index';

const Currentprice = createContext();

// var pathname = window.location.pathname;
// var parameter = pathname.split('/');

// console.log(parameter);
function App(props) {
  return (
    <>
      <ToastContainer
        draggable={false}
        transition={Bounce}
        autoClose={5000}
        position="bottom-right"
      />

      <Currentprice.Provider value={{ name: 'sulaiman', fname: 'jawaid' }}>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/welcome/:name?" component={Welcome} />
            <Route path="/expire" exact component={Expire} />
            <Route path="/reset_expire" exact component={ResetExpire} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/reset_password/:token?" component={Reset} />
            <Route path="/company" exact component={Overview} />

            <Route path="/company/result" exact component={Result} />
            <Route
              path="/company/balancesheet"
              exact
              component={Balancesheet}
            />
            <Route
              path="/company/incomestatement"
              exact
              component={Incomestatement}
            />
            <Route path="/company/ratio" exact component={Ratio} />
            <Route path="/company/cashflow" exact component={Cashflow} />
            <Route path="/company/valuation" exact component={Valuation} />
            <Route path="/company/technicals" exact component={Technical} />
            <Route path="/company/overview" exact component={Overview} />
            <Route
              path="/company/researchreport"
              exact
              component={ResearchReport}
            />
            <Route path="/company/timeline" exact component={TimeLine} />
            <Route path="/company/news" exact component={News} />
            <Route path="/dashboard" exact component={market} />
            <Route path="/market" exact component={market} />
            <Route path="/market/investment" exact component={Portfolio} />
            <Route path="/databank" exact component={Databank} />
            <Route path="/market2" exact component={market2} />
            <Route path="/publisher/morningbriefing" exact component={MB} />
            <Route
              path="/comparison/:module/:type/:symbol/:description_id/"
              exact
              component={Camparasion}
            />
            <Route path="/publisher/investment" exact component={Portfolio} />

            {/* <Route path="/sectorchemcial" exact component={Sectorchemcial} /> */}
            {/* <Route path="/researchreport" exact component={ResearchReport} /> */}
            <Route
              path="/company/recentdevelopment"
              exact
              component={RecentDevelopment}
            />
            <Route path="/financials" exact component={Alphaanalyst} />
            <Route path="/sector" exact component={Chemical} />
            <Route path="/sector/chemical" exact component={Chemical} />
            <Route path="/publisher/omc" exact component={OMC} />

            <Route path="/market" exact component={Settlement} />
            <Route path="/market/settlement" exact component={Settlement} />
            <Route path="/market/reportlisting" exact component={Report} />
            <Route path="/market/latestnews" exact component={latestnews} />
            <Route path="/market/researchreport" exact component={Research} />

            <Route path="/economy" exact component={Currency} />
            <Route path="/economy/currency" exact component={Currency} />
            <Route path="/economy/omc" exact component={OMC} />
            <Route path="/sector/energy/:sector" exact component={Energy} />
            {/* <Route path="/economy/investment" exact component={investment} /> */}
            <Route path="/publisher/bop" exact component={BOP} />
            <Route path="/economy/inflation" exact component={Inflation} />

            <Route path="/company/filings" exact component={Filling} />
            <Route
              path="/company/financialstatements"
              exact
              component={Finanicalstatement}
            />
          </Switch>
        </Router>
      </Currentprice.Provider>

      {/* <RatioTable /> */}
      {/* <RatioChart /> */}
    </>
  );
}

export default App;
export { Currentprice };
