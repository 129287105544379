import React from 'react'
import { Link } from 'react-router-dom'
import expiredImg from '../../assets/media/images/expiredScreen.svg'

const expired = () => {
  return (
     <section className='expired-Sec' style={styles.ExpiredSec} >
         <div className="container d-flex flex-column flex-wrap justify-content-center align-items-center" >
            <div>
                <img src={expiredImg} style={styles.ExpiredScreenImg} />
            </div>
            <div style={styles.ExpiredScreenContent} >
                <h4 style={styles.ScreenContentHeading} >Link has expired!</h4>
                <p style={styles.ScreenContentParagraph} >Ops! This verification link is not valid anymore.</p>
                <Link  to={"/signup"} replace className='btn btn-primary ' style={styles.ScreenContentBtn}>Go back to Sign Up</Link>
            </div>
         </div>
     </section>
  )
}

export default expired


const styles = {
    ExpiredSec:{
        height:"100vh",
        background :"#ffffff",
        padding : "0px 30px 30px",
        width : "100%"
    },
    ExpiredScreenImg:{
      maxHeight :"425px"
    },
    ExpiredScreenContent:{
        marginTop:"5%",
    },
    ScreenContentHeading:{
        fontWeight : "700",
        lineHeight: "38px",
        display: "flex",
        justifyContent: "space-around",
        color: "#3d3d3d" 
    },
    ScreenContentParagraph:{
       letterSpacing :"0.5px",
       display: "flex",
       justifyContent: "space-around",
       color: "#3d3d3d" 
    },
    ScreenContentBtn:{
        marginTop:"10%",
        flex:"center",
        justifyContent: "center",
        marginLeft : "25%",
        marginRight : "25%",
        background: "#3969d5"
    }
}