import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
// import axios from 'axios';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import ChartModal from './../../../layout/modals/ChartModal';
import CompanyFinancialTableModal from './../../../layout/modals/CompanyFinancialTableModal';
import ValuationTableModal from './../../../layout/modals/ValuationTableModal';

import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import loader from '../../../assets/media/_loader.gif';

const CompFinancials = ({ company }) => {
  let compId = company.id;
  let sectorId = company.sector_id;

  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [companyFinancials, setCompanyFinancials] = useState([]);

  const [valuationTableActive, setValuationTableActive] = useState(false);
  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [lable, setLable] = useState('');
  const [valuation, setValuation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [valuationSectorData, setValuationSectorData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/companyfinancial/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('i am here', data);
        setCompanyFinancials(data);
        setIsLoading(true);
      });
  }, [compId]);

  const handleChartModal = (company_name, lable, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLable(lable);
    setchartData(chartData);
    setChartActive(true);
  };

  const handleTableModal = (lable, name) => {
    // alert(lable);
    // setHeadingId(headingId);
    // setDescriptionId(descriptionId);
    setCompanyName(name);
    setLable(lable);
    setTableActive(true);
  };
  let withPercent = {
    DIVIDEND_YIELD: 'DIVIDEND YIELD',
    GROSS_MARGIN: 'GROSS MARGIN',
    EBIDTA_MARGIN: 'EBITDA MARGIN',
    OPERATING_MARGIN: 'OPERATING MARGIN',
    NET_MARGIN: 'NET MARGIN',
    ROE: 'ROE',
  };
  /* let valuation = [
    'Price/Earnings',
    'EV/EBITDA',
    'Price/BV',
    'EV/Sales',
    'Price/Sales',
  ]; */
  let valuation2 = {
    price_to_earning: 'Price/Earnings',
    ev_to_ebidta: 'EV/EBITDA',
    price_to_book_value: 'Price/BV',
    ev_to_sales: 'EV/Sales',
    price_to_sales: 'Price/Sales',
  };
  if (companyFinancials.length && isLoading) {
    return (
      <>
        <div className="comp-financials company-table">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Company Financials
                </th>
                <th></th>
                {companyFinancials[0].data.slice(-6).map((item, index) => {
                  return index != 5 ? (
                    <th key={'year' + index}>{item.year}</th>
                  ) : null;
                })}
                {/* <th>TTM</th> */}
              </tr>
            </thead>
            {companyFinancials ? (
              <tbody>
                {companyFinancials.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={2}>
                        {d.label}{' '}
                        <span className="table-unit-col">({d.unit})</span>
                      </td>
                      <td>
                        <span
                          style={styles.chart}
                          onClick={() => {
                            handleChartModal(company.name, d.label, d.data);
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: modal_chart_icon,
                            }}
                          />
                        </span>
                        {!Object.values(valuation2).includes(d.label) ? (
                          <span
                            style={styles.chart}
                            onClick={() => {
                              handleTableModal(d.label, d.label);
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modal_table_icon,
                              }}
                            />
                          </span>
                        ) : (
                          <span style={styles.chart}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modal_table_icon,
                              }}
                            />
                          </span>
                        )}
                      </td>
                      {d.data
                        .slice(-6)
                        .map((v, i) =>
                          i != 5 ? (
                            <td key={i}>
                              {v.value ? valueCheckGlobal(v.value) : '0'}
                            </td>
                          ) : null
                        )}
                      {/* <td>N/A</td> */}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              ''
            )}
          </table>
        </div>
        <ChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          lable={lable}
          chartData={chartData}
        />
        <CompanyFinancialTableModal
          tableActive={tableActive}
          setTableActive={setTableActive}
          setChartActive={setChartActive}
          sector={sectorData}
          lable={lable}
          setCompanyName={setCompanyName}
          setLable={setLable}
          setchartData={setchartData}
        />
        {Object.keys(valuationSectorData).length ? (
          <ValuationTableModal
            valuationTableActive={valuationTableActive}
            setValuationTableActive={setValuationTableActive}
            setChartActive={setChartActive}
            valuationSectorData={valuationSectorData}
            lable={lable}
            setCompanyName={setCompanyName}
            setLable={setLable}
            setchartData={setchartData}
          />
        ) : (
          ''
        )}
      </>
    );
  } else {
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    return (
      <div className="comp-financials">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={7} className="text-left">
                Company Financials
              </th>
            </tr>
          </thead>
          <tbody>
            {arr.map((v, i) => {
              return (
                <tr key={i}>
                  <td colSpan={7}>
                    <span style={{ color: 'white' }}>.</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={styles.noContent} className="no-content-load text-center">
          <img style={styles.noContentImage} src={loader} alt="" />
        </div>
      </div>
    );
  }
};

export default CompFinancials;
const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(50% - 90px))',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    // justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};
