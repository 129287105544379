import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import { ColorPicker } from 'antd';
import ApexCharts from 'apexcharts';
import color_picker from '../../assets/media/icons/color_picker.svg';
import { modal_close_icon, modal_excel_icon } from '../../api/baseUrl';
import { valueCheckGlobal } from '../../GlobalFunctions';
import eye from '../../assets/media/icons/eye.svg';
const EnergyChartModal = ({ chartActive, setChartActive, chartData, head }) => {
  const theme = useTheme();

  const years = head.slice(-10).map((d) => d['year']);
  const value = head.slice(-10).map((d) => chartData[d['key']]);

  const [openColorPicker, setOpenColorPicker] = useState(false);

  // console.log(chartData.label);
  const ApexOptions = {
    chart: {
      id: 'energy-year-book',
      foreColor: theme.palette.primary.main,
      type: 'bar',
      toolbar: {
        tools: {
          // download: '<img src="' + eye + '" width="50" height="20" />',
          customIcons: [
            {
              icon:
                '<img src="' +
                color_picker +
                '" class="ico-download" width="20"> ',
              index: 0,
              title: '',
              class: 'custom-icon',
              click: () => {
                setOpenColorPicker((openColorPicker) => !openColorPicker);
                console.log(openColorPicker);
              },
            },
          ],
        },
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      decimalsInFloat: 2,
    },
    xaxis: {
      // categories: [
      //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
      // ],
      categories: years,
    },
    legend: {
      width: 400,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      show: false,
      // curve: 'straight',
      // curve: ['smooth', 'straight', 'stepline'],
    },
    series: [
      {
        name: '',
        type: 'bar',
        // data: [23, 42, 35, 27, 43, 22, 17, 31, 42, 22, 12, 16],
        data: value,
      },
    ],
  };

  const handleSeriesColor = (e) => {
    var color = {
      fill: {
        colors: e.toHexString(),
      },
    };
    // setOpenColorPicker(false);
    ApexCharts.exec('energy-year-book', 'updateOptions', color);
  };

  return (
    <>
      <Modal
        style={styles.modalBackdrop}
        className="table-modal session-modal"
        show={chartActive ? true : false}
        centered
      >
        <Modal.Header style={styles.modalHeader}>
          <Modal.Title
            style={styles.modalTitle}
            className="text-left d-inline-block w-100"
          >
            {chartData.label !== undefined ? (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: chartData.label,
                  }}
                />
              </span>
            ) : (
              ''
            )}
            <span
              style={styles.close}
              onClick={() => {
                setChartActive(false);
                setOpenColorPicker(false);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: modal_close_icon }} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody} className="p-0">
          <ColorPicker
            size="small"
            open={openColorPicker}
            onChange={(e) => {
              handleSeriesColor(e);
            }}
            presets={[
              {
                label: 'Recommended',
                colors: [
                  '#002060',
                  '#7F7F7F',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                ],
              },
            ]}
          >
            <div>&nbsp;</div>
          </ColorPicker>
          <ReactApexChart options={ApexOptions} series={ApexOptions.series} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnergyChartModal;

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 25px',
    // background: "#f0f7ff",
    letterSpacing: '0.3px',
    fontWeight: '700',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3969D5',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 0px',
  },
  close: {
    cursor: 'pointer',
    float: 'right',
    color: '#3d3d3d',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
};
