import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatMoney } from '../../GlobalFunctions';
const Chart = (props) => {
  //console.log(12345, props.series);
  //console.log(12345, props.dates);
  //props.series.map((value, index) => {
  //  console.log(12345, value);
  //});

  var labelFormatter = function (value) {
    var val = Math.abs(value);
    if (val >= 1000000) {
      // val = val().toLocaleString();
      val = (val / 1000000).toFixed(1) + ' M';
    } else {
      val = val.toFixed(0);
    }
    // val = val.toLocaleString();
    return formatMoney(val);
  };

  /* title: {
    text: false,
    //text: '1 Kg',
    rotate: 0,
    offsetX: 30,
    offsetY: -160,
    style: {
      // color: undefined,
      fontSize: '12px',
      fontFamily: 'PKR',
      fontWeight: 600,
      cssClass: 'apexcharts-xaxis-title',
    },
  }, */

  const yaxis = props.axis.map((y) => ({
    // min: 0,
    // max: y.max,
    // tickAmount: 4,
    opposite: y.opposite,
    logarithmic: false,
    // floating: true,

    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
      // color: '#FF1654',
    },
    labels: {
      // rotate: 270,
      style: {
        // colors: '#FF1654',
      },
    },
    tooltip: {
      enabled: true,
      // formatter: undefined,
      // offsetY: 0,
    },
    labels: {
      formatter: labelFormatter,
    },
  }));

  const dataLabel2 = props.series.map((value, index) => ({
    enabled: false,
    // position: 'top',
    // textAnchor: 'top',
    offsetX: 0,
    offsetY: index % 2 == 0 ? 10 : -10,
    hideOverflowingLabels: true,
    formatter: labelFormatter,
    background: {
      enabled: false,
    },
  }));

  //console.log(dataLabel2);
  //console.log(1234, yaxis);

  const ApexOptions = {
    colors: props.colors,
    chart: {
      id: 'graphID',
      height: 200,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      enabled: true,
      text: 'Source: Ask Analyst',
      align: 'right',
      margin: 0,
      offsetX: 0,
      offsetY: 422.5,
      floating: false,
      style: {
        fontStyle: 'italic',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: '#263238',
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 20,
        bottom: -15,
      },
    },
    dataLabels: {
      enabled: true,
      // position: 'top',
      // textAnchor: 'middle',
      offsetX: 0,
      offsetY: -10,
      hideOverflowingLabels: true,
      formatter: labelFormatter,
      background: {
        enabled: false,
      },
    },
    series: props.series,
    stroke: {
      // show: true,
      curve: 'smooth',
      width: props.strock,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      tickAmount: 14,
      tickPlacement: 'between',
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
      },
      axisTicks: {
        show: false,
      },
      categories: props.dates,
    },
    yaxis: yaxis,
    tooltip: {
      enabled: true,
    },
    legend: {
      showForSingleSeries: true,
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 4,
        vertical: 25,
      },
      // offsetX: 40,
    },
    export: {
      csv: {
        filename: '',
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      },
    },

    fill: {
      type: 'solid',
    },
  };

  return (
    <>
      <ReactApexChart
        options={ApexOptions}
        series={props.series}
        type={props.type}
        // height="350"
        width="100%"
      />
    </>
  );
};

export default Chart;
