import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal.css';
import { valueCheckGlobal } from '../../GlobalFunctions';
import {
  modal_close_icon,
  modal_excel_icon,
  modal_chart_icon,
} from '../../api/baseUrl';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const CashFlowTableModal = ({
  tableActive,
  setTableActive,
  setChartActive,
  sector,
  lable,
  rationame,
  setCompanyName,
  setLable,
  setchartData,
}) => {
  // const

  const handleChartModal = (company_name, lable, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLable(lable);
    setchartData(chartData);
    setChartActive(true);
  };

  // if (sector.company != undefined) {
  // console.log(rationame, sector.company);
  // }
  if (sector.company != undefined && tableActive) {
    return (
      <>
        <Modal
          style={styles.modalBackdrop}
          className="table-modal session-modal ratio-table-modal"
          show={tableActive ? true : false}
          centered
        >
          <Modal.Header style={styles.modalHeader}>
            <Modal.Title
              style={styles.modalTitle}
              className="text-left d-inline-block w-100"
            >
              {sector.sector}
              <span
                style={styles.close}
                onClick={() => {
                  setTableActive(false);
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: modal_close_icon }} />
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={styles.modalBody}>
            <div>
              {lable}{' '}
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  table="cashflow"
                  filename={lable}
                  sheet="file"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{ __html: modal_excel_icon }}
                    />
                  }
                />{' '}
              </span>
            </div>
            <div
              style={styles.sessionAlertContent}
              className="session-alert-content"
            >
              <div className="cashflow-main">
                <table className="table table-responsive" id="cashflow">
                  <thead>
                    <tr>
                      <th colSpan={2}>Company </th>
                      {sector.company[0].cashflow[0].data
                        .slice(-5)
                        .map((value, index) => {
                          return <th key={index}>{value.year}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {sector.company.map((value, index) => {
                      const cashflow_index = value.cashflow.filter(
                        (p) => p.label == lable
                      );
                      console.log(123, value);
                      return (
                        <tr key={index}>
                          <td colSpan={2}>
                            <span
                              style={styles.chart}
                              onClick={() => {
                                handleChartModal(
                                  value.name,
                                  lable,
                                  cashflow_index[0].data
                                );
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: modal_chart_icon,
                                }}
                              />
                            </span>
                            {value.name}
                          </td>
                          {cashflow_index[0].data
                            .slice(-5)
                            .map((value, index) => {
                              return <td>{valueCheckGlobal(value.value)}</td>;
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <button style={styles.buttonOK}>Ok</button> */}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return '';
  }
};

export default CashFlowTableModal;

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 25px',
    letterSpacing: '0.3px',
    fontWeight: '700',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3969D5',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 50px',
  },
  close: {
    color: '#3d3d3d',
    cursor: 'pointer',
    float: 'right',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
  ratio_table_modal: {
    maxWidth: '700px',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
};
