import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { addDigit, formatMoney } from '../../../GlobalFunctions';
const Chart = (props) => {
  // console.log(props.color);
  var category = props.chart.label;
  var data = props.chart.data;
  var color = '#' + props.color;

  const fontFamily = 'Helvetica, sans-serif';
  const fontWeight = 400;
  const fontSize = '12px';

  const options = {
    series: [
      {
        // data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        data: data,
      },
    ],
    chart: {
      id: props.title,
      type: 'bar',
      fontFamily: 'Helvetica, sans-serif !important',
    },
    fill: {
      colors: color,
    },

    title: {
      text: props.title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#000',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        distributed: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
          style: {
            colors: ['#000'],
          },
        },
        // dumbbellColors: '#' + color,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000'],
        },
        // offsetX: 0,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        colors: ['#000'],
        fontSize: 9.5,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        cssClass: 'apexcharts-yaxis-label',
      },
      formatter: (value) => {
        return (Math.round(value * 100) / 100).toFixed(1) + '%';
      },
      // offsetX: 5,
      offsetY: -30,
      dropShadow: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#D8D8D8',
      strokeDashArray: 7,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        // top: 20,
        // bottom: -15,
      },
    },
    //
    stroke: {
      // show: true,
      curve: 'smooth',
      width: 0,
    },
    yaxis: {
      tickAmount: 5,
      // min: 0,
      // min: min + min / net.length,
      // max: max + max / net.length,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 50,

        style: {
          colors: ['#000000'],
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          cssClass: 'apexcharts-yaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return (Math.round(value * 100) / 100).toFixed(1) + '%';
        },
      },
      title: {
        // text: false,
        text: 'USD mn',
        rotate: 0,
        offsetX: 30,
        offsetY: -210,
        style: {
          // color: undefined,
          fontSize: '12px',
          fontFamily: fontFamily,
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
        style: {
          colors: ['#000000'],
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          cssClass: 'apexcharts-yaxis-label',
        },
        formatter: (value) => {
          return value;
        },
      },

      axisBorder: {
        show: false,
        color: '#78909C',
        height: 0,
        width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },

      // categories: [2010, 2020, 2030, 2040, 2050, 2060, 2070, 2080, 2090, 3000],
      categories: category,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#D8D8D8',
          borderWidth: 1,
          opacity: 1,
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={options.series}
        type={'bar'}
        height="300"
        width="100%"
      />
    </>
  );
};

export default Chart;
