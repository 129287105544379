import { withReduxStateSync } from 'redux-state-sync';

function reducer(state = {} , action){
    if(action.type === "sectorSearch"){
        return {
            ...state , 
            sectorname : action.payload.name
        }
    }
    else if(action.type === "currentUser"){
        return {
            ...state,
            userdata:{
                user : action.payload.email,
                password: action.payload.password,
                token : action.payload.token
            }
        }
    } else {
        return state;
    }
}

export default withReduxStateSync(reducer)