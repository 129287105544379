import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import {
  baseUrl,
  comingsoon,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';

import './nav.css';

function Nav({ companyId, nopadding }) {
  // console.log(123, companyId);
  let history = useHistory();
  const location = useLocation();

  const nav = [
    {
      path: 'company/overview',
      label: 'Overview',
      status: 'active',
    },
    {
      path: 'company/result',
      label: 'Latest Result',
      status: 'active',
    },
    {
      path: 'company/news',
      label: 'News',
      status: 'active',
    },
    {
      path: 'company/researchreport',
      label: 'Research Reports',
      status: 'active',
    },
    {
      path: 'company/technicals',
      label: 'Technicals',
      status: 'active',
    },
    {
      path: 'company/valuation',
      label: 'Valuation',
      status: 'active',
    },
    {
      path: 'company/incomestatement',
      label: 'Income Statement',
      status: 'active',
    },
    {
      path: 'company/balancesheet',
      label: 'Balance Sheet',
      status: 'active',
    },
    {
      path: 'company/cashflow',
      label: 'Cash Flow',
      status: 'active',
    },
    {
      path: 'company/ratio',
      label: 'Ratios',
      status: 'active',
    },
    {
      path: 'company/filings',
      label: 'Company Filings',
      status: 'active',
    },
    {
      path: 'company/financialstatements',
      label: 'Financial Statements',
      status: 'active',
    },
    {
      path: 'company/recentdevelopment',
      label: 'Recent Developments',
      status: 'inactive',
    },
    {
      path: 'company/timeline',
      label: 'History',
      status: 'inactive',
    },
  ];

  const navChange = (id, path) => {
    history.push({
      pathname: '/' + path,
      state: {
        id: id,
      },
    });
  };

  return (
    <div
      className={
        nopadding
          ? ' dashboard-main container container_box'
          : 'dashboard-main container container_box'
      }
    >
      <div className="row ml-0">
        <ul className="navbar-nav second-nav mr-auto">
          {nav.map((value, item) => {
            const btn_status = value.status === 'inactive';
            return (
              <li
                className={
                  location.pathname === '/' + value.path
                    ? 'nav-item active'
                    : 'nav-item'
                }
                key={item}
              >
                {btn_status ? (
                  <Tooltip
                    position="bottom"
                    label={
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: comingsoon }} />
                        <p>Coming Soon</p>
                      </div>
                    }
                    order={0}
                  >
                    <span>
                      <button
                        disabled={btn_status}
                        type="button"
                        role="tab"
                        className="disabled-btn"
                        onClick={() => {
                          navChange(companyId, value.path);
                        }}
                      >
                        {value.label}
                      </button>
                    </span>
                  </Tooltip>
                ) : (
                  <button
                    disabled={btn_status}
                    type="button"
                    role="tab"
                    onClick={() => {
                      navChange(companyId, value.path);
                    }}
                  >
                    {value.label}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Nav;
