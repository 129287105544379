import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import {
  valueCheckGlobal,
  getLoader,
  formatDate,
  LocalStorageURL,
} from '../../../GlobalFunctions';
import { Popover, Button, Autocomplete, Checkbox } from '@mantine/core';
import { Select } from '@mantine/core';
import './filling.css';
// import Valuation from './../../valuationData';
import {
  baseUrl,
  pdf,
  modal_chart_icon,
  downicon,
  modal_table_icon,
} from '../../../api/baseUrl';

import ChartModal from '../../../layout/modals/ChartModal';
import CashFlowTableModal from '../../../layout/modals/CashFlowTableModal';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import Paginate from 'react-paginate';
const Filing = (props) => {
  const location = useLocation();

  const [company, setCompany] = useState({});
  let compId = location.state != undefined ? location.state.id : 173;
  let symbol = company.symbol != undefined ? company.symbol : 'LUCK';
  const [companyId, setCompanyId] = useState(compId);
  const [filings, setFilings] = useState([]);
  const [check, setCheck] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    fetch(`${baseUrl}/filings/${companyId}/1000`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFilings(data);
        setCheck(true);
      });
  }, [companyId]);
  // console.log(symbol, filings);
  /////////////////////Pagination/////////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filings.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const handlePostsPerPage = (e) => {
    setCurrentPage(1);
    setPostsPerPage(e.target.value);
  };
  ////////////////////End Pagination///////////////////////////

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />

      <div className="filling_section">
        <div className="container container_box">
          <div className="filing_table filing">
            <table>
              <thead>
                <tr>
                  <th>
                    <span className="filling-filter">Company</span>
                  </th>
                  <th className="filling_second">
                    <span className="filling-filter justify-content-center">
                      Symbol
                    </span>
                  </th>
                  {/*                   <th>
                    <span className="filling-filter justify-content-center">
                      Sector
                    </span>
                  </th> */}
                  <th>
                    <span className="filling-filter justify-content-center">
                      Date
                    </span>
                  </th>
                  <th>Description</th>
                  {/* <th className="filling_second">
                    <span className="filling-filter justify-content-center">
                      <Popover
                        width={240}
                        position="bottom"
                        withArrow
                        shadow="md"
                      >
                        <Popover.Target>
                          <Button>
                            <div
                              dangerouslySetInnerHTML={{ __html: downicon }}
                            />
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Select
                            className="drop_selected"
                            label="Your favorite library"
                            placeholder="Pick value"
                            searchable
                            data={[
                              'Financial Results',
                              'Material Information',
                              'Pre Result Update',
                              'Company Update',
                            ]}
                          />
                          <div className="filters_button">
                            <button className="Reset-btn">Reset</button>
                            <button className="Apply-btn">Apply</button>
                          </div>
                        </Popover.Dropdown>
                      </Popover>
                      Type
                    </span>
                  </th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.length ? (
                  currentPosts.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.company_name}</td>
                        <td>{item.company_symbol}</td>
                        {/* <td>{item.company_sector}</td> */}
                        <td>{formatDate(item.date)}</td>
                        <td>{item.description.slice(0, 120)}...</td>

                        {/* <td>
                          <span className={'type-color'}>{item.type}</span>
                        </td> */}
                        <td>
                          <Link
                            to={{
                              pathname: `${LocalStorageURL}/filing/${item.pdf}.pdf`,
                            }}
                            target="_blank"
                            download
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: pdf,
                              }}
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {check ? 'No Record Found' : 'Loading...'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {currentPosts.length ? (
              <div className="dropdown-pagination">
                <div className="select_drop">
                  <select
                    className="selectpicker"
                    value={postsPerPage}
                    onChange={handlePostsPerPage}
                  >
                    <option data-tokens="25">25</option>
                    <option data-tokens="50">50</option>
                    <option data-tokens="100">100</option>
                    {/* <option data-tokens="All">All</option> */}
                  </select>
                </div>
                <Paginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(filings.length / postsPerPage)}
                  previousLabel={'Prev'}
                  nextLabel={'Next'}
                  containerClassName={'pagination'}
                  pageLinkClassName={'page-number'}
                  previousLinkClassName={'page-number'}
                  nextLinkClassName={'page-number'}
                  activeLinkClassName={'active'}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filing;
