import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FinanceIncome from './financeIncome';
import FinanceBalance from './financialBalance';
import FinanceRatio from './financialRatio';
import ChartSlider from './ChartSlider';
import ChartSection from './ChartSection';
import { baseUrl } from '../../../api/baseUrl';

const FinancialCharts = (props) => {
  let comp_id = props.company.id;

  const [charts, setCharts] = useState([]);

  useEffect(() => {
    axios
      .post(`${baseUrl}/financialchart`, {
        company_id: comp_id,
      })
      .then(function (response) {
        // console.log('chart data', response.data);
        setCharts(response.data);
      })
      .catch(function (error) {
        console.log('ERROR IN FINANCIAL CHART API RESPONSE====>', error);
      });
  }, [props.company.id]);
  return (
    <>
      <div className="financial-chart col-12 p-0">
        <div className="col-12 data-block p-0">
          {charts.map((section, index) => {
            return (
              <ChartSection
                section={section}
                company={props.company}
                key={index}
                key2={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default FinancialCharts;
