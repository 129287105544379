import React, { useEffect, useState, useContext } from 'react';
import './result.css';
import {
  minusValueCheckGlobal,
  valueCheckGlobal,
  minusValueCheckGlobalDPS,
} from '../../../GlobalFunctions';
// import { valueCheckGlobal } from '../../../GlobalFunctions';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import arrowicon from '../../../assets/media/images/arrow-icon.svg';
import arrowdown from '../../../assets/media/images/arrow-down.svg';
import LoadingImage from '../../../assets/media/loader.gif';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.png';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import Search2 from '../search2';
import Nav from '../nav';

import ChartModal from '../../../layout/modals/ChartModal';
import IncomeStatementTableModal from '../../../layout/modals/IncomeStatementTableModal';
import Switch from 'react-input-switch';
import Header from '../../../common/header/header';
import Select from 'react-select';
import Loader from '../../../assets/media/_loader.gif';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';

const Result = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [currentPrice, setCurrentPrice] = useState({});
  const [result, setResult] = useState([]);
  const [is, setIs] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [sectorData, setSectorData] = useState([]);
  const [value, setValue] = useState('analysis');
  // const [symbol, setSymbol] = useState('LUCK');
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  // const [selected, setSelected] = useState({ value: 189, label: 'LUCK' });

  // const selectedcompany = { value: 189, label: 'LUCK' };

  console.log(companyId, company);
  useEffect(() => {
    // setIsLoading(false);
    //companyId == null ? location.state.id : 189;
    if (null != companyId && company !== undefined) {
      fetch(`${baseUrl}/incomestatement/${companyId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setIs(data);
          setResult(data['analysis']);
          setIsLoading(true);
          // generateChart(data);
        });
    }
  }, [companyId]);

  useEffect(() => {
    if (null != companyId && company !== undefined) {
      setIsLoading2(false);
      fetch(`${baseUrl}/sharepricedata3/${companyId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCurrentPrice(data);
          setIsLoading2(true);
          // setIsLoading(true);
          // generateChart(data);
        });
    }
  }, [companyId]);

  /* var handleCompanyChange = (companySelectedOption) => {
    setCompanyId(companySelectedOption.value);
    setSymbol(companySelectedOption.label);
    // setCompanySelectedOption(companySelectedOption.value);
  }; */

  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    // 'EPS - Diluted',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  // console.log(companyId, currentPrice);

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [lable, setLable] = useState('');
  const [analysis, setAnalysis] = useState('off');
  const [chartData, setchartData] = useState([]);
  const [headingId, setHeadingId] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [slice, setSlice] = useState(0, 11);
  const [colspan, setColspan] = useState(14);
  // const [bs, setBs] = useState([]);

  const handleSwitch = (value) => {
    setResult(is[value]);
    setValue(value);
    setAnalysis('off');
  };

  const handleAnalysis = (analysis) => {
    setAnalysis(analysis);

    analysis == 'on' ? setColspan(15) : setSlice(14);
    analysis == 'on' ? setSlice(-12) : setSlice(-11);
    analysis == 'on' ? setResult(is['analysis']) : setResult(is[value]);
  };
  // console.log(123);
  return (
    <>
      <Header />
      <div className="result-main analyst">
        <div
          className="row resultboxed latest-boxed"
          style={styles.companyname}
        >
          <Search2
            companyId={companyId}
            setCompanyId={setCompanyId}
            setCompany={setCompany}
          />
          <Nav companyId={companyId} nopadding={true} />
        </div>
      </div>

      {result.length && isLoading ? (
        <div className="result-main latest">
          <div
            className="latest-boxed1 container container_box"
            style={styles.companyname}
          >
            {company && isLoading2 ? (
              <>
                <div className="col-sm-3 p-0 title result_lucky">
                  <p className="lucky_name mb-0">{company.symbol}</p>
                  <p className="amount_symbol mb-0">{company.name}</p>
                  <p className="amount_symbol mb-0">
                    Amount in PKR Mn; per share in PKR
                  </p>
                </div>

                <div className="col-sm-3 p-0 return result-sizing">
                  <div className="table_align">
                    <span className="currentprice">Current Price </span>
                  </div>
                  {currentPrice && currentPrice.current ? (
                    <>
                      {currentPrice.direction == '+' ? (
                        <div className="table_align">
                          {/* <span className="currentprice">Current Price</span> */}

                          <p className="pkrnum mt-0">
                            PKR {currentPrice.current}&nbsp;
                            <img src={arrowicon} alt="" />
                            <span className="countsymbol text-green">
                              {currentPrice.change}&nbsp;(
                              {currentPrice.change_in_percentage} %)
                            </span>
                          </p>
                          <p className="date mb-0">{currentPrice.date}</p>
                        </div>
                      ) : (
                        <div>
                          {/* <span className="currentprice">Current Price</span> */}

                          <p className="pkrnum mt-0">
                            PKR {currentPrice.current}&nbsp;
                            <img src={arrowdown} />
                            <span className="countsymbol text-red">
                              {/* {currentPrice.direction} */}
                              {currentPrice.change}&nbsp;(
                              {currentPrice.change_in_percentage} %)
                            </span>
                          </p>
                          <p className="date mb-0">{currentPrice.date}</p>
                        </div>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
                {currentPrice && currentPrice.total_return ? (
                  <div className="col-sm-4 m-auto p-0 return">
                    <table className="table table-responsive">
                      <tr>
                        <td>
                          1M Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['1M'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['1M']}%
                          </span>
                        </td>
                        <td>
                          3M Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['3M'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['3M']}%
                          </span>
                        </td>
                        <td>
                          6M Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['6M'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['6M']}%
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          1Y Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['1Y'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['1Y']}%
                          </span>
                        </td>
                        <td>
                          3Y Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['3Y'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['3Y']}%
                          </span>
                        </td>
                        <td>
                          5Y Return:{' '}
                          <span
                            className={
                              currentPrice.total_return['5Y'] < 0
                                ? 'text-danger text-red'
                                : 'countsymbol text-green'
                            }
                          >
                            {currentPrice.total_return['5Y']}%
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  ''
                )}

                <div
                  className="col-sm-2 logo latest-image"
                  style={styles.companylogoimage}
                >
                  <img src={companylogo} style={styles.companylogo} />
                </div>
              </>
            ) : (
              <div
                style={styles.noContent}
                className="no-content-load text-center"
              >
                <img style={styles.noContentImage} src={Loader} alt="" />
              </div>
            )}
          </div>
          <div class="dashboard-main1 container container_box latest_analyst">
            <div className="latest-result-table">
              <table
                className="table table-responsive"
                // style={{ width: '80%' }}
              >
                <thead>
                  <tr>
                    <th colSpan={2}>P&L Summary</th>
                    {result[0].data
                      .slice(-11)
                      .map((y, i) =>
                        i !== 11 ? <th key={i}>{y.year}</th> : null
                      )}
                  </tr>
                </thead>
                <tbody>
                  {result.map((r, i) => {
                    if (headingCheck(r.label)) {
                      return (
                        <tr key={i}>
                          <td style={{ fontWeight: '800' }} colSpan={2} key={i}>
                            {r.label}
                          </td>
                          {r.data.slice(-11).map((d, i) => {
                            if (r.label == 'DPS') {
                              var val = minusValueCheckGlobalDPS(d.value);
                            } else {
                              var val = minusValueCheckGlobal(d.value);
                            }
                            return i !== 11 ? (
                              <td style={{ fontWeight: '800' }} key={i}>
                                {val}
                              </td>
                            ) : null;
                          })}
                        </tr>
                      );
                    }
                    return (
                      <tr key={i}>
                        <td colSpan={2}> {r.label}</td>
                        {r.data.slice(-11).map((d, i) => {
                          return i !== 11 ? (
                            <td key={i}>{minusValueCheckGlobal(d.value)}</td>
                          ) : null;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '180px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    width: '80%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default Result;
