import React, { useEffect, useState, useContext } from 'react';
import './inflation.css';
import Nav from '../nav';
import Header from '../../../common/header/header';
import Chart from './charts';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import LoadingImage from '../../../assets/media/loader.gif';
import SlickNextArrow from '../../../common/slicknext';
import SlickPrevArrow from '../../../common/slickprev';
import Slider from 'react-slick';
const Inflation = (props) => {
  const [chart, setChart] = useState([]);
  const [message, setMessage] = useState([]);
  const [header, setHeader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();

  useEffect(() => {
    setIsLoading(false);

    fetch(`${baseUrl}/inflationchange`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setChart(data.chart);
        setMessage(data.message);
        setHeader(data.head);
        // console.log(data.head);
        setIsLoading(true);
      });
  }, []);

  const settings = {
    dots: false,
    arrow: Object.keys(chart).length <= 6 ? false : true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ChartColors = [
    '0B254A',
    '3969D5',
    'B7CD5E',
    '717375',
    'D2E5FF',
    '0B254A',
    '3969D5',
    'B7CD5E',
    '717375',
    'D2E5FF',
  ];

  return (
    <>
      <Header />
      <div className="row inflationboxed" style={styles.navWidth}>
        <Nav nopadding={true} />
      </div>
      {isLoading && message.length ? (
        <div className="inflation_section">
          <div className="container container_box">
            <div className="main_inflation">
              <div className="inflation_table">
                <table>
                  <tr>
                    <th></th>
                    {isLoading &&
                      header &&
                      header.map((value, key) => {
                        return <th>{value}</th>;
                      })}
                  </tr>
                  {isLoading &&
                    message &&
                    message.map((value, key) => {
                      return (
                        <tr>
                          <td>{value.label}</td>
                          {value.data.map((value, key) => {
                            return <td>{value['value']}</td>;
                          })}
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
            {/*<div className="col-md-7 inflation-charts">
                <div className="chart_box inflation_boxed">
                  <Chart
                    chart={chart['6MA']}
                    title={
                      'Trailing ' +
                      header[0].replace('MA', 'M') +
                      ' Avg MoM run rate'
                    }
                  />
                </div>
                <div className="chart_box inflation_boxed">
                  <Chart
                    chart={chart['12MA']}
                    title={
                      'Trailing ' +
                      header[1].replace('MA', 'M') +
                      ' Avg MoM run rate'
                    }
                  />
                </div>
              </div>*/}
            {isLoading ? (
              <>
                {/*<div className="inflation_chart">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="chart_box">
                        <Chart
                          chart={chart['24MA']}
                          title={
                            'Trailing ' +
                            header[2].replace('MA', 'M') +
                            ' Avg MoM run rate'
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="chart_box">
                        <Chart
                          chart={chart['36MA']}
                          title={
                            'Trailing ' +
                            header[3].replace('MA', 'M') +
                            ' Avg MoM run rate'
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="chart_box">
                        <Chart
                          chart={chart['48MA']}
                          title={
                            'Trailing ' +
                            header[4].replace('MA', 'M') +
                            ' Avg MoM run rate'
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="chart_box">
                        <Chart
                          chart={chart['5MA']}
                          title={
                            'Trailing ' +
                            header[5].replace(' (%YoY)', '') +
                            ' Avg YoY run rate'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>*/}
              </>
            ) : (
              ''
            )}

            <Slider {...settings}>
              <Chart
                chart={chart['6MA']}
                color={ChartColors[0]}
                title={
                  'Trailing ' +
                  header[0].replace('MA', 'M') +
                  ' Avg MoM run rate'
                }
              />
              <Chart
                chart={chart['12MA']}
                color={ChartColors[1]}
                title={
                  'Trailing ' +
                  header[1].replace('MA', 'M') +
                  ' Avg MoM run rate'
                }
              />
              <Chart
                chart={chart['24MA']}
                color={ChartColors[2]}
                title={
                  'Trailing ' +
                  header[2].replace('MA', 'M') +
                  ' Avg MoM run rate'
                }
              />
              <Chart
                chart={chart['36MA']}
                color={ChartColors[3]}
                title={
                  'Trailing ' +
                  header[3].replace('MA', 'M') +
                  ' Avg MoM run rate'
                }
              />
              <Chart
                chart={chart['48MA']}
                color={ChartColors[4]}
                title={
                  'Trailing ' +
                  header[4].replace('MA', 'M') +
                  ' Avg MoM run rate'
                }
              />
              <Chart
                chart={chart['5MA']}
                color={ChartColors[5]}
                title={
                  'Trailing ' +
                  header[5].replace(' (%YoY)', '') +
                  ' Avg YoY run rate'
                }
              />
            </Slider>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{ width: '100%', height: '100%', color: '#426fd7' }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: '500px' }} />
          </div>
        </>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '180px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
};
export default Inflation;
