import React, { useEffect, useState, useContext } from "react";
import "./report.css";
import Header from "../../../common/header/header";
import Luckylatest from "../../../../src/assets/media/images/latest1img.svg";
import luckyicon from "../../../../src/assets/media/images/luck-icon.svg";
import AELLucky from "../../../../src/assets/media/images/latest2img.svg";
import AELicon from "../../../../src/assets/media/images/ael_icon.svg";
import UblLucky from "../../../../src/assets/media/images/latest3img.svg";
import alnrsicon from "../../../../src/assets/media/images/alnrs_icon.svg";
import cementicon from "../../../../src/assets/media/images/cement_icon.svg";
import luckbox from "../../../../src/assets/media/images/luckbox1.svg";
import ublboxed from "../../../../src/assets/media/images/ubl_boxed.svg";
import sapticon from "../../../../src/assets/media/images/sapt_icon.svg";
import federalboxed from "../../../../src/assets/media/images/federal_boxed.svg";
import bglicon from "../../../../src/assets/media/images/bgl_icon.svg";
import srccement from "../../../../src/assets/media/images/src_cement.svg";
import srcicon from "../../../../src/assets/media/images/src_icon.svg";
import cementlucky from "../../../../src/assets/media/images/cement_lucky.svg";
import dfsmicon from "../../../../src/assets/media/images/dfsm_icon.svg";
import luckystrucure from "../../../../src/assets/media/images/lucky_strucure.svg";
import habsmicon from "../../../../src/assets/media/images/habsm_icon.svg";
import worklucky from "../../../../src/assets/media/images/work_lucky.svg";
import construction from "../../../../src/assets/media/images/construction.svg";
import constructionlucky from "../../../../src/assets/media/images/construction1.svg";
import { Link } from "react-router-dom";
import { Tabs } from "@mantine/core";
import { SegmentedControl } from '@mantine/core';


const reportlisting = () => {
  return (
    <>
      <Header />

      <div className="main_rearch" id="main_research">
        <div className="container container_box">
          <div className="latest_heading">
            <h1>Research Reports</h1>
          </div>
          <div className="researchtogglesec">
            <Tabs defaultValue="All">
              <div className="listing_tabs">
              <Tabs.List>
                <Tabs.Tab value="All">All</Tabs.Tab>
                <Tabs.Tab value="Autos">Autos</Tabs.Tab>
                <Tabs.Tab value="Banks">Banks</Tabs.Tab>
                <Tabs.Tab value="Chemicals">Chemicals</Tabs.Tab>
                <Tabs.Tab value="E&P">E&P</Tabs.Tab>
                <Tabs.Tab value="Economy">Economy</Tabs.Tab>
                <Tabs.Tab value="Fertilizer">Fertilizer</Tabs.Tab>
                <Tabs.Tab value="IPPs">IPPs</Tabs.Tab>
                <Tabs.Tab value="IT">IT</Tabs.Tab>
                <Tabs.Tab value="Logistics">Logistics</Tabs.Tab>
                <Tabs.Tab value="Oil&Gas">Oil & Gas</Tabs.Tab>
                <Tabs.Tab value="OMCs">OMCs</Tabs.Tab>
                <Tabs.Tab value="Steel">Steel</Tabs.Tab>
              </Tabs.List>
              <div className="segment_report">
                <SegmentedControl data={['Blog View', 'Listing View']} />
              </div>
              </div>
              <Tabs.Panel value="All">
                <div className="report_listing">
                  <div className="row">
                    <div className="main_research col-md-12 col-xl-9">
                      <div className="research_left">
                        <div className="research_main">
                          <div className="researchbox">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="researh_bg">
                                  <div className="box_slide">
                                    <div className="items_box">
                                      <div className="card-body p-0">
                                        <div className="mainlucky d-flex align-items-center">
                                          <img
                                            src={cementicon}
                                            alt=""
                                            className="cropped_icon ml-0"
                                          />
                                          <ul className="mb-0 pl-4">
                                            <li className="datemintext">
                                              12 Mins ago
                                            </li>
                                          </ul>
                                        </div>
                                        <h5 className="luckheldtextbox p-0">
                                          LUCK: 2QCY23 EPS clocked in at PKR
                                          0.79; DPS PKR 3.00
                                        </h5>
                                        <p className="fellowparagraph">
                                          LUCK announced its 2QCY23 result
                                          today, wherein the company announced.
                                          <Link to="/market/researchreport">
                                            {" "}
                                            Read More
                                          </Link>
                                        </p>
                                        <div className="mainlucky d-flex">
                                          <span className="date_symbol">
                                            2nd, August 23
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="list_box col-md-5">
                                <div className="latest_rightbox">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={Luckylatest}
                                      alt=""
                                      className="latestminibox"
                                    />
                                  </Link>
                                  <div className="mainlucky d-flex mb-auto align-items-center">
                                    <img
                                      src={luckyicon}
                                      alt=""
                                      className="cropped_icon"
                                    />
                                    <span className="luck_span">
                                      <Link to="/market/researchreport">
                                        Lucky Cement
                                      </Link>
                                    </span>
                                    <ul className="mb-0 pl-3">
                                      <li className="datemintext">
                                        12 Mins ago
                                      </li>
                                    </ul>
                                  </div>
                                  <h5 className="luckheldtext1 p-0">
                                    LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS
                                    PKR 3.00
                                  </h5>
                                  <div className="mainlucky d-flex">
                                    <span className="date_symbol1">
                                      2nd, August 23
                                    </span>
                                  </div>
                                </div>
                                <div className="latest_rightbox mt-3">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={AELLucky}
                                      alt=""
                                      className="latestminibox"
                                    />
                                  </Link>
                                  <div className="mainlucky d-flex mb-auto align-items-center">
                                    <img
                                      src={AELicon}
                                      alt=""
                                      className="cropped_icon"
                                    />
                                    <span className="luck_span">
                                      <Link to="/market/researchreport">
                                        Lucky Cement
                                      </Link>
                                    </span>
                                    <ul className="mb-0 pl-3">
                                      <li className="datemintext">
                                        12 Mins ago
                                      </li>
                                    </ul>
                                  </div>
                                  <h5 className="luckheldtext1 p-0">
                                    LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS
                                    PKR 3.00
                                  </h5>
                                  <div className="mainlucky d-flex">
                                    <span className="date_symbol1">
                                      2nd, August 23
                                    </span>
                                  </div>
                                </div>
                                <div className="latest_rightbox mt-3">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={UblLucky}
                                      alt=""
                                      className="latestminibox"
                                    />
                                  </Link>
                                  <div className="mainlucky d-flex mb-auto align-items-center">
                                    <img
                                      src={alnrsicon}
                                      alt=""
                                      className="cropped_icon"
                                    />
                                    <span className="luck_span">
                                      <Link to="/market/researchreport">
                                        Lucky Cement
                                      </Link>
                                    </span>
                                    <ul className="mb-0 pl-3">
                                      <li className="datemintext">
                                        12 Mins ago
                                      </li>
                                    </ul>
                                  </div>
                                  <h5 className="luckheldtext1 p-0">
                                    LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS
                                    PKR 3.00
                                  </h5>
                                  <div className="mainlucky d-flex">
                                    <span className="date_symbol1">
                                      2nd, August 23
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="res_sec mt-4" id="scrollable-section">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={luckbox}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={cementicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        href="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex mb-3">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={ublboxed}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={sapticon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={federalboxed}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={bglicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={srccement}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={srcicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={cementlucky}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={dfsmicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={luckystrucure}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={habsmicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={worklucky}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={AELicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={construction}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={alnrsicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                <div className="items_box">
                                  <Link to="/market/researchreport">
                                    <img
                                      src={constructionlucky}
                                      alt=""
                                      className="luckbox1_img"
                                    />
                                  </Link>
                                  <div className="card-body p-0">
                                    <div className="mainlucky d-flex align-items-center mt-3">
                                      <img src={srcicon} alt="" />
                                      <span className="luck_span">
                                        <Link to="/market/researchreport">
                                          Lucky Cement
                                        </Link>
                                      </span>
                                      <ul className="mb-0">
                                        <li className="datemintext">
                                          12 Mins ago
                                        </li>
                                      </ul>
                                    </div>
                                    <h5 className="luckheldtext p-0">
                                      LUCK: 2QCY23 EPS clocked in at PKR 0.79;
                                      DPS PKR 3.00
                                    </h5>
                                    <p className="fellowparagraph">
                                      LUCK announced its 2QCY23 result today,
                                      wherein the company announced.
                                      <Link
                                        to="/market/researchreport"
                                        className="read_btn"
                                      >
                                        Read More
                                      </Link>
                                    </p>
                                    <div className="mainlucky d-flex">
                                      <span className="date_symbol">
                                        2nd, August 23
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-3 bg-light">
                      <div className="subcribe_sec border-bottom">
                        <h1>Subscribe to market updates</h1>
                        <p>
                          Get the latest market insights directly in your inbox.
                        </p>
                        <input
                          type="text"
                          placeholder="Enter your email address"
                          className="luckbox1_img research_input"
                        />
                        <button className="luckbox1_img sub_btn">
                          Subscribe
                        </button>
                      </div>
                      <div className="recent_sec subcribe_sec">
                        <h1 className="p-0 mb-4">Recent Reports</h1>
                        <div className="items_box">
                          <Link to="/market/researchreport">
                          <img
                            src={luckbox}
                            alt=""
                            className="latestbigimg mt-2"
                          />
                          </Link>
                          <div className="card-body p-0">
                            <div className="mainlucky d-flex align-items-center mt-3">
                              <img src={cementicon} alt="" />
                              <span className="luck_span">
                                <Link to="/market/researchreport">Lucky Cement</Link>
                              </span>
                              <ul className="mb-0">
                                <li className="datemintext">8 Mins ago</li>
                              </ul>
                            </div>
                            <h5 className="luckheldtext p-0">
                              LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS PKR
                              3.00
                            </h5>
                            <div className="mainlucky d-flex">
                              <span className="date_symbol">
                                2nd, August 23
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="items_box">
                        <Link to="/market/researchreport">
                          <img
                            src={ublboxed}
                            alt=""
                            className="latestbigimg mt-4"
                          />
                          </Link>
                          <div className="card-body p-0">
                            <div className="mainlucky d-flex align-items-center mt-3">
                              <img src={sapticon} alt="" />
                              <span className="luck_span">
                                <Link to="/market/researchreport">Lucky Cement</Link>
                              </span>
                              <ul className="mb-0">
                                <li className="datemintext">8 Mins ago</li>
                              </ul>
                            </div>
                            <h5 className="luckheldtext p-0">
                              LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS PKR
                              3.00
                            </h5>
                            <div className="mainlucky d-flex">
                              <span className="date_symbol">
                                2nd, August 23
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="items_box">
                          <Link to="/market/researchreport">
                          <img
                            src={worklucky}
                            alt=""
                            className="latestbigimg mt-4"
                          />
                          </Link>
                          <div className="card-body p-0">
                            <div className="mainlucky d-flex align-items-center mt-3">
                              <img src={AELicon} alt="" />
                              <span className="luck_span">
                                <Link to="/market/researchreport">Lucky Cement</Link>
                              </span>
                              <ul className="mb-0">
                                <li className="datemintext">8 Mins ago</li>
                              </ul>
                            </div>
                            <h5 className="luckheldtext p-0">
                              LUCK: 2QCY23 EPS clocked in at PKR 0.79; DPS PKR
                              3.00
                            </h5>
                            <div className="mainlucky d-flex">
                              <span className="date_symbol">
                                2nd, August 23
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="Autos">
                Settings tab content2
              </Tabs.Panel>

              <Tabs.Panel value="Banks">
                Settings tab content3
              </Tabs.Panel>

              <Tabs.Panel value="Chemicals">Settings tab content4</Tabs.Panel>

              <Tabs.Panel value="E&P">Settings tab content5</Tabs.Panel>

              <Tabs.Panel value="Economy">Settings tab content6</Tabs.Panel>

              <Tabs.Panel value="Fertilizer">Settings tab content7</Tabs.Panel>

              <Tabs.Panel value="IPPs">Settings tab content8</Tabs.Panel>

              <Tabs.Panel value="IT">Settings tab content9</Tabs.Panel>

              <Tabs.Panel value="Logistics">Settings tab content10</Tabs.Panel>

              <Tabs.Panel value="Oil&Gas">Settings tab content11</Tabs.Panel>

              <Tabs.Panel value="OMCs">Settings tab content12</Tabs.Panel>

              <Tabs.Panel value="Steel">Settings tab content13</Tabs.Panel>

            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default reportlisting;
