import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { addDigit, formatMoney } from '../../../GlobalFunctions';
const Chart = (props) => {
  //console.log(12345, props.dates);
  var category = [];
  var net = [];
  props.lipi.map((value, index) => {
    if (value.label == 'Broker Proprietary trading') {
      value.label = 'Broker';
    }
    category[index] = value.label;
    net[index] = value.net;
  });

  function prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  category = prepend('FIPI', category);
  net = prepend(props.fipi.net, net);

  function average(array) {
    return array.reduce((x, y) => x + y) / array.length;
  }

  const max = Math.max(...net);
  const min = Math.min(...net);

  const fontFamily = 'Helvetica, sans-serif';
  const fontWeight = 400;
  const fontSize = '12px';

  const options = {
    series: [
      {
        // data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        data: net,
      },
    ],
    chart: {
      type: 'bar',
      fontFamily: 'Helvetica, sans-serif !important',
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        distributed: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000'],
        },
        // offsetX: 0,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        colors: ['#000'],
      },
      formatter: (value) => {
        return addDigit(value, 2);
      },
      // offsetX: 5,
      offsetY: -30,
      dropShadow: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#D8D8D8',
      strokeDashArray: 7,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 20,
        bottom: -15,
      },
    },
    //
    stroke: {
      // show: true,
      curve: 'smooth',
      width: 0,
    },
    yaxis: {
      tickAmount: 10,
      // min: 0,
      // min: min + min / net.length,
      // max: max + max / net.length,
      labels: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,

        style: {
          // colors: [],
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          cssClass: 'apexcharts-yaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return addDigit(value, 1);
        },
      },
      title: {
        // text: false,
        text: 'USD bn',
        rotate: 0,
        offsetX: 30,
        offsetY: -210,
        style: {
          // color: undefined,
          fontSize: '12px',
          fontFamily: fontFamily,
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
        style: {
          // colors: [],
          fontSize: fontSize,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          cssClass: 'apexcharts-yaxis-label',
        },
      },

      axisBorder: {
        show: false,
        color: '#78909C',
        height: 0,
        width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },

      categories: category,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#D8D8D8',
          borderWidth: 1,
          opacity: 1,
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={options.series}
        type={'bar'}
        height="500"
        width="100%"
      />
    </>
  );
};

export default Chart;
