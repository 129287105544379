export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",

    SET_LOGGED_IN: "SET_LOGGED_IN",
    SET_AUTH: "SET_AUTH",
    GET_AUTH: "GET_AUTH",
    REMOVE_AUTH: "REMOVE_AUTH",

    SET_ALL_DATA: "SET_ALL_DATA",
    GET_ALL_DATA: "GET_ALL_DATA",
    SET_SELECTED_DATA: "SET_SELECTED_DATA",
    GET_SELECTED_DATA: "GET_SELECTED_DATA",
    SET_DATA: "SET_DATA",
    GET_DATA: "GET_DATA",

    SET_SELECTED_COMPANY_ID: "SET_SELECTED_COMPANY_ID",
    GET_SELECTED_COMPANY_ID: "GET_SELECTED_COMPANY_ID",
    SET_COMPANY_DETAIL: "SET_COMPANY_DETAIL",
    GET_COMPANY_DETAIL: "GET_COMPANY_DETAIL",
    SET_COMPANY_LIST: "SET_COMPANY_LIST",
    GET_COMPANY_LIST: "GET_COMPANY_LIST",
}