import React from 'react';
// import Modal from 'react-bootstrap/Modal';
// import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
// import { modal_close_icon, modal_excel_icon } from '../api/baseUrl';
// import { ApexOptions } from 'apexcharts';
// import { valueCheckGlobal } from '../../GlobalFunctions';

const StockChartModal = (props) => {
  // const theme = useTheme();

  // const years = chartData.slice(-10).map((d) => d['year']);
  // const value = chartData.slice(-10).map((d) => d['value']);
  var xaxis = {};
  var tooltip = { x: { format: 'MMM dd, yyyy' } };
  if (props.chart_id == '1D') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'hh:mm TT',
      },
      // tickAmount: 3,
    };
    tooltip = {
      x: { format: 'MMM dd hh:mm' },
    };
  } else if (props.chart_id == '1M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd',
      },
      // tickAmount: 5,
    };
  } else if (props.chart_id == '3M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd-MMM',
      },
      // tickAmount: 3,
    };
  } else if (props.chart_id == '6M') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'dd-MMM',
      },
      // tickAmount: 6,
    };
  } else if (props.chart_id == '1Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yy',
      },
      // tickAmount: 5,
    };
  } else if (props.chart_id == '3Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yyyy',
      },
      // tickAmount: 3,
    };
  } else if (props.chart_id == '5Y') {
    xaxis = {
      type: 'datetime',
      tickPlacement: 'on',
      decimalInFloat: 3,
      tickPlacement: 'between',
      labels: {
        format: 'MMM-yyyy',
      },
      // tickAmount: 5,
    };
  } else {
    xaxis = {};
  }

  //console.log(123, props.current_feed[props.series].feed[props.chart_id]);

  const ApexOptions = {
    chart: {
      id: 'apexchart-example',
      // foreColor: theme.palette.primary.main,
      type: 'area',
      height: '300px',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.1,
        opacityTo: 0.4,
        // stops: [0, 50, 100],
        // colorStops: [],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    yaxis: {
      opposite: false,
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      // decimalsInFloat: 2,
    },
    xaxis: xaxis,
    legend: {
      width: 400,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      width: 1,
      curve: 'straight',
      // curve: ['smooth', 'straight', 'stepline'],
    },
    tooltip: tooltip,
    series: [
      {
        name: '',
        type: 'area',
        data: props.current_feed[props.series].feed[props.chart_id],
        // data: value,
      },
    ],
  };

  return (
    <>
      <ReactApexChart
        type="area"
        options={ApexOptions}
        series={ApexOptions.series}
        width="100%"
      />
    </>
  );
};

export default StockChartModal;
