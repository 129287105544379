import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import InputField from './inputField';
import { forgotAPI } from '../../api/api';
// import './login.css';
import forgotEmail from '../../assets/media/images/forgotEmail.svg';

const ForgotModal = (props) => {
  const [successMessage, setSuccesMessage] = useState(false);
  const [error, setError] = useState('');

  const site = props.site;

  const validate = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const handleSubmit = (values) => {
    setError('');
    setSuccesMessage(false);
    axios
      .post(forgotAPI, values)
      .then(function (response) {
        setSuccesMessage(true);
        setTimeout(() => {
          props.closeModal();
          setSuccesMessage(false);
        }, 3000);
      })
      .catch(function (error) {
        setSuccesMessage(false);
        if (error.response.status === 400) {
          console.log(error.response.data.error);
          setError(error.response.data.error);
        } else {
          console.log(error.response.data.error);
        }
      });
  };
  const closeModal = () => {
    props.closeModal();
    setError('');
  };
  return (
    <>
      <Modal
        centered
        className="table-modal forgot-modal"
        show={props.forgotModal ? true : false}
        style={{ textAlign: 'center;' }}
      >
        <Modal.Header>
          <button
            className="close-subs-view"
            onClick={closeModal}
            style={{ background: '#000000' }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="times"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className="svg-inline--fa fa-times fa-w-10 fa-2x"
            >
              <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                className=""
              ></path>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          {successMessage ? (
            <div className="forgot-content" style={styles.forgotContentSuccess}>
              <img src={forgotEmail} style={{ width: '300px' }} />
              <h3 style={styles.forgotEmailText} className="text-center">
                Please check your email for <br /> reset password link
              </h3>
            </div>
          ) : (
            <>
              <div className="forgot-content">
                <h3>Forgot Password?</h3>
                <p style={styles.forgotParagraph}>
                  Enter your verified email address and we'll send you a link to
                  reset your password.
                </p>
              </div>
              <Formik
                initialValues={{
                  email: '',
                  site: site,
                }}
                validationSchema={validate}
                onSubmit={async (values) => {
                  handleSubmit(values);
                }}
              >
                {(formik) => (
                  <>
                    <Form>
                      <InputField
                        type="hidden"
                        name="site"
                        value={formik.values.site}
                      />
                      <InputField
                        type="email"
                        label="Email Address"
                        name="email"
                        placeholder="Enter your registered email address"
                        onFocus={() => setError('')}
                        onChange={formik.handleChange}
                      />
                      {error != '' ? (
                        <span
                          style={{
                            position: 'absolute',
                            top: '60px',
                            color: 'red',
                            fontSize: '12px',
                          }}
                        >
                          {error}
                        </span>
                      ) : null}
                      <div className="form-group d-inline-flex justify-content-center w-100 forgot-submit-btn">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          name="submit"
                        >
                          Request Password Change
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotModal;

const styles = {
  forgotContentSuccess: {
    margin: '0',
    padding: '0',
    border: 'none',
    textAlign: 'center',
  },
  forgotParagraph: {
    fontSize: '14px',
    color: '#3d3d3d',
  },
  forgotEmailText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    marginTop: '32px',
    lineHeight: '40px',
    textAlign: 'center',
    color: '#3D3D3D',
  },
};
