import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const IdleTimerContainer = ({ setSessionMessage, handleSession }) => {
  const [cookies, setCookies, removeCookies] = useCookies();
  const idleTimerRef = useRef(null);
  const history = useHistory();

  const onIdle = () => {
    setSessionMessage(
      'Your session has expired due to inactivity. Please login again.'
    );
    handleSession();
  };
  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={60 * 100000}
        onIdle={onIdle}
        crossTab={{ emitOnAllTabs: true }}
      ></IdleTimer>
    </>
  );
};

export default IdleTimerContainer;
