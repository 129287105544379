import React, { useState, useEffect } from 'react';
import Nav from './nav.jsx';
import './market2.css';
import IdleTimer from '../../IdleTimerContainer';
import Header from '../../common/header/header';
import SessionExpire from '../../layout/login/sessionExpire';
import LoadingImage from '../../assets/media/loader.gif';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import arrowicon from '../../../src/assets/media/images/arrow-icon.svg';
import arrowdown from '../../../src/assets/media/images/arrow-down.svg';
import { useCookies } from 'react-cookie';
import chartlogo from '../../../src/assets/media/images/indices.svg';
import luckyicon from '../../../src/assets/media/images/luck-icon.svg';
import breflyicon from '../../../src/assets/media/images/brefly-icon.svg';
import heldicon from '../../../src/assets/media/images/held-icon.svg';
import gasicon from '../../../src/assets/media/images/gas-icon.svg';
import ranbowicon from '../../../src/assets/media/images/ranbow-icon.svg';
import shellicon from '../../../src/assets/media/images/shell-icon.svg';
import blogthumbnail from '../../../src/assets/media/images/blog-thumbnail.svg';
import chartline from '../../../src/assets/media/images/tab-chartline.svg';
import axios from 'axios';
import { baseUrl, pdf, loader } from '../../api/baseUrl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { modal_close_icon, modal_excel_icon } from '../../api/baseUrl';
import Chart from './Chart';
import Switch from 'react-input-switch';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ApexCharts from 'apexcharts';
import {
  Slider,
  RangeSlider,
  Tooltip,
  ColorInput,
  Radio,
  rem,
} from '@mantine/core';
import {
  YearPickerInput,
  MonthPickerInput,
  DatePickerInput,
} from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { DatePicker, ColorPicker } from 'antd';

function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);

  let month = ('0' + (date.getMonth() + 1)).slice(-2); //months from 1-12
  let day = ('0' + date.getDate()).slice(-2);
  let year = date.getFullYear();

  let newdate = year + '/' + month + '/' + day;
  return newdate;
}
const current_year = new Date().getFullYear();
const current_month = ('0' + (new Date().getMonth() + 1)).slice(-2);
const current_day = ('0' + new Date().getDate()).slice(-2);
const current_date = current_year + '/' + current_month + '/' + current_day;
const one_year_ago = subtractYears(new Date(), 1);
// console.log('000', one_year_ago, current_date);

const { RangePicker } = DatePicker;

const animatedComponents = makeAnimated();

function Index(props) {
  const history = useHistory();
  const cities = [
    { value: 'islamabad', label: 'Islamabad' },
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'sialkot', label: 'Sialkot' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faisalabad', label: 'Faisalabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'karachi', label: 'Karachi' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
    { value: 'khuzdar', label: 'Khuzdar' },
    { value: 'national', label: 'National' },
  ];
  const cities_cng = [
    { value: 'islamabad', label: 'Islamabad' },
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'sialkot', label: 'Sialkot' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faisalabad', label: 'Faisalabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'karachi', label: 'Karachi' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
    { value: 'khuzdar', label: 'Khuzdar' },
  ];
  const cities_fertilizer = [
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faisalabad', label: 'Faisalabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
  ];
  const icon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const [sessionMessage, setSessionMessage] = useState('');
  const [sessionModal, setSessionModal] = useState(false);
  const [dark, setDark] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoading2, setIsLoading2] = useState(true);
  const accessToken = cookies.accessToken;
  const [loaderIcon, setLoaderIcon] = useState(false);
  const [searchList, setSearchList] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [city, setCity] = useState([]);
  const [classification, setClassification] = useState([]);
  const [avg, setAvg] = useState([]);
  const [avgSelector, setAvgSelector] = useState(true);
  const [records, setRecords] = useState([]);
  const [cityOptions, setCityOptions] = useState(cities);
  const [frequency, setFrequency] = useState('Day');
  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([]);
  const [axis, setAxis] = useState([]);
  const [chartType, setChartType] = useState('line');
  const [rangeValue, setRangeValue] = useState([80, 100]);
  const [colors, setColors] = useState([]);
  const [strock, setStrock] = useState([]);
  // const [timePeriod, setTimePeriod] = useState(rangeValue);
  const [btnName, setBtnName] = useState('Load Chart');

  const [value, setValue] = useState([null, null]);

  const [timePeriod, setTimePeriod] = useState([one_year_ago, current_date]);

  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;
  const average = [
    { value: 'min', label: 'Min' },
    { value: 'max', label: 'Max' },
    { value: 'average', label: 'Average' },
  ];

  const handleShow = () => setShow(true);

  // useEffect(() => {}, [color]);
  useEffect(() => {
    const list = fetch(`${baseUrl}/searchlist`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchList(data);
        setIsLoading(true);
        // const companyListIndex = data.filter((p) => p.value == symbol);
        // setCompanyListIndex(companyListIndex);
        // console.log(123, data, companyListIndex);
      });
  }, []);

  const sessionTimer = localStorage.getItem('sessionTime');
  const dateFormat = 'YYYY-MM-DD';

  function sessionTimeout() {
    setSessionModal(false);
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    localStorage.removeItem('sessionTime');
    history.replace('/');
  }

  var count = sessionTimer * 1000;
  setTimeout(() => {
    setSessionMessage('Your session has timed out. Please login again.');
    handleSession();
  }, 3600000);

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }

  const handleCityChange = (city) => {
    setCity(city);
    // console.log(city);
  };
  const handleAverageChange = (average) => {
    setAvg(average);
    // console.log(average);
  };

  var handleChange = (selectedOption, context) => {
    //console.log(selectedOption);
    if (context.action == 'remove-value') {
      setSelectedItem(selectedOption);
      setShow(false);
      return;
    }
    if (selectedOption.length > 0) {
      setSelectedItem(selectedOption);
      let lastElement = selectedOption[selectedOption.length - 1];
      let lastElementArray = lastElement.value.split('_');
      // if (lastElement.label.indexOf('SPI') > -1) {
      setClassification(lastElementArray[1]);
      if (lastElementArray[1] == 'SPI') {
        // console.log(lastElementArray[1], lastElementArray[0]);
        setAvgSelector(false);
        if (lastElementArray[2] == 'Essential') {
          setAvgSelector(true);
          setCityOptions(cities);
        } else if (lastElementArray[2] == 'Fertilizer') {
          setCityOptions(cities_fertilizer);
        } else {
          setCityOptions(cities_cng);
        }
        setTitle(lastElement.label);
        setShow(true);
        // console.log(123, lastElement);
      }
    }
  };

  const handleSPIClose = () => {
    if (selectedItem.length > 0) {
      // setSelectedItem(selectedOption);
      const lastElement = selectedItem.length;
      let lastElementIndex = selectedItem[lastElement - 1];
      setSelectedItem(selectedItem.splice(0, lastElement - 1));
      // console.log(lastElement - 1, lastElementIndex, lastElement, selectedItem);
    }
    setShow(false);
  };

  const ApplySPI = () => {
    // selectedItem;

    const lastElement = selectedItem.length;
    let lastElementIndex = selectedItem[lastElement - 1];

    // obj = {};
    lastElementIndex['city'] = city.value;
    lastElementIndex['avg'] = avg.value;
    // console.log(lastElementIndex, selectedItem);
    setShow(false);
    // let lastElement = selectedOption[selectedOption.length - 1];
  };

  const handleSwitch = (value) => {
    setChartType(value);
  };

  const _handleSubmit = () => {
    setRecords([]);
    // const [records, setRecords] = useState([]);

    axios
      .post(`${baseUrl}/inflation`, {
        data: selectedItem,
        classification: classification,
        frequency: frequency,
        timePeriod: timePeriod,
        marks: marks,
      })
      .then(function (response) {
        setRecords(response.data.table);
        setSeries(response.data.chart);
        setDates(response.data.dates);
        setAxis(response.data.axis);
        setColors(response.data.colors);
        setStrock(response.data.strock);
        setLoaderIcon(false);
        setBtnName('Reload Chart');

        //console.log(response.data.colors, colors);
        // console.log('inflation data', response.data.table);
        // setCharts(response.data);
      })
      .catch(function (error) {
        console.log('inflation====>', error);
      });
  };
  const handleSubmit = () => {
    // console.log(selectedItem);
    setLoaderIcon(true);

    _handleSubmit();
  };

  const handleTimePeriod = (e) => {
    // console.log(e.target.value, selectedItem);
    setTimePeriod(e.target.value);
    if (selectedItem.length > 0) {
      _handleSubmit();
    }
    // setCurrentPage(1);
    // setPostsPerPage(e.target.value);
  };

  const handleSeries = (seriesName) => {
    // console.log(e.target.seriesname);
    ApexCharts.exec('graphID', 'toggleSeries', seriesName);
  };

  const handleChartType = (index, type) => {
    series[index]['type'] = type;
    setSeries(series);
    strock[index] = type == 'bar' ? 0 : 2;
    setStrock(strock);
    var stroks = {
      stroke: {
        show: true,
        curve: 'smooth',
        width: strock,
      },
    };

    ApexCharts.exec('graphID', 'updateOptions', stroks);

    ApexCharts.exec('graphID', 'updateSeries', series, true);
  };
  const arr = [];
  const handleDataLable = (index) => {
    if (arr.includes(index)) {
      arr.pop(index);
    } else {
      arr.push(index);
    }
    var lebel = {
      dataLabels: {
        enabled: true,
        position: 'top',
        enabledOnSeries: arr,
      },
    };
    ApexCharts.exec('graphID', 'updateOptions', lebel);
  };

  /* const marks = [
    { value: 0, label: '2018' },
    { value: 20, label: '2019' },
    { value: 40, label: '2020' },
    { value: 60, label: '2021' },
    { value: 80, label: '2022' },
    { value: 100, label: '2023' },
  ]; */

  var marks = [];
  var k = 5;
  for (var x = 0; x <= 100; x += 20) {
    marks = [...marks, { value: x, label: current_year - k }];
    k--;
  }

  // console.log(marks, objects);

  const handleClose = (index) => {
    // delete series[index];
    series.splice(index, 1);
    const index2 = series.indexOf(index);
    setSeries(series);

    ApexCharts.exec('graphID', 'updateSeries', series, true);
    console.log(index, records, series);
  };
  const handleRangeValue = (value) => {
    setRangeValue(value);
    setTimePeriod(value);
    console.log(value);
  };

  const handleSeriesColor = (e, index) => {
    // console.log(e.toHexString());
    colors[index] = e.toHexString();
    var color = {
      fill: {
        colors: colors,
      },
    };

    ApexCharts.exec('graphID', 'updateOptions', color);
    // console.log(e.metaColor.originalInput, index);
  };
  const handleRangePicker = (date, dateString) => {
    // setRangeValue(value);
    setTimePeriod(dateString);
    // console.log(date, dateString);
  };

  const handleDate = (dateString) => {
    // setRangeValue(value);
    setTimePeriod(dateString);
    // console.log(dateString);
  };

  const handleFrequency = (value) => {
    // console.log(123, value);
    setFrequency(value);
  };

  // console.log(marks);

  return (
    <>

      <IdleTimer
        handleSession={handleSession}
        setSessionMessage={setSessionMessage}
      />
      <Header dark={dark} setDark={setDark} />
      <div className="landing">
        <div className="container container_box">
          <div className="row">
            <div className="col-md-8 chart">
              <div className="Tab_learn">
                <h2>Indices</h2>
                <hr />
                <div className="tab-card">
                  <div className="tab-card-header">
                    <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="active tab-links" id="KSE100-tab" data-toggle="tab" href="#KSE100"
                          role="tab" aria-controls="KSE100" aria-selected="true">KSE100<img
                            src={chartline} alt="" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="tab-links" id="KSE30-tab" data-toggle="tab" href="#KSE30" role="tab"
                          aria-controls="KSE30" aria-selected="false">KSE30<img
                            src={chartline} alt="" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="tab-links" id="ALLSHR-tab" data-toggle="tab" href="#ALLSHR" role="tab"
                          aria-controls="ALLSHR" aria-selected="false">ALLSHR<img
                            src={chartline} alt="" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="tab-links" id="KMI30-tab" data-toggle="tab" href="#KMI30" role="tab"
                          aria-controls="KMI30" aria-selected="false">KMI30<img
                            src={chartline} alt="" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="tab-links" id="BKTI-tab" data-toggle="tab" href="#BKTI" role="tab"
                          aria-controls="BKTI" aria-selected="false">BKTI<img
                            src={chartline} alt="" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="tab-links" id="OGTI-tab" data-toggle="tab" href="#OGTI" role="tab"
                          aria-controls="Three" aria-selected="false">OGTI<img
                            src={chartline} alt="" /></a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active p-3" id="KSE100" role="tabpanel"
                      aria-labelledby="KSE100-tab">
                      <div className="row chart-row-one mb-0">
                        <div className="col-md-6 indices order-md-1">
                          <div className="row indices-border-bottom">
                            <div className="col-4 text-left">
                              <p className="heading number">39848.35</p>
                              <p className="content">48,906,963</p>
                            </div>
                            <div className="col-4 text-left">
                              <img src={chartlogo} alt="" />
                            </div>
                            <div className="col-4 text-right">
                              <p className="heading text-color-red number">-0.08%</p>
                              <p className="content text-color-red">-31.54</p>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col-4 text-left">
                              <p className="heading">HIGH</p>
                              <p className="content">48,906,963</p>
                            </div>
                            <div className="col-4 pl-3">
                              <p className="heading text-start">LOW</p>
                              <p className="content text-start">48,906,963</p>
                            </div>
                            <div className="col-4 pr-0 incides-text">
                              <p className="heading">VOLUME</p>
                              <p className="content">-31.54</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 text-left">
                              <p className="heading">52 WEEK HIGH</p>
                              <p className="content">48,906,963</p>
                            </div>
                            <div className="col-4 pl-3">
                              <p className="heading text-start">52 WEEK LOW</p>
                              <p className="content text-start">48,906,963</p>
                            </div>
                            <div className="col-4 pr-0 incides-text">
                              <p className="heading">PREV. CLOSE</p>
                              <p className="content">-31.54</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 text-left">
                              <p className="heading">P/E Ratio</p>
                              <p className="content">48,906,963</p>
                            </div>
                            <div className="col-4 pl-3 pr-0">
                              <p className="heading text-start">DIVIDEND YIELD</p>
                              <p className="content text-start">48,906,963</p>
                            </div>
                            <div className="col-4 pr-0 incides-text">
                              <p className="heading">MARKET CAP</p>
                              <p className="content">-31.54</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 indices-chart order-md-12">
                          <figure className="highcharts-figure">
                            <div id="container"></div>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="top_active_display">
                  <h2>Top Active Stocks</h2>
                  <table className="table side-table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Price</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Change</th>
                        <th scope="col">Volume</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>PKGP</td>
                        <td>31.00</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">1.00 (3.33%)</td>
                        <td>4,417,000</td>
                      </tr>
                      <tr>
                        <td>HASCOL</td>
                        <td>5.94</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.27 (4.76%)</td>
                        <td>3,419,000</td>
                      </tr>
                      <tr>
                        <td>KEL</td>
                        <td>2.07</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.00 (0.00%)</td>
                        <td>2,741,919</td>
                      </tr>
                      <tr>
                        <td>TELE</td>
                        <td>7.45</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.18 (-2.36)</td>
                        <td>2,020,000</td>
                      </tr>
                      <tr>
                        <td>WTL</td>
                        <td>1.19</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.01 (0.85%)</td>
                        <td>1,912,690</td>
                      </tr>
                      <tr>
                        <td>TPLP</td>
                        <td>14.00</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.17 (-1.20%)</td>
                        <td>1,539,527</td>
                      </tr>
                      <tr>
                        <td>CNERGY</td>
                        <td>3.42</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.06 (-1.72%)</td>
                        <td>1,475,067</td>
                      </tr>
                      <tr>
                        <td>HBL</td>
                        <td>73.00</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.07 (0.10%)</td>
                        <td>1,128,024</td>
                      </tr>
                      <tr>
                        <td>WAVES</td>
                        <td>7.31</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.16 (2.24%)</td>
                        <td>978,000</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>Top Advancers</h2>
                  <table className="table side-table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Price</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Change</th>
                        <th scope="col">Volume</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RICL</td>
                        <td>6.31</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.52 (8.98%)</td>
                        <td>2,500</td>
                      </tr>
                      <tr>
                        <td>SNBL</td>
                        <td>9.28</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.76 (8.92%)</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>ASTM</td>
                        <td>6.48</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.53 (8.91%)</td>
                        <td>18,000</td>
                      </tr>
                      <tr>
                        <td>SCL</td>
                        <td>303.15</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">21.15 (7.50%)</td>
                        <td>200</td>
                      </tr>
                      <tr>
                        <td>PECO</td>
                        <td>325.40</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">22.70 (7.50%)</td>
                        <td>39,100</td>
                      </tr>
                      <tr>
                        <td>KHYT</td>
                        <td>427.22</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">29.80 (7.50%)</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>JDMT</td>
                        <td>44.42</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">3.09 (7.48%)</td>
                        <td>1,000</td>
                      </tr>
                      <tr>
                        <td>SASML</td>
                        <td>14.50</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">1.00 (7.41%)</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>ICIBL</td>
                        <td>1.20</td>
                        <td><img src={arrowicon} alt="" /></td>
                        <td id="arrow-up">0.08 (7.14%)</td>
                        <td>500</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>Top Decliners</h2>
                  <table className="table side-table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Price</th>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Change</th>
                        <th scope="col">Volume</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>FUDLM</td>
                        <td>4.52</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.68 (-13.08%)</td>
                        <td>3,000</td>
                      </tr>
                      <tr>
                        <td>BILF (DEF)</td>
                        <td>2.15</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.29 (-11.88%)</td>
                        <td>2,000</td>
                      </tr>
                      <tr>
                        <td>SEL</td>
                        <td>6.25</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.75 (-10.71%)</td>
                        <td>2,000</td>
                      </tr>
                      <tr>
                        <td>META</td>
                        <td>5.55</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-0.51 (-8.42)</td>
                        <td>17,500</td>
                      </tr>
                      <tr>
                        <td>NAGC</td>
                        <td>55.70</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-4.49 (-7.46%)</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>WAHN</td>
                        <td>144.55</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-11.65 (-7.46%)</td>
                        <td>200</td>
                      </tr>
                      <tr>
                        <td>SURC</td>
                        <td>93.50</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-7.50 (-7.43%)</td>
                        <td>300</td>
                      </tr>
                      <tr>
                        <td>PAKD</td>
                        <td>59.26</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-4.69 (-7.33%)</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>EMCO</td>
                        <td>23.07</td>
                        <td><img src={arrowdown} alt="" /></td>
                        <td id="arrow-down">-1.77 (-7.13%)</td>
                        <td>1,500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="charting">
                <div className="row chart-row-two">
                  <div className="col-md-6 float-left research">
                    <h2>Research Reports</h2>
                    <hr />
                    <div className="researchitem">
                      <div className="learn_icon">
                        <img src={luckyicon} alt="" />
                        <div className="heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                          1HFY23
                          Financial Results.
                        </div>
                      </div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="detail.php">View More</a> &nbsp; <a
                          href="sample.pdf">Download</a> &nbsp; <img
                            src="img/downloadminiicon.svg" alt="" /></span>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem">
                      <div className="learn_icon">
                        <img src={breflyicon} alt="" />
                        <div className="heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                          1HFY23
                          Financial Results.</div>
                      </div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="detail.php">View More</a> &nbsp; <a
                          href="sample.pdf">Download</a> &nbsp; <img
                            src="img/downloadminiicon.svg" alt="" /></span>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem">
                      <div className="learn_icon">
                        <img src={heldicon} alt="" />
                        <div className="heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                          1HFY23
                          Financial Results.</div>
                      </div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="detail.php">View More</a> &nbsp; <a
                          href="sample.pdf">Download</a> &nbsp; <img
                            src="img/downloadminiicon.svg" alt="" /></span>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="col-md-6 float-right latest">
                    <h2>Latest News</h2>
                    <hr />
                    <div className="researchitem">
                      <div className="col-md-1 icon"><img src={gasicon} alt="" /></div>
                      <div className="col-md-12 latest-heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                        1HFY23
                        Financial Results.</div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="latestnews.php">View More</a></span>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem">
                      <div className="col-md-1 icon"><img src={ranbowicon} alt="" /></div>
                      <div className="col-md-12 latest-heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                        1HFY23
                        Financial Results.</div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="latestnews.php">View More</a></span>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem">
                      <div className="col-md-1 icon"><img src={shellicon} alt="" /></div>
                      <div className="col-md-12 latest-heading">LUCK Held Its Analyst Briefing Today To Discuss Its
                        1HFY23
                        Financial Results.</div>
                      <div className="col-md-12 content">The company reported consolidated earnings of PKR
                        49.32/share, up 21% YoY during the 1HFY23..</div>
                      <div className="col-md-12 calander"><i className="fa-solid fa-calendar-days"></i> 28th Aug,
                        2004
                        <span className="float-right"><a href="latestnews.php">View More</a></span>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 float-left blog">
                    <h2 className="blogheading mt-3">Blogs</h2>
                    <hr />
                    <div className="researchitem pl-0 mb-3">
                      <img src={blogthumbnail} alt="" className="blog-thumnail" />
                      <div className="blogitem">
                        <div className="col-md-12 blogheading">LUCK held its analyst briefing today to
                          discuss
                          its
                          1HFY23 financial results.</div>
                        <div className="col-md-12 blogcontent">The company reported consolidated earnings of
                          PKR
                          49.32/share, up 21% YoY during the 1HFY23 (PKR 32.51/share in 2Q).</div>
                        <div className="col-md-12 calander pl-3"> <span className="float-right"><a
                          href="detail.php">View More</a></span><span
                            className="float-right mr-3"><i className="fa-solid fa-calendar-days"></i> 28th
                            Aug, 2004</span></div>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem pl-0 mb-3">
                      <img src={blogthumbnail} alt="" className="blog-thumnail" />
                      <div className="blogitem">
                        <div className="col-md-12 blogheading">LUCK held its analyst briefing today to
                          discuss
                          its
                          1HFY23 financial results.</div>
                        <div className="col-md-12 blogcontent">The company reported consolidated earnings of
                          PKR
                          49.32/share, up 21% YoY during the 1HFY23 (PKR 32.51/share in 2Q).</div>
                        <div className="col-md-12 calander pl-3"> <span className="float-right"><a
                          href="detail.php">View More</a></span><span
                            className="float-right mr-3"><i className="fa-solid fa-calendar-days"></i> 28th
                            Aug, 2004</span></div>
                      </div>
                    </div>
                    <hr />
                    <div className="researchitem pl-0 mb-3">
                      <img src={blogthumbnail} alt="" className="blog-thumnail" />
                      <div className="blogitem">
                        <div className="col-md-12 blogheading">LUCK held its analyst briefing today to
                          discuss
                          its
                          1HFY23 financial results.</div>
                        <div className="col-md-12 blogcontent">The company reported consolidated earnings of
                          PKR
                          49.32/share, up 21% YoY during the 1HFY23 (PKR 32.51/share in 2Q).</div>
                        <div className="col-md-12 calander pl-3"> <span className="float-right"><a
                          href="detail.php">View More</a></span><span
                            className="float-right mr-3"><i className="fa-solid fa-calendar-days"></i> 28th
                            Aug, 2004</span></div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="top_active col-md-4">
              <h2>Top Active Stocks</h2>
              <table className="table side-table">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Price</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Change</th>
                    <th scope="col">Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PKGP</td>
                    <td>31.00</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">1.00 (3.33%)</td>
                    <td>4,417,000</td>
                  </tr>
                  <tr>
                    <td>HASCOL</td>
                    <td>5.94</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.27 (4.76%)</td>
                    <td>3,419,000</td>
                  </tr>
                  <tr>
                    <td>KEL</td>
                    <td>2.07</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.00 (0.00%)</td>
                    <td>2,741,919</td>
                  </tr>
                  <tr>
                    <td>TELE</td>
                    <td>7.45</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.18 (-2.36)</td>
                    <td>2,020,000</td>
                  </tr>
                  <tr>
                    <td>WTL</td>
                    <td>1.19</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.01 (0.85%)</td>
                    <td>1,912,690</td>
                  </tr>
                  <tr>
                    <td>TPLP</td>
                    <td>14.00</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.17 (-1.20%)</td>
                    <td>1,539,527</td>
                  </tr>
                  <tr>
                    <td>CNERGY</td>
                    <td>3.42</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.06 (-1.72%)</td>
                    <td>1,475,067</td>
                  </tr>
                  <tr>
                    <td>HBL</td>
                    <td>73.00</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.07 (0.10%)</td>
                    <td>1,128,024</td>
                  </tr>
                  <tr>
                    <td>WAVES</td>
                    <td>7.31</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.16 (2.24%)</td>
                    <td>978,000</td>
                  </tr>
                </tbody>
              </table>
              <h2>Top Advancers</h2>
              <table className="table side-table">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Price</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Change</th>
                    <th scope="col">Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>RICL</td>
                    <td>6.31</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.52 (8.98%)</td>
                    <td>2,500</td>
                  </tr>
                  <tr>
                    <td>SNBL</td>
                    <td>9.28</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.76 (8.92%)</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>ASTM</td>
                    <td>6.48</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.53 (8.91%)</td>
                    <td>18,000</td>
                  </tr>
                  <tr>
                    <td>SCL</td>
                    <td>303.15</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">21.15 (7.50%)</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>PECO</td>
                    <td>325.40</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">22.70 (7.50%)</td>
                    <td>39,100</td>
                  </tr>
                  <tr>
                    <td>KHYT</td>
                    <td>427.22</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">29.80 (7.50%)</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>JDMT</td>
                    <td>44.42</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">3.09 (7.48%)</td>
                    <td>1,000</td>
                  </tr>
                  <tr>
                    <td>SASML</td>
                    <td>14.50</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">1.00 (7.41%)</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>ICIBL</td>
                    <td>1.20</td>
                    <td><img src={arrowicon} alt="" /></td>
                    <td id="arrow-up">0.08 (7.14%)</td>
                    <td>500</td>
                  </tr>
                </tbody>
              </table>
              <h2>Top Decliners</h2>
              <table className="table side-table">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Price</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Change</th>
                    <th scope="col">Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>FUDLM</td>
                    <td>4.52</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.68 (-13.08%)</td>
                    <td>3,000</td>
                  </tr>
                  <tr>
                    <td>BILF (DEF)</td>
                    <td>2.15</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.29 (-11.88%)</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>SEL</td>
                    <td>6.25</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.75 (-10.71%)</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>META</td>
                    <td>5.55</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-0.51 (-8.42)</td>
                    <td>17,500</td>
                  </tr>
                  <tr>
                    <td>NAGC</td>
                    <td>55.70</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-4.49 (-7.46%)</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>WAHN</td>
                    <td>144.55</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-11.65 (-7.46%)</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>SURC</td>
                    <td>93.50</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-7.50 (-7.43%)</td>
                    <td>300</td>
                  </tr>
                  <tr>
                    <td>PAKD</td>
                    <td>59.26</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-4.69 (-7.33%)</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>EMCO</td>
                    <td>23.07</td>
                    <td><img src={arrowdown} alt="" /></td>
                    <td id="arrow-down">-1.77 (-7.13%)</td>
                    <td>1,500</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
