import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
// import axios from 'axios';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import ChartModal from './../../../layout/modals/ChartModal';
import StockDataTableModal from './../../../layout/modals/StockDataTableModal';

import loader from '../../../assets/media/_loader.gif';
// import { useCookies } from 'react-cookie';

const StockDataNew = ({ company }) => {
  let compId = company.id;
  let sectorId = company.sector_id;
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [stockPrice, setStockPrice] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/stockprice/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStockPrice(data);
        setIsLoading(true);
      });
  }, [compId]);

  useEffect(() => {
    fetch(`${baseUrl}/sector-stockprice/${sectorId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('ok ok ok', data);
        setSectorData(data);
      });
  }, [sectorId]);

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [lable, setLable] = useState('');
  const [dataKey, setDataKey] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const handleChartModal = (company_name, lable, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLable(lable);
    setchartData(chartData);
    setChartActive(true);
  };

  const handleTableModal = (lable, name) => {
    setDataKey(name);
    setCompanyName(name);
    setLable(lable);
    setTableActive(true);
  };

  if (stockPrice.length && isLoading) {
    var adjusted_numbers = [];
    var free_float_share = [];
    var free_float_share_in_percentage = [];
    var high = [];
    var low = [];
    var average = [];
    var closing = [];
    var capital_gain = [];
    var dividend_return = [];
    var total_return = [];
    stockPrice.map((item, index) => {
      adjusted_numbers[index] = {
        year: item.label,
        value: item.adjusted_numbers != undefined ? item.adjusted_numbers : 0,
      };

      free_float_share[index] = {
        year: item.label,
        value: item.free_float_share != undefined ? item.free_float_share : 0,
      };

      free_float_share_in_percentage[index] = {
        year: item.label,
        value:
          item.free_float_share_in_percentage != undefined
            ? item.free_float_share_in_percentage
            : 0,
      };

      high[index] = {
        year: item.label,
        value: item.max != undefined ? item.max : 0,
      };

      low[index] = {
        year: item.label,
        value: item.min != undefined ? item.min : 0,
      };

      average[index] = {
        year: item.label,
        value: item.avg != undefined ? item.avg : 0,
      };

      closing[index] = {
        year: item.label,
        value: item.closing != undefined ? item.closing : 0,
      };

      capital_gain[index] = {
        year: item.label,
        value: item.capital_gain != undefined ? item.capital_gain : 0,
      };

      dividend_return[index] = {
        year: item.label,
        value: item.dividend_return != undefined ? item.dividend_return : 0,
      };

      total_return[index] = {
        year: item.label,
        value: item.total_return != undefined ? item.total_return : 0,
      };
    });
    // console.log('data_obj12 ', total_return); getSharePriceData2 generateStockPriceData

    return (
      <>
        <div className="comp-financials stocktable">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={3} className="text-left">
                  Stock Data
                </th>
                {stockPrice.map((item, index) => {
                  return <th key={'year' + index}>{item.label}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr style={styles.headingtr}>
                <td colSpan={2}>Outstanding Shares - Adjusted</td>
              </tr>
              <tr>
                <td>
                  Total Shares <span className="table-unit-col">(mn)</span>
                </td>
                <td colSpan={2}>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Total Shares',
                        adjusted_numbers
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Total Shares', 'adjusted_numbers');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {/* let modal_data =  */}
                {stockPrice.map((data, i) => {
                  if (data.adjusted_numbers != undefined) {
                    return (
                      <td key={i}>{valueCheckGlobal(data.adjusted_numbers)}</td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td>
                  Free Float Shares <span className="table-unit-col">(mn)</span>
                </td>
                <td colSpan={2}>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Free Float Shares',
                        free_float_share
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Free Float Shares', 'free_float_share');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.free_float_share != undefined) {
                    return (
                      <td key={i}>{valueCheckGlobal(data.free_float_share)}</td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td>
                  Free Float <span className="table-unit-col">(%)</span>
                </td>
                <td colSpan={2}>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Free Float',
                        free_float_share_in_percentage
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal(
                        'Free Float',
                        'free_float_share_in_percentage'
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.free_float_share_in_percentage != undefined) {
                    return (
                      <td key={i}>
                        {valueCheckGlobal(data.free_float_share_in_percentage)}
                      </td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr style={styles.headingtr}>
                <td colSpan={3}>Adjusted Stock Prices</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  High <span className="table-unit-col">(PKR)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(company.name, 'High', high);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('High', 'max');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.max != undefined) {
                    return <td key={i}>{valueCheckGlobal(data.max)}</td>;
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td colSpan={2}>
                  Low <span className="table-unit-col">(PKR)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(company.name, 'Low', low);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Low', 'min');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.min != undefined) {
                    return <td key={i}>{valueCheckGlobal(data.min)}</td>;
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td colSpan={2}>
                  Average <span className="table-unit-col">(PKR)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(company.name, 'Average', average);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Average', 'avg');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.avg != undefined) {
                    return <td key={i}>{valueCheckGlobal(data.avg)}</td>;
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td colSpan={2}>
                  Closing <span className="table-unit-col">(PKR)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(company.name, 'Closing', closing);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Closing', 'closing');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.closing != undefined) {
                    return <td key={i}>{valueCheckGlobal(data.closing)}</td>;
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr style={styles.headingtr}>
                <td colSpan={3}>Return Composition</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  Capital Gain / (Loss)
                  <span className="table-unit-col">(%)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Capital Gain / (Loss)',
                        capital_gain
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>

                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Capital Gain / (Loss)', 'capital_gain');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.capital_gain != undefined) {
                    return (
                      <td key={i}>{valueCheckGlobal(data.capital_gain)}</td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td colSpan={2}>Dividend Return</td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Dividend Return',
                        dividend_return
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Dividend Return', 'dividend_return');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.max != undefined) {
                    return (
                      <td key={i}>{valueCheckGlobal(data.dividend_return)}</td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
              <tr>
                <td colSpan={2}>
                  Total Return<span className="table-unit-col">(%)</span>
                </td>
                <td>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleChartModal(
                        company.name,
                        'Total Return',
                        total_return
                      );
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_chart_icon,
                      }}
                    />
                  </span>
                  <span
                    style={styles.chart}
                    onClick={() => {
                      handleTableModal('Total Return', 'total_return');
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_table_icon,
                      }}
                    />
                  </span>
                </td>
                {stockPrice.map((data, i) => {
                  if (data.max != undefined) {
                    return (
                      <td key={i}>{valueCheckGlobal(data.total_return)}</td>
                    );
                  } else {
                    return <td key={i}>0</td>;
                  }
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <ChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          lable={lable}
          chartData={chartData}
        />
        <StockDataTableModal
          tableActive={tableActive}
          setTableActive={setTableActive}
          setChartActive={setChartActive}
          sector={sectorData}
          lable={lable}
          dataKey={dataKey}
          setCompanyName={setCompanyName}
          setLable={setLable}
          setchartData={setchartData}
        />
      </>
    );
  } else {
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    return (
      <div className="comp-financials">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={7} className="text-left">
                Stock Data
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Outstanding Shares - Adjusted</td>
            </tr>
            <tr>
              <td colSpan={2}>Total Shares</td>
            </tr>
            <tr>
              <td colSpan={2}>Free Float Shares</td>
            </tr>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Adjusted Stock Prices</td>
            </tr>
            <tr>
              <td colSpan={2}>
                High<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Low<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Average<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Closing<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Return Composition</td>
            </tr>
            <tr>
              <td colSpan={2}>
                Capital Gain / (Loss)<span className="table-unit-col">(%)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Dividend Return</td>
            </tr>
            <tr>
              <td colSpan={2}>
                Total Return<span className="table-unit-col">(%)</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={styles.noContent} className="no-content-load text-center">
          <img style={styles.noContentImage} src={loader} alt="" />
        </div>
      </div>
    );
  }
};

export default StockDataNew;
const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(50% - 90px))',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    // justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  headingtr: {
    fontWeight: '700',
  },
  position: {
    position: 'relative',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};
