import React, { useState } from 'react';
import askanalystlogo from '../../assets/media/images/askanalyst.png';
import alphaanalystlogo from '../../assets/media/images/alphaanalyst.svg';
import LoginImg from '../../assets/media/images/loginimg.png';
import LoginForm from './loginForm.jsx';
import './login.css';
import _login from './_login';

// https://askanalyst-testing.akseerresearch.com/api/login

const Login = () => {
  const [verified, setVerified] = useState(true);
  let logo;
  let site;
  if (window.location.host === 'analyst.alphacapital.com.pk') {
    logo = alphaanalystlogo;
    site = 'alphaanalyst';
  } else if (window.location.host === 'askanalyst.akseerresearch.com') {
    logo = askanalystlogo;
    site = 'askanalyst';
  } else {
    logo = askanalystlogo;
    site = 'askanalyst';
  }
  return (
    <>
      <section className="login-sec">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-md-7 d-flex align-items-center justify-content-center">
                  <div className="login-img text-center">
                    <img src={LoginImg} className="w-100" alt="login-img" />
                  </div>
                </div>
                <div className="col-md-5 d-flex flex-column justify-content-center">
                  <div className="form-area ml-md-auto mx-auto">
                    <h3 className="mb-4">Login to Your Account</h3>
                    <_login setVerified={setVerified} verified={verified} site={site} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
