import React, { useEffect, useState, useContext } from 'react';
import './settlement.css';
import {
  minusValueCheckGlobal,
  valueCheckGlobal,
  minusValueCheckGlobalDPS,
} from '../../../GlobalFunctions';
// import { valueCheckGlobal } from '../../../GlobalFunctions';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import arrowicon from '../../../assets/media/images/arrow-icon.svg';
import arrowdown from '../../../assets/media/images/arrow-down.svg';
import LoadingImage from '../../../assets/media/loader.gif';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.svg';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
// import Search2 from '../search2';
import Nav from '../nav';

import ChartModal from '../../../layout/modals/ChartModal';
import IncomeStatementTableModal from '../../../layout/modals/IncomeStatementTableModal';
import Switch from 'react-input-switch';
import Header from '../../../common/header/header';
import Loader from '../../../assets/media/_loader.gif';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';

const Settlement = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [currentPrice, setCurrentPrice] = useState({});
  const [settlement, setsettlement] = useState([]);
  const [is, setIs] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState('analysis');
  // const [symbol, setSymbol] = useState('LUCK');
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  // const [selected, setSelected] = useState({ value: 189, label: 'LUCK' });

  // const selectedcompany = { value: 189, label: 'LUCK' };

  console.log(companyId, company);
  useEffect(() => {
    // setIsLoading(false);
    //companyId == null ? location.state.id : 189;
    if (null != companyId && company !== undefined) {
      fetch(`${baseUrl}/incomestatement/${companyId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setIs(data);
          setsettlement(data['analysis']);
          setIsLoading(true);
          // generateChart(data);
        });
    }
  }, [companyId]);

  /* var handleCompanyChange = (companySelectedOption) => {
    setCompanyId(companySelectedOption.value);
    setSymbol(companySelectedOption.label);
    // setCompanySelectedOption(companySelectedOption.value);
  }; */

  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    // 'EPS - Diluted',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  // console.log(companyId, currentPrice);

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [lable, setLable] = useState('');
  const [analysis, setAnalysis] = useState('off');
  const [chartData, setchartData] = useState([]);
  const [headingId, setHeadingId] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [slice, setSlice] = useState(-11);
  const [colspan, setColspan] = useState(14);
  // const [bs, setBs] = useState([]);

  const handleSwitch = (value) => {
    setsettlement(is[value]);
    setValue(value);
    setAnalysis('off');
  };

  const handleAnalysis = (analysis) => {
    setAnalysis(analysis);

    analysis == 'on' ? setColspan(15) : setSlice(14);
    analysis == 'on' ? setSlice(-12) : setSlice(-11);
    analysis == 'on' ? setsettlement(is['analysis']) : setsettlement(is[value]);
  };
  // console.log(123);
  return (
    <>
      <Header />
      <div className="settlement-main analyst">
        <div className="row settlementboxed container container_box" style={styles.companyname}>
          <Nav companyId={companyId} nopadding={true} />
        </div>
      </div>

      {settlement.length && isLoading ? (
        <div className="settlement-main analyst">
          <div
            className="row container container_box settlement-media"
            style={styles.companyname}
          >
            <div className="col-sm-10 p-0 title settlement_lucky">
              <p className="heading mb-0">Akseer Research (Pvt) Ltd.</p>
              <p className="amount_symbol mb-0">10th October, 2023</p>
              <p className="heading mb-0">Settlement Info UIN Wise</p>
            </div>

            <div className="col-sm-2 logo settlement-imgs" style={styles.companylogoimage}>
              <img src={companylogo} style={styles.companylogo} />
            </div>
          </div>
          <div class="dashboard-main">
          <div className="settlement-summary latest-result-table">
          <table
            className="table table-responsive container container_box"
            style={{ width: '84%' }}
          >
            <thead>
              <tr>
                <th colSpan={2}>P&L Summary</th>
                {settlement[0].data
                  .slice(-11)
                  .map((y, i) => (i !== 11 ? <th key={i}>{y.year}</th> : null))}
              </tr>
            </thead>
            <tbody>
              {settlement.map((r, i) => {
                if (headingCheck(r.label)) {
                  return (
                    <tr key={i}>
                      <td style={{ fontWeight: '800' }} colSpan={2} key={i}>
                        {r.label}
                      </td>
                      {r.data.slice(-11).map((d, i) => {
                        if (r.label == 'DPS') {
                          var val = minusValueCheckGlobalDPS(d.value);
                        } else {
                          var val = minusValueCheckGlobal(d.value);
                        }
                        return i !== 11 ? (
                          <td style={{ fontWeight: '800' }} key={i}>
                            {val}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  );
                }
                return (
                  <tr key={i}>
                    <td colSpan={2}> {r.label}</td>
                    {r.data.slice(-11).map((d, i) => {
                      return i !== 11 ? (
                        <td key={i}>{minusValueCheckGlobal(d.value)}</td>
                      ) : null;
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} className="source">Source: NCPPL, Akseer Research</td>
                {settlement[0].data
                  .slice(-11)
                  .map((y, i) => (i !== 11 ? <td key={i}>&nbsp;</td> : null))}
              </tr>
            </tfoot>
          </table>
          </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    width: '80%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default Settlement;
