import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts'

import { addDigit, formatMoney } from '../../GlobalFunctions';
const sparkchart = () => {
  var category = [];
  var data = [];


  const fontFamily = 'Helvetica, sans-serif';
  const fontWeight = 400;
  const fontSize = '12px';

  var options = {
    series: [{
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }],
    chart: {
    type: 'line',
    width: 100,
    height: 35,
    sparkline: {
      enabled: true
    }
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
  };

  var chart = new ApexCharts(document.querySelector("#chart"), options);
  chart.render();
  var chart = new ApexCharts(document.querySelector("#chart"), options);
  chart.render();

  return (
    <>
      <ReactApexChart
        options={options}
        series={options.series}
        type={'area'}
        height="50"
        width="100%"
      />
    </>
  );
};

export default sparkchart;
