import React, { useEffect, useState, useContext } from 'react';
import './investment.css';
import LoadingImage from '../../../assets/media/loader.gif';
import Nav1 from '../nav1';
import Header from '../../../common/header/header';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.png';
import axios from 'axios';
import { baseUrl, pdf } from '../../../api/baseUrl';
import {
  valueCheckGlobal,
  addDigit,
  addDigitWithSign,
} from '../../../GlobalFunctions';
import Chart from './Chart';

const Publisher = (props) => {
  const [investment, setInvestment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState('Chart');

  useEffect(() => {
    axios
      .get(`${baseUrl}/investment`)
      .then(function (response) {
        setInvestment(response.data);
        //console.log(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('energyyearbook====>', error);
      });
  }, []);

  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };
  const handleTab = (value) => {
    setTab(value);
  };
  let color_class = '';
  return (
    <>
      <Header />
      <div className="investment-main analyst">
        <div className="row investmentboxed" style={styles.navWidth}>
          <Nav1 nopadding={true} />
        </div>
      </div>

      <div className="dashboard-main container container_box">
        {investment.lipi && isLoading ? (
          <div className="tab-chart">
            <div className="investment-main analyst">
              <div className="pdf_icon text-right m-0">
                <button className="p-0">
                  <a href={investment.pdf} rel="noreferrer" target="_blank">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pdf,
                      }}
                    />
                  </a>
                </button>
              </div>
              <div
                className="row container container_box investment-media p-0"
                style={styles.companyname}
              >
                <div className="col-sm-5 p-0 title investment_lucky">
                  <p className="heading mb-0">Alpha-Akseer-Research</p>
                  <p className="amount_symbol">{investment.date}</p>
                </div>

                <div
                  className="col-sm-5 logo investment-imgs pr-0"
                  style={styles.companylogoimage}
                >
                  <img src={companylogo} style={styles.companylogo} />
                </div>
              </div>
              <div className="col-md-12 p-0 investment_lucky">
                <p className="heading mb-0 sector-boxed">
                  Sector Wise Investment
                </p>
              </div>
            </div>
            <div className="Investment_table">
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th colSpan="2"></th>
                    <th>Buy</th>
                    <th>Sell</th>
                    <th>Net</th>
                    {investment.lipi.sector[0].data.map((value, index) => {
                      return <th>{value.sector}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {investment.lipi.sector.map((value, index) => {
                    return (
                      <tr>
                        <td colSpan="2">{value.label}</td>
                        <td>{addDigit(value.buy, 2)}</td>
                        <td>{addDigit(value.sell, 2)}</td>
                        <td>{addDigit(value.net, 2)}</td>
                        {value.data.map((value2) => {
                          return <td>{addDigit(value2.value, 2)}</td>;
                        })}
                      </tr>
                    );
                  })}
                  <tr className="boldness">
                    <td colSpan="2">LIPI Net</td>

                    <td>{addDigit(investment.lipi.buy, 2)}</td>
                    <td>{addDigit(investment.lipi.sell, 2)}</td>
                    <td>{addDigit(investment.lipi.net, 2)}</td>
                    {investment.lipi.data.map((value, index) => {
                      return <td>{addDigit(value.value, 2)}</td>;
                    })}
                  </tr>
                  {investment.fipi.sector.map((value, index) => {
                    return (
                      <tr>
                        <td colSpan="2">{value.label}</td>
                        <td>{addDigit(value.buy, 2)}</td>
                        <td>{addDigit(value.sell, 2)}</td>
                        <td>{addDigit(value.net, 2)}</td>
                        {value.data.map((value2) => {
                          return <td>{addDigit(value2.value, 2)}</td>;
                        })}
                      </tr>
                    );
                  })}
                  <tr className="boldness">
                    <td colSpan="2">FIPI Net</td>

                    <td>{addDigit(investment.fipi.buy, 2)}</td>
                    <td>{addDigit(investment.fipi.sell, 2)}</td>
                    <td>{addDigit(investment.fipi.net, 2)}</td>
                    {investment.fipi.data.map((value, index) => {
                      return <td>{addDigit(value.value, 2)}</td>;
                    })}
                  </tr>
                  <tr>
                    <td colSpan="2">{'WTD'}</td>
                    <td>{addDigit(investment.wtd.fipi.buy, 2)}</td>
                    <td>{addDigit(investment.wtd.fipi.sell, 2)}</td>
                    <td>{addDigit(investment.wtd.fipi.net, 2)}</td>
                    {investment.wtd.fipi.sector.map((value2) => {
                      return <td>{addDigit(value2.value, 2)}</td>;
                    })}
                  </tr>
                  <tr>
                    <td colSpan="2">{'MTD'}</td>
                    <td>{addDigit(investment.mtd.fipi.buy, 2)}</td>
                    <td>{addDigit(investment.mtd.fipi.sell, 2)}</td>
                    <td>{addDigit(investment.mtd.fipi.net, 2)}</td>
                    {investment.mtd.fipi.sector.map((value2) => {
                      return <td>{addDigit(value2.value, 2)}</td>;
                    })}
                  </tr>
                  <tr>
                    <td colSpan="2">{'CYTD'}</td>
                    <td>{addDigit(investment.cy.fipi.buy, 2)}</td>
                    <td>{addDigit(investment.cy.fipi.sell, 2)}</td>
                    <td>{addDigit(investment.cy.fipi.net, 2)}</td>
                    {investment.cy.fipi.sector.map((value2) => {
                      return <td>{addDigit(value2.value, 2)}</td>;
                    })}
                  </tr>
                  <tr>
                    <td colSpan="2">{'FYTD'}</td>
                    <td>{addDigit(investment.fy.fipi.buy, 2)}</td>
                    <td>{addDigit(investment.fy.fipi.sell, 2)}</td>
                    <td>{addDigit(investment.fy.fipi.net, 2)}</td>
                    {investment.fy.fipi.sector.map((value2) => {
                      return <td>{addDigit(value2.value, 2)}</td>;
                    })}
                  </tr>
                </tbody>
              </table>
              <div className="source_line">
                <p>Source: NCCPL, Akseer Research</p>
              </div>
            </div>

            {/* portfolio investment page */}
            <div className="portfolio_investment">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4 pl-0 portfolio_responsive">
                    <div className="portfolio-table">
                      <table className="table table-responsive" id="table">
                        <thead>
                          <tr className="portfolio_first">
                            <th>Portfolio Investment</th>
                            <th colSpan={'4'}>(Figures in USD mn)</th>
                          </tr>
                          <tr className="portfolio_second">
                            <th colSpan="2"></th>
                            <th>Buy</th>
                            <th>Sell</th>
                            <th>Net</th>
                          </tr>
                        </thead>
                        <tbody>
                          {investment.fipi.sector.map((value, index) => {
                            return (
                              <tr>
                                <td colSpan="2">{value.label}</td>
                                <td>{addDigit(value.buy, 2)}</td>
                                <td>{addDigit(value.sell, 2)}</td>
                                <td>{addDigit(value.net, 2)}</td>
                              </tr>
                            );
                          })}

                          <tr className="boldness">
                            <td colSpan="2">FIPI Net</td>
                            <td>{addDigit(investment.fipi.buy, 2)}</td>
                            <td>{addDigit(investment.fipi.sell, 2)}</td>
                            <td>{addDigit(investment.fipi.net, 2)}</td>
                          </tr>
                          {investment.lipi.sector.map((value, index) => {
                            if (value.label == 'Other Organization') {
                              value.label = 'Others';
                            }
                            if (value.label == 'Broker Proprietary') {
                              value.label = value.label + ' trading';
                            }
                            return (
                              <tr>
                                <td colSpan="2">{value.label}</td>
                                <td>{addDigit(value.buy, 2)}</td>
                                <td>{addDigit(value.sell, 2)}</td>
                                <td>{addDigit(value.net, 2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="source_line">
                        {/* <p>Source: NCCPL, Akseer Research</p> */}
                      </div>
                      <div className="portfolio-heading">
                        {/* <h1>Foreign Inflows & KSE-100/Interest rates</h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="flow_chart">
                      <Chart
                        lipi={investment.lipi.sector}
                        fipi={investment.fipi}
                      />
                      {/* <img src={flowchart} alt="" /> */}
                    </div>
                    <div className="flow-heaading">
                      {/* <h1>Mutual Fund flows & KSE-100/ Interest Rates</h1> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ width: '100%', height: '100%', color: '#426fd7' }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: '500px' }} />
          </div>
        )}
      </div>
    </>
  );
};
const styles = {
  companylogo: {
    width: '280px',
    float: 'right',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
    marginLeft: '0px',
    marginRight: '0px',
  },
};
export default Publisher;
