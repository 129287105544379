import React, { useEffect, useState, useContext } from 'react';
import './currency.css';

import Nav from '../nav';
import Search2 from '../search2';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import Header from '../../../common/header/header';
import {
  Slider,
  RangeSlider,
  Tooltip,
  ColorInput,
  Radio,
  rem,
} from '@mantine/core';
import {
  YearPickerInput,
  MonthPickerInput,
  DatePickerInput,
} from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { DatePicker, ColorPicker } from 'antd';
import axios from 'axios';
import { baseUrl, modal_excel_icon, loader } from '../../../api/baseUrl';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadingImage from '../../../assets/media/loader.gif';

function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);

  let month = ('0' + (date.getMonth() + 1)).slice(-2); //months from 1-12
  let day = ('0' + date.getDate()).slice(-2);
  let year = date.getFullYear();

  let newdate = year + '/' + month + '/' + day;
  return newdate;
}
const current_year = new Date().getFullYear();
const current_month = ('0' + (new Date().getMonth() + 1)).slice(-2);
const current_day = ('0' + new Date().getDate()).slice(-2);
const current_date = current_year + '/' + current_month + '/' + current_day;
const one_year_ago = subtractYears(new Date(), 1);
const { RangePicker } = DatePicker;
const Currency = (props) => {
  const [frequency, setFrequency] = useState('Day');
  const [timePeriod, setTimePeriod] = useState([one_year_ago, current_date]);
  const [records, setRecords] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dateFormat = 'YYYY-MM-DD';

  const icon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const handleSelectedItem = (value) => {
    setSelectedItem(value);
  };
  const handleDate = (dateString) => {
    setTimePeriod(dateString);
  };

  const handleSubmit = () => {
    setRecords([]);
    if (selectedItem.length) {
      setIsLoading(false);
      axios
        .post(`${baseUrl}/currency`, {
          data: selectedItem,
          frequency: frequency,
          timePeriod: timePeriod,
        })
        .then(function (response) {
          setRecords(response.data);
          setIsLoading(true);
          // console.log(response.data);
        })
        .catch(function (error) {
          console.log('inflation====>', error);
        });
    }
  };
  const handleFrequency = (value) => {
    setFrequency(value);
  };

  return (
    <>
      <Header />
      <div className="dashboard-main currency-main analyst">
        <div className="main-tabs-view">
          <div className="row currencyboxed" style={styles.companyname}>
            <Nav nopadding={true} />
          </div>
          <Search2
            setSelectedItem={handleSelectedItem}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            count={records.length}
          />
          <div className="container container_box">
            <div className="row">
              <div className="radio_box col-md-6">
                <h1 className="heading_radio">Data Frequency</h1>
                <div className="radio_data">
                  <Radio.Group
                    value={frequency}
                    onChange={handleFrequency}
                    name="frequency"
                    label=""
                    description=""
                    withAsterisk
                  >
                    <Radio value="Day" label="Daily" />
                    <Radio value="Week" label="Weekly" />
                    <Radio value="Month" label="Monthly" />
                    <Radio value="Quarter" label="Quarterly" />
                    <Radio value="Half Year" label="Half Yearly" />
                    <Radio value="Annual" label="Annually" />
                  </Radio.Group>
                </div>
              </div>
              <div className="range_selected col-md-6 m-auto">
                <h1 className="heading_radio">Date Range</h1>
                {/* {console.log(123, frequency)} */}
                {frequency == 'Annual' ||
                frequency == 'Half Year' ||
                frequency == 'Quarter' ? (
                  <YearPickerInput
                    leftSection={icon}
                    leftSectionPointerEvents="none"
                    type="range"
                    label=""
                    placeholder="Pick dates range"
                    // value={value}
                    clearable
                    defaultValue={[
                      dayjs(one_year_ago, dateFormat),
                      dayjs(current_date, dateFormat),
                    ]}
                    // defaultValue={[new Date(), new Date()]}
                    onChange={handleDate}
                    valueFormat="YYYY"
                  />
                ) : frequency == 'Month' ? (
                  <MonthPickerInput
                    leftSection={icon}
                    leftSectionPointerEvents="none"
                    type="range"
                    label=""
                    placeholder="Pick dates range"
                    // value={value}
                    clearable
                    defaultValue={[
                      dayjs(one_year_ago, dateFormat),
                      dayjs(current_date, dateFormat),
                    ]}
                    // defaultValue={[new Date(), new Date()]}
                    onChange={handleDate}
                    valueFormat="MMM, YYYY"
                  />
                ) : frequency == 'Day' || frequency == 'Week' ? (
                  <DatePickerInput
                    leftSection={icon}
                    leftSectionPointerEvents="none"
                    type="range"
                    label=""
                    placeholder="Pick dates range"
                    // value={value}
                    clearable
                    defaultValue={[
                      dayjs(one_year_ago, dateFormat),
                      dayjs(current_date, dateFormat),
                    ]}
                    // defaultValue={[new Date(), new Date()]}
                    onChange={handleDate}
                    valueFormat="MMM, DD YYYY"
                    // valueormat={dateFormat} please go through these and complete the QC process.
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <hr className="datalines" />
          </div>
          {/* {console.log(records.length)} */}
          {isLoading && records.length ? (
            <>
              <div className="container container_box table_container">
                <span style={styles.excel}>
                  <ReactHTMLTableToExcel
                    className="download-table-xls-button"
                    table="table"
                    filename="Currency"
                    sheet="Currency"
                    buttonText={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: modal_excel_icon,
                        }}
                      />
                    }
                  />{' '}
                </span>
                <table className="table table-responsive" id="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Currency</th>
                      <th>Ready</th>
                      <th>1-Week</th>
                      <th>2-Week</th>
                      <th>1-Month</th>
                      <th>2-Month</th>
                      <th>3-Month</th>
                      <th>4-Month</th>
                      <th>5-Month</th>
                      <th>6-Month</th>
                      <th>9-Month</th>
                      <th>1-Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((value, index) => {
                      return (
                        <tr>
                          <td>{value.date}</td>
                          <td>{value.currency}</td>
                          <td>{valueCheckGlobal(value.ready, '', 2)}</td>
                          <td>{valueCheckGlobal(value.week_1, '', 2)}</td>
                          <td>{valueCheckGlobal(value.week_2, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_1, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_2, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_3, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_4, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_5, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_6, '', 2)}</td>
                          <td>{valueCheckGlobal(value.month_9, '', 2)}</td>
                          <td>{valueCheckGlobal(value.year_1, '', 2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ width: '100%', height: '100%', color: '#426fd7' }}
                className="main-loader d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  src={LoadingImage}
                  style={{
                    height: '500px',
                    display: isLoading ? 'none' : 'block',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '180px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
};
export default Currency;
