import React from 'react';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import './nav.css';

function Nav({ nopadding }) {
  let history = useHistory();
  const location = useLocation();

  const nav = [
    {
      path: 'market',
      label: 'Market',
    },
    {
      path: 'market/investment',
      label: 'Portfolio Investment',
    },
  ];

  const navChange = (path) => {
    history.push({
      pathname: '/' + path,
    });
  };
  return (
    <div
      className={
        nopadding
          ? 'dashboard-main container container_box omcs-sizing'
          : 'dashboard-main container container_box'
      }
    >
      <div className="row ml-0">
        <ul className="navbar-nav second-nav mr-auto">
          {nav.map((value, item) => {
            return (
              <li
                className={
                  location.pathname == '/' + value.path
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <button
                  type="button"
                  id="controlled-tab-example-tab-technical"
                  role="tab"
                  data-rr-ui-event-key="technical"
                  aria-controls="controlled-tab-example-tabpane-technical"
                  tabIndex="-1"
                  onClick={() => {
                    navChange(value.path);
                  }}
                >
                  {value.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Nav;
